/* Add your variable customizations of layout here */
/* Utils */
.clearfix:after {
	content: " ";
	display: block;
	clear: both;
}

.card {
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	background: #ffffff;
	padding: 18px;
	margin-bottom: 2rem;
}
.card:last-child {
	margin-bottom: 0;
}
.card .card-header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
.card .card-subtitle {
	color: #9199a9;
	font-weight: 600;
	margin: -1rem 0 1rem 0;
}

.p-toast.p-toast-top-right, .p-toast.p-toast-top-left, .p-toast.p-toast-top-center {
	top: 70px;
}

@-webkit-keyframes fadeInDown {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, -20px, 0);
		transform: translate3d(0, -20px, 0);
	}
	to {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}
@keyframes fadeInDown {
	from {
		opacity: 0;
		transform: translate3d(0, -20px, 0);
	}
	to {
		opacity: 1;
		transform: none;
	}
}
@-webkit-keyframes fadeOutUp {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
		-webkit-transform: translate3d(0, -20px, 0);
		transform: translate3d(0, -20px, 0);
	}
}
@keyframes fadeOutUp {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
		-webkit-transform: translate3d(0, -20px, 0);
		transform: translate3d(0, -20px, 0);
	}
}
@keyframes rippleOn {
	0% {
		opacity: 0.5;
	}
	100% {
		opacity: 0;
		transform: scale(13, 13);
	}
}
@-webkit-keyframes spin {
	from {
		-webkit-transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(359deg);
	}
}
@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}
@keyframes rippleOff {
	0% {
		opacity: 0.5;
	}
	100% {
		opacity: 0;
		transform: scale(13, 13);
	}
}
.fadeInDown {
	-webkit-animation: fadeInDown 0.5s;
	/* Safari 4.0 - 8.0 */
	animation: fadeInDown 0.5s;
}

.fadeOutUp {
	-webkit-animation: fadeOutUp 0.15s;
	/* Safari 4.0 - 8.0 */
	animation: fadeOutUp 0.15s;
}

h1, h2, h3, h4, h5, h6 {
	margin: 1.5rem 0 1rem 0;
	font-family: inherit;
	font-weight: 600;
	line-height: 1.2;
	color: inherit;
}
h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child {
	margin-top: 0;
}

h1 {
	font-size: 2.5rem;
}

h2 {
	font-size: 2rem;
}

h3 {
	font-size: 1.75rem;
}

h4 {
	font-size: 1.5rem;
}

h5 {
	font-size: 1.25rem;
}

h6 {
	font-size: 1rem;
}

mark {
	background: #FFF8E1;
	padding: 0.25rem 0.4rem;
	border-radius: 4px;
	font-family: monospace;
}

blockquote {
	margin: 1rem 0;
	padding: 0 2rem;
	border-left: 4px solid #90A4AE;
}

hr {
	border-top: solid rgba(145, 153, 169, 0.3);
	border-width: 1px 0 0 0;
	margin: 1rem 0;
}

p {
	margin: 0 0 1rem 0;
	line-height: 1.5;
}
p:last-child {
	margin-bottom: 0;
}

.splash-screen {
	width: 100%;
	min-height: 100vh;
	background-color: #F4F7F7;
}

.spinner {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 1;
	height: 40px;
	width: 40px;
	transform: translate(-50%, -50%);
}

[class^=ball-] {
	position: absolute;
	display: block;
	left: 30px;
	width: 12px;
	height: 12px;
	border-radius: 6px;
	transition: all 0.5s;
	animation: circleRotate 4s both infinite;
	transform-origin: 0 250% 0;
}

@keyframes circleRotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(1440deg);
	}
}
.ball-1 {
	z-index: -1;
	background-color: #5E81AC;
	animation-timing-function: cubic-bezier(0.5, 0.3, 0.9, 0.9);
}

.ball-2 {
	z-index: -2;
	background-color: #81A1C1;
	animation-timing-function: cubic-bezier(0.5, 0.6, 0.9, 0.9);
}

.ball-3 {
	z-index: -3;
	background-color: #88C0D0;
	animation-timing-function: cubic-bezier(0.5, 0.9, 0.9, 0.9);
}

.ball-4 {
	z-index: -4;
	background-color: #8FBCBB;
	animation-timing-function: cubic-bezier(0.5, 1.2, 0.9, 0.9);
}

.ball-5 {
	z-index: -5;
	background-color: #D08770;
	animation-timing-function: cubic-bezier(0.5, 1.5, 0.9, 0.9);
}

.ball-6 {
	z-index: -6;
	background-color: #EBCB8B;
	animation-timing-function: cubic-bezier(0.5, 1.8, 0.9, 0.9);
}

.ball-7 {
	z-index: -7;
	background-color: #A3BE8C;
	animation-timing-function: cubic-bezier(0.5, 2.1, 0.9, 0.9);
}

.ball-8 {
	z-index: -8;
	background-color: #B48EAD;
	animation-timing-function: cubic-bezier(0.5, 2.4, 0.9, 0.9);
}

* {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

html {
	height: 100%;
	font-size: 14px;
}

body {
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	font-size: 14px;
	color: #4c566a;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	padding: 0;
	margin: 0;
	min-height: 100%;
	background-color: #eceff4;
}
body .ajax-loader {
	font-size: 32px;
}
body.hidden-overflow {
	overflow: hidden;
}

a {
	text-decoration: none;
	color: #9199a9;
	color: var(--primary-color);
}

.layout-main-mask {
	display: none;
	background-color: #2e3440;
	position: fixed;
	z-index: 997;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
	opacity: 0.64;
	animation-name: fadeinmask;
	animation-duration: 0.15s;
	opacity: 0.8;
	filter: alpha(opacity=80);
	cursor: pointer;
}

.layout-main {
	margin-left: 60px;
	padding-top: 75px;
	-moz-transition: margin-left 0.15s;
	-o-transition: margin-left 0.15s;
	-webkit-transition: margin-left 0.15s;
	transition: margin-left 0.15s;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	min-height: 100vh;
	-moz-transition: margin-left 0.2s;
	-o-transition: margin-left 0.2s;
	-webkit-transition: margin-left 0.2s;
	transition: margin-left 0.2s;
}

.layout-footer {
	background-color: #ffffff;
	padding: 10px 30px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 120px;
}
.layout-footer .logo-text {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	height: 100%;
}
.layout-footer .logo-text img {
	height: 50px;
	width: 50px;
}
.layout-footer .logo-text .text {
	margin-left: 15px;
}
.layout-footer .logo-text .text h1 {
	font-size: 18px;
	color: #4c566a;
	font-weight: normal;
	margin: 0;
}
.layout-footer .logo-text .text span {
	color: #9199a9;
	font-size: 12px;
}
.layout-footer .icons {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	font-size: 24px;
	color: #9199a9;
	height: 100%;
}
.layout-footer .icons i {
	margin-left: 20px;
	cursor: pointer;
	font-size: 28px;
}
.layout-footer .icons i:hover {
	color: #5e81ac;
}

.layout-sidebar {
	width: 240px;
	height: 100%;
	position: fixed;
	top: 0;
	-webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	z-index: 999;
	transform: translate3d(-180px, 0px, 0px);
	-moz-transition: transform 0.15s;
	-o-transition: transform 0.15s;
	-webkit-transition: transform 0.15s;
	transition: transform 0.15s;
	-webkit-box-shadow: 3px 0 5px 0 rgba(33, 35, 39, 0.15);
	-moz-box-shadow: 3px 0 5px 0 rgba(33, 35, 39, 0.15);
	box-shadow: 3px 0 5px 0 rgba(33, 35, 39, 0.15);
}
.layout-sidebar .sidebar-logo {
	height: 76px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 0 8px;
}
.layout-sidebar .sidebar-logo .sidebar-pin {
	display: none;
	width: 30px;
	height: 30px;
	line-height: 29px;
	text-align: center;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	-moz-transition: all 0.15s;
	-o-transition: all 0.15s;
	-webkit-transition: all 0.15s;
	transition: all 0.15s;
}
.layout-sidebar .sidebar-logo .sidebar-pin i {
	line-height: inherit;
	font-size: 18px;
}
.layout-sidebar .sidebar-logo img {
	vertical-align: middle;
	height: 45px;
	width: 45px;
	border: 0 none;
}
.layout-sidebar .sidebar-logo .app-name {
	display: none;
	vertical-align: middle;
	font-size: 22.5px;
	margin-left: 4px;
}
.layout-sidebar .layout-menu-container {
	overflow: auto;
	height: calc(100% - 72px);
}
.layout-sidebar .layout-menu-container .layout-menu {
	list-style-type: none;
	margin: 10px 0 0 0;
	padding: 0;
	padding-bottom: 120px;
}
.layout-sidebar .layout-menu-container .layout-menu li {
	padding: 4px 10px;
}
.layout-sidebar .layout-menu-container .layout-menu li.active-menuitem > a i.layout-submenu-toggler {
	-webkit-transform: rotate(-180deg);
	-moz-transform: rotate(-180deg);
	-o-transform: rotate(-180deg);
	-ms-transform: rotate(-180deg);
	transform: rotate(-180deg);
}
.layout-sidebar .layout-menu-container .layout-menu li > a {
	padding: 10px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	border-radius: 0;
	-moz-transition: all 0.15s;
	-o-transition: all 0.15s;
	-webkit-transition: all 0.15s;
	transition: all 0.15s;
	align-items: center;
	justify-content: space-between;
	position: relative;
}
.layout-sidebar .layout-menu-container .layout-menu li > a i {
	width: 22px;
	height: 22px;
	font-size: 17px;
	position: absolute;
	right: 10px;
	top: 50%;
	margin-top: -0.5em;
}
.layout-sidebar .layout-menu-container .layout-menu li > a i.layout-submenu-toggler {
	-moz-transition: all 0.15s;
	-o-transition: all 0.15s;
	-webkit-transition: all 0.15s;
	transition: all 0.15s;
	font-size: 22px;
	display: none;
	margin-right: 4px;
	right: 30px;
}
.layout-sidebar .layout-menu-container .layout-menu li > a .menuitem-badge {
	display: none;
	margin-left: auto;
}
.layout-sidebar .layout-menu-container .layout-menu li ul {
	overflow: hidden;
	list-style-type: none;
	margin: 0;
	padding: 0;
}
.layout-sidebar .layout-menu-container .layout-menu li ul li {
	padding: 4px 0;
}
.layout-sidebar .layout-menu-container .layout-menu li ul li a {
	padding-left: 20px;
}
.layout-sidebar .layout-menu-container .layout-menu li ul li ul li a {
	padding-left: 30px;
}
.layout-sidebar .layout-menu-container .layout-menu li ul li ul li ul li a {
	padding-left: 40px;
}
.layout-sidebar .layout-menu-container .layout-menu li ul li ul li ul ul li a {
	padding-left: 50px;
}
.layout-sidebar .layout-menu-container .layout-menu li ul li ul li ul ul ul li a {
	padding-left: 60px;
}
.layout-sidebar .layout-menu-container .layout-menu li ul li ul li ul ul ul ul li a {
	padding-left: 70px;
}
.layout-sidebar .layout-menu-container .layout-menu li ul li ul li ul ul ul ul ul li a {
	padding-left: 80px;
}
.layout-sidebar .layout-menu-container .layout-menu > li > a {
	-moz-border-radius: 6px;
	-webkit-border-radius: 6px;
	border-radius: 6px;
}
.layout-sidebar .layout-menu-container .layout-menu > li.active-menuitem > a {
	-moz-border-radius: 6px;
	-webkit-border-radius: 6px;
	border-radius: 6px;
	-moz-border-radius-bottomleft: 0;
	-webkit-border-bottom-left-radius: 0;
	border-bottom-left-radius: 0;
	-moz-border-radius-bottomright: 0;
	-webkit-border-bottom-right-radius: 0;
	border-bottom-right-radius: 0;
}
.layout-sidebar .layout-menu-container .layout-menu > li.active-menuitem > ul {
	-moz-border-radius-bottomleft: 6px;
	-webkit-border-bottom-left-radius: 6px;
	border-bottom-left-radius: 6px;
	-moz-border-radius-bottomright: 6px;
	-webkit-border-bottom-right-radius: 6px;
	border-bottom-right-radius: 6px;
}
.layout-sidebar .layout-menu-container .layout-menu .menuitem-badge {
	display: inline-block;
	width: 16px;
	height: 16px;
	margin-right: 6px;
	text-align: center;
	font-size: 12px;
	font-weight: 700;
	line-height: 16px;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	border-radius: 50%;
}
.layout-sidebar.layout-sidebar-active {
	transform: translate3d(0px, 0px, 0px);
}
.layout-sidebar.layout-sidebar-active .sidebar-logo {
	flex-flow: row-reverse;
	justify-content: space-between;
	padding: 0 14px;
}
.layout-sidebar.layout-sidebar-active .sidebar-logo img {
	display: inline;
}
.layout-sidebar.layout-sidebar-active .sidebar-logo .app-name {
	display: inline;
}
.layout-sidebar.layout-sidebar-active .sidebar-logo .sidebar-pin {
	display: inline-block;
}
.layout-sidebar.layout-sidebar-active .layout-menu li a i.layout-submenu-toggler {
	display: inline-block;
}
.layout-sidebar.layout-sidebar-active .layout-menu li a .menuitem-badge {
	display: inline-block;
}

.layout-wrapper-static .layout-sidebar {
	transform: translate3d(0px, 0px, 0px);
}
.layout-wrapper-static .layout-sidebar .sidebar-logo {
	flex-flow: row-reverse;
	justify-content: space-between;
	padding: 0 14px;
}
.layout-wrapper-static .layout-sidebar .sidebar-logo img {
	display: inline;
}
.layout-wrapper-static .layout-sidebar .sidebar-logo .app-name {
	display: inline;
}
.layout-wrapper-static .layout-sidebar .sidebar-logo .sidebar-pin {
	display: block;
}
.layout-wrapper-static .layout-sidebar .layout-menu li > a i.layout-submenu-toggler {
	display: inline-block;
}
.layout-wrapper-static .layout-main {
	margin-left: 240px;
}
.layout-wrapper-static .layout-main .layout-topbar {
	width: calc(100% - 240px);
	-moz-transition: width 0.15s;
	-o-transition: width 0.15s;
	-webkit-transition: width 0.15s;
	transition: width 0.15s;
}

.layout-wrapper-static-restore .layout-sidebar {
	-moz-transition: none;
	-o-transition: none;
	-webkit-transition: none;
	transition: none;
	transform: translate3d(0px, 0px, 0px);
}
.layout-wrapper-static-restore .layout-main {
	-moz-transition: none;
	-o-transition: none;
	-webkit-transition: none;
	transition: none;
	margin-left: 240px;
}

@media (max-width: 1199px) {
	.layout-wrapper .layout-sidebar {
		width: 240px;
		top: 75px;
		transform: translate3d(-240px, 0px, 0px);
		-webkit-box-shadow: 3px 0 5px 0 rgba(33, 35, 39, 0.15);
		-moz-box-shadow: 3px 0 5px 0 rgba(33, 35, 39, 0.15);
		box-shadow: 3px 0 5px 0 rgba(33, 35, 39, 0.15);
	}
	.layout-wrapper .layout-sidebar .sidebar-logo {
		display: none;
	}
	.layout-wrapper.layout-wrapper-static .layout-main .layout-topbar {
		width: 100%;
	}
	.layout-wrapper.layout-wrapper-static-restore .layout-sidebar {
		-moz-transition: transform 0.15s;
		-o-transition: transform 0.15s;
		-webkit-transition: transform 0.15s;
		transition: transform 0.15s;
	}
	.layout-wrapper .layout-main {
		margin-left: 0px;
	}
	.layout-wrapper .layout-topbar {
		width: 100%;
	}
	.layout-wrapper .layout-topbar .topbar-logo {
		margin-top: -10px;
		margin-right: 20px;
		display: block;
	}
	.layout-wrapper .layout-topbar .topbar-logo a {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.layout-wrapper .layout-topbar .topbar-logo a .app-name {
		margin-left: 10px;
		color: #4c566a;
		font-size: 18px;
	}
	.layout-wrapper.layout-sidebar-mobile-active .layout-sidebar {
		transform: translate3d(0px, 0px, 0px);
	}
	.layout-wrapper.layout-sidebar-mobile-active .layout-sidebar .layout-menu li a i.layout-submenu-toggler {
		display: inline-block;
	}
	.layout-wrapper.layout-sidebar-mobile-active .layout-sidebar .layout-menu li a .menuitem-badge {
		display: inline-block;
	}
	.layout-wrapper.layout-sidebar-mobile-active .layout-topbar .layout-topbar-wrapper .layout-topbar-left .sidebar-menu-button {
		background-color: #4c566a;
		-moz-transition: background-color 0.15s;
		-o-transition: background-color 0.15s;
		-webkit-transition: background-color 0.15s;
		transition: background-color 0.15s;
	}
	.layout-wrapper.layout-sidebar-mobile-active .layout-topbar .layout-topbar-wrapper .layout-topbar-left .sidebar-menu-button i {
		color: #ffffff;
	}
	.layout-wrapper.layout-sidebar-mobile-active .layout-topbar .layout-topbar-wrapper .layout-topbar-left .sidebar-menu-button:hover {
		background-color: #5e81ac;
		-moz-transition: background-color 0.15s;
		-o-transition: background-color 0.15s;
		-webkit-transition: background-color 0.15s;
		transition: background-color 0.15s;
	}
	.layout-wrapper.layout-sidebar-mobile-active .layout-topbar .layout-topbar-wrapper .layout-topbar-left .sidebar-menu-button:hover i {
		color: #ffffff;
	}
	.layout-wrapper.layout-sidebar-mobile-active .layout-main-mask {
		display: block;
	}
}
@media (min-width: 1200px) {
	.layout-wrapper.layout-horizontal .layout-sidebar {
		width: 100%;
		height: 50px;
		top: 75px;
		left: 0;
		z-index: 99;
		transform: none;
		box-shadow: 3px 0 5px 0 rgba(33, 35, 39, 0.15);
	}
	.layout-wrapper.layout-horizontal .layout-sidebar .sidebar-logo {
		display: none;
	}
	.layout-wrapper.layout-horizontal .layout-sidebar .layout-menu-container {
		overflow: visible;
		height: 100%;
	}
	.layout-wrapper.layout-horizontal .layout-sidebar .layout-menu-container .layout-menu {
		margin: 0px 15px;
		padding-bottom: 0;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
	.layout-wrapper.layout-horizontal .layout-sidebar .layout-menu-container .layout-menu > li {
		width: auto;
		padding: 0;
		position: relative;
	}
	.layout-wrapper.layout-horizontal .layout-sidebar .layout-menu-container .layout-menu > li > a {
		height: 35px;
		margin: 8px 3px;
		padding: 7px 12px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		align-items: center;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		-moz-border-radius: 0;
		-webkit-border-radius: 0;
		border-radius: 0;
		-moz-border-radius-topleft: 3px;
		-webkit-border-top-left-radius: 3px;
		border-top-left-radius: 3px;
		-moz-border-radius-topright: 3px;
		-webkit-border-top-right-radius: 3px;
		border-top-right-radius: 3px;
		justify-content: space-between;
	}
	.layout-wrapper.layout-horizontal .layout-sidebar .layout-menu-container .layout-menu > li > a .menuitem-text {
		vertical-align: middle;
		margin-left: 8px;
		order: 2;
	}
	.layout-wrapper.layout-horizontal .layout-sidebar .layout-menu-container .layout-menu > li > a i {
		position: static;
		vertical-align: middle;
		margin-top: 0;
		top: auto;
		right: auto;
		margin-right: 5px;
		order: 1;
	}
	.layout-wrapper.layout-horizontal .layout-sidebar .layout-menu-container .layout-menu > li > a i.layout-submenu-toggler {
		order: 3;
		display: inline-block;
		margin-top: 2px;
	}
	.layout-wrapper.layout-horizontal .layout-sidebar .layout-menu-container .layout-menu > li > ul {
		top: 41px;
		left: 3px;
		width: 230px;
		position: absolute;
		padding: 0;
		margin: 0;
		z-index: 100;
		overflow: auto;
		max-height: 450px;
		-webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
		-moz-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
		box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
	}
	.layout-wrapper.layout-horizontal .layout-sidebar .layout-menu-container .layout-menu > li > ul li a {
		padding-top: 5px;
		padding-bottom: 5px;
		flex-flow: row-reverse;
		justify-content: flex-end;
	}
	.layout-wrapper.layout-horizontal .layout-sidebar .layout-menu-container .layout-menu > li > ul li a .menuitem-text {
		vertical-align: middle;
		margin-left: 8px;
	}
	.layout-wrapper.layout-horizontal .layout-sidebar .layout-menu-container .layout-menu > li > ul li a i {
		position: relative;
		right: 0;
		left: 0;
		top: 2px;
		margin-top: 0;
	}
	.layout-wrapper.layout-horizontal .layout-sidebar .layout-menu-container .layout-menu > li > ul li a .layout-submenu-toggler {
		display: block;
		left: auto;
		right: 10px;
		position: absolute;
	}
	.layout-wrapper.layout-horizontal .layout-sidebar .layout-menu-container .layout-menu > li.active-menuitem > ul {
		-moz-border-radius-bottomleft: 3px;
		-webkit-border-bottom-left-radius: 3px;
		border-bottom-left-radius: 3px;
		-moz-border-radius-bottomright: 3px;
		-webkit-border-bottom-right-radius: 3px;
		border-bottom-right-radius: 3px;
		box-shadow: 0 2px 6px 0 rgba(29, 29, 33, 0.22);
	}
	.layout-wrapper.layout-horizontal .layout-sidebar .layout-menu-container .layout-menu li ul li a {
		padding-left: 12px;
	}
	.layout-wrapper.layout-horizontal .layout-sidebar .layout-menu-container .layout-menu li ul li ul li a {
		padding-left: 24px;
	}
	.layout-wrapper.layout-horizontal .layout-sidebar .layout-menu-container .layout-menu li ul li ul li ul li a {
		padding-left: 36px;
	}
	.layout-wrapper.layout-horizontal .layout-sidebar .layout-menu-container .layout-menu li ul li ul li ul ul li a {
		padding-left: 48px;
	}
	.layout-wrapper.layout-horizontal .layout-sidebar .layout-menu-container .layout-menu li ul li ul li ul ul ul li a {
		padding-left: 60px;
	}
	.layout-wrapper.layout-horizontal .layout-sidebar .layout-menu-container .layout-menu li ul li ul li ul ul ul ul li a {
		padding-left: 72px;
	}
	.layout-wrapper.layout-horizontal .layout-sidebar .layout-menu-container .layout-menu li ul li ul li ul ul ul ul ul li a {
		padding-left: 84px;
	}
	.layout-wrapper.layout-horizontal .layout-sidebar .layout-menu-container .layout-menu li a .menuitem-badge {
		left: 18px;
		top: 15px;
		display: block;
	}
	.layout-wrapper.layout-horizontal .layout-main {
		margin-left: 0px;
		padding-top: 125px;
	}
	.layout-wrapper.layout-horizontal .layout-topbar {
		width: 100%;
		box-shadow: none;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
	}
	.layout-wrapper.layout-horizontal .layout-topbar .layout-topbar-logo-wrapper {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		margin-right: 8%;
	}
	.layout-wrapper.layout-horizontal .layout-topbar .layout-topbar-logo-wrapper .layout-topbar-logo {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}
	.layout-wrapper.layout-horizontal .layout-topbar .layout-topbar-logo-wrapper .layout-topbar-logo .app-name {
		font-size: 22.5px;
		color: #4c566a;
		margin: 0 10px;
	}
	.layout-wrapper.layout-horizontal .layout-topbar .layout-topbar-logo-wrapper .layout-topbar-logo img {
		height: 45px;
		width: 45px;
	}
}
.layout-menu-light .layout-sidebar {
	background-color: #ffffff;
}
.layout-menu-light .layout-sidebar .sidebar-logo .sidebar-pin {
	background-color: #2e3440;
}
.layout-menu-light .layout-sidebar .sidebar-logo .sidebar-pin i {
	color: #ffffff;
}
.layout-menu-light .layout-sidebar .sidebar-logo .app-name {
	color: #2e3440;
}
.layout-menu-light .layout-sidebar .layout-menu li > a {
	cursor: pointer;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	outline: none;
}
.layout-menu-light .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
	color: #5e81ac;
}
.layout-menu-light .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
	color: #5e81ac;
}
.layout-menu-light .layout-sidebar .layout-menu > li > a .menuitem-text {
	color: #2e3440;
}
.layout-menu-light .layout-sidebar .layout-menu > li > a i {
	color: #4c566a;
}
.layout-menu-light .layout-sidebar .layout-menu > li > a:hover {
	background-color: #f4f4f4;
}
.layout-menu-light .layout-sidebar .layout-menu > li.active-menuitem > a {
	background-color: #5e81ac;
}
.layout-menu-light .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
	color: #ffffff;
}
.layout-menu-light .layout-sidebar .layout-menu > li.active-menuitem > a i {
	color: #ffffff;
}
.layout-menu-light .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
	background-color: #5e81ac;
}
.layout-menu-light .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
	color: #ffffff;
}
.layout-menu-light .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
	color: #ffffff;
}
.layout-menu-light .layout-sidebar .layout-menu > li ul {
	background-color: #e8e8e8;
}
.layout-menu-light .layout-sidebar .layout-menu > li ul li a .menuitem-text {
	color: #2e3440;
}
.layout-menu-light .layout-sidebar .layout-menu > li ul li a i {
	color: #4c566a;
}
.layout-menu-light .layout-sidebar .layout-menu > li ul li a:hover {
	background-color: #f4f4f4;
}
.layout-menu-light .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
	color: #5e81ac;
}
.layout-menu-light .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
	color: #5e81ac;
}
@media (min-width: 1200px) {
	.layout-menu-light.layout-horizontal .layout-sidebar {
		background-color: #ffffff;
	}
	.layout-menu-light.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
		color: #5e81ac;
	}
	.layout-menu-light.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
		color: #5e81ac;
	}
	.layout-menu-light.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
		color: #2e3440;
	}
	.layout-menu-light.layout-horizontal .layout-sidebar .layout-menu > li > a i {
		color: #4c566a;
	}
	.layout-menu-light.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
		background-color: #f4f4f4;
	}
	.layout-menu-light.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
		background-color: #5e81ac;
	}
	.layout-menu-light.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
		color: #ffffff;
	}
	.layout-menu-light.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
		color: #ffffff;
	}
	.layout-menu-light.layout-horizontal .layout-sidebar .layout-menu > li ul {
		background-color: #ffffff;
	}
	.layout-menu-light.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
		color: #2e3440;
	}
	.layout-menu-light.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
		color: #4c566a;
	}
	.layout-menu-light.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
		background-color: #f4f4f4;
	}
	.layout-menu-light.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
		color: #5e81ac;
	}
	.layout-menu-light.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
		color: #5e81ac;
	}
}

.layout-menu-dark .layout-sidebar {
	background-color: #2e3440;
}
.layout-menu-dark .layout-sidebar .sidebar-logo .sidebar-pin {
	background-color: #ffffff;
}
.layout-menu-dark .layout-sidebar .sidebar-logo .sidebar-pin i {
	color: #2e3440;
}
.layout-menu-dark .layout-sidebar .sidebar-logo .app-name {
	color: #ffffff;
}
.layout-menu-dark .layout-sidebar .layout-menu li > a {
	cursor: pointer;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	outline: none;
}
.layout-menu-dark .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
	color: #809bbd;
}
.layout-menu-dark .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
	color: #809bbd;
}
.layout-menu-dark .layout-sidebar .layout-menu > li > a .menuitem-text {
	color: #eceff4;
}
.layout-menu-dark .layout-sidebar .layout-menu > li > a i {
	color: #eceff4;
}
.layout-menu-dark .layout-sidebar .layout-menu > li > a:hover {
	background-color: #4c566a;
}
.layout-menu-dark .layout-sidebar .layout-menu > li.active-menuitem > a {
	background-color: #5e81ac;
}
.layout-menu-dark .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
	color: #ffffff;
}
.layout-menu-dark .layout-sidebar .layout-menu > li.active-menuitem > a i {
	color: #ffffff;
}
.layout-menu-dark .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
	background-color: #5e81ac;
}
.layout-menu-dark .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
	color: #ffffff;
}
.layout-menu-dark .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
	color: #ffffff;
}
.layout-menu-dark .layout-sidebar .layout-menu > li ul {
	background-color: #4c566a;
}
.layout-menu-dark .layout-sidebar .layout-menu > li ul li a .menuitem-text {
	color: #eceff4;
}
.layout-menu-dark .layout-sidebar .layout-menu > li ul li a i {
	color: #eceff4;
}
.layout-menu-dark .layout-sidebar .layout-menu > li ul li a:hover {
	background-color: #2E3440;
}
.layout-menu-dark .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
	color: #809bbd;
}
.layout-menu-dark .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
	color: #809bbd;
}
@media (min-width: 1200px) {
	.layout-menu-dark.layout-horizontal .layout-sidebar {
		background-color: #2e3440;
	}
	.layout-menu-dark.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
		color: #809bbd;
	}
	.layout-menu-dark.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
		color: #809bbd;
	}
	.layout-menu-dark.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-dark.layout-horizontal .layout-sidebar .layout-menu > li > a i {
		color: #eceff4;
	}
	.layout-menu-dark.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
		background-color: #4c566a;
	}
	.layout-menu-dark.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
		background-color: #5e81ac;
	}
	.layout-menu-dark.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
		color: #ffffff;
	}
	.layout-menu-dark.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
		color: #ffffff;
	}
	.layout-menu-dark.layout-horizontal .layout-sidebar .layout-menu > li ul {
		background-color: #4c566a;
	}
	.layout-menu-dark.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-dark.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
		color: #eceff4;
	}
	.layout-menu-dark.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
		background-color: #2E3440;
	}
	.layout-menu-dark.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
		color: #809bbd;
	}
	.layout-menu-dark.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
		color: #809bbd;
	}
}

.layout-menu-chile .layout-sidebar {
	background-color: #282D37;
	background-image: url("../../images/menu/chile.jpg");
	background-size: 240px 100%;
	background-repeat: no-repeat;
}
.layout-menu-chile .layout-sidebar .sidebar-logo .sidebar-pin {
	background-color: #ffffff;
}
.layout-menu-chile .layout-sidebar .sidebar-logo .sidebar-pin i {
	color: #2e3440;
}
.layout-menu-chile .layout-sidebar .sidebar-logo .app-name {
	color: #ffffff;
}
.layout-menu-chile .layout-sidebar .layout-menu li > a {
	cursor: pointer;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	outline: none;
}
.layout-menu-chile .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
	color: #88C0D0;
}
.layout-menu-chile .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
	color: #88C0D0;
}
.layout-menu-chile .layout-sidebar .layout-menu > li > a .menuitem-text {
	color: #eceff4;
}
.layout-menu-chile .layout-sidebar .layout-menu > li > a i {
	color: #eceff4;
}
.layout-menu-chile .layout-sidebar .layout-menu > li > a:hover {
	background-color: rgba(255, 255, 255, 0.24);
}
.layout-menu-chile .layout-sidebar .layout-menu > li.active-menuitem > a {
	background-color: #88C0D0;
}
.layout-menu-chile .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
	color: #3b4252;
}
.layout-menu-chile .layout-sidebar .layout-menu > li.active-menuitem > a i {
	color: #2e3440;
}
.layout-menu-chile .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
	background-color: #88C0D0;
}
.layout-menu-chile .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
	color: #3b4252;
}
.layout-menu-chile .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
	color: #2e3440;
}
.layout-menu-chile .layout-sidebar .layout-menu > li ul {
	background-color: rgba(255, 255, 255, 0.12);
}
.layout-menu-chile .layout-sidebar .layout-menu > li ul li a .menuitem-text {
	color: #eceff4;
}
.layout-menu-chile .layout-sidebar .layout-menu > li ul li a i {
	color: #eceff4;
}
.layout-menu-chile .layout-sidebar .layout-menu > li ul li a:hover {
	background-color: rgba(0, 0, 0, 0.36);
}
.layout-menu-chile .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
	color: #88C0D0;
}
.layout-menu-chile .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
	color: #88C0D0;
}
@media (min-width: 1200px) {
	.layout-menu-chile.layout-horizontal .layout-sidebar {
		background-color: #282D37;
		background-image: url("../../images/menu/chile-horizontal.jpg");
		background-size: auto 100%;
		background-repeat: no-repeat;
	}
	.layout-menu-chile.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
		color: #88C0D0;
	}
	.layout-menu-chile.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
		color: #88C0D0;
	}
	.layout-menu-chile.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-chile.layout-horizontal .layout-sidebar .layout-menu > li > a i {
		color: #eceff4;
	}
	.layout-menu-chile.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
		background-color: rgba(255, 255, 255, 0.24);
	}
	.layout-menu-chile.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
		background-color: #88C0D0;
	}
	.layout-menu-chile.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
		color: #3b4252;
	}
	.layout-menu-chile.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
		color: #2e3440;
	}
	.layout-menu-chile.layout-horizontal .layout-sidebar .layout-menu > li ul {
		background-color: #2e3440;
	}
	.layout-menu-chile.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-chile.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
		color: #eceff4;
	}
	.layout-menu-chile.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
		background-color: rgba(0, 0, 0, 0.36);
	}
	.layout-menu-chile.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
		color: #88C0D0;
	}
	.layout-menu-chile.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
		color: #88C0D0;
	}
}

.layout-menu-naples .layout-sidebar {
	background-color: #312F3A;
	background-image: url("../../images/menu/naples.jpg");
	background-size: 240px 100%;
	background-repeat: no-repeat;
}
.layout-menu-naples .layout-sidebar .sidebar-logo .sidebar-pin {
	background-color: #ffffff;
}
.layout-menu-naples .layout-sidebar .sidebar-logo .sidebar-pin i {
	color: #2e3440;
}
.layout-menu-naples .layout-sidebar .sidebar-logo .app-name {
	color: #ffffff;
}
.layout-menu-naples .layout-sidebar .layout-menu li > a {
	cursor: pointer;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	outline: none;
}
.layout-menu-naples .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
	color: #A3BE8C;
}
.layout-menu-naples .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
	color: #A3BE8C;
}
.layout-menu-naples .layout-sidebar .layout-menu > li > a .menuitem-text {
	color: #eceff4;
}
.layout-menu-naples .layout-sidebar .layout-menu > li > a i {
	color: #eceff4;
}
.layout-menu-naples .layout-sidebar .layout-menu > li > a:hover {
	background-color: rgba(255, 255, 255, 0.24);
}
.layout-menu-naples .layout-sidebar .layout-menu > li.active-menuitem > a {
	background-color: #A3BE8C;
}
.layout-menu-naples .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
	color: #3b4252;
}
.layout-menu-naples .layout-sidebar .layout-menu > li.active-menuitem > a i {
	color: #2e3440;
}
.layout-menu-naples .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
	background-color: #A3BE8C;
}
.layout-menu-naples .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
	color: #3b4252;
}
.layout-menu-naples .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
	color: #2e3440;
}
.layout-menu-naples .layout-sidebar .layout-menu > li ul {
	background-color: rgba(255, 255, 255, 0.12);
}
.layout-menu-naples .layout-sidebar .layout-menu > li ul li a .menuitem-text {
	color: #eceff4;
}
.layout-menu-naples .layout-sidebar .layout-menu > li ul li a i {
	color: #eceff4;
}
.layout-menu-naples .layout-sidebar .layout-menu > li ul li a:hover {
	background-color: rgba(0, 0, 0, 0.36);
}
.layout-menu-naples .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
	color: #A3BE8C;
}
.layout-menu-naples .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
	color: #A3BE8C;
}
@media (min-width: 1200px) {
	.layout-menu-naples.layout-horizontal .layout-sidebar {
		background-color: #312F3A;
		background-image: url("../../images/menu/naples-horizontal.jpg");
		background-size: auto 100%;
		background-repeat: no-repeat;
	}
	.layout-menu-naples.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
		color: #A3BE8C;
	}
	.layout-menu-naples.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
		color: #A3BE8C;
	}
	.layout-menu-naples.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-naples.layout-horizontal .layout-sidebar .layout-menu > li > a i {
		color: #eceff4;
	}
	.layout-menu-naples.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
		background-color: rgba(255, 255, 255, 0.24);
	}
	.layout-menu-naples.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
		background-color: #A3BE8C;
	}
	.layout-menu-naples.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
		color: #3b4252;
	}
	.layout-menu-naples.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
		color: #2e3440;
	}
	.layout-menu-naples.layout-horizontal .layout-sidebar .layout-menu > li ul {
		background-color: #2e3440;
	}
	.layout-menu-naples.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-naples.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
		color: #eceff4;
	}
	.layout-menu-naples.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
		background-color: rgba(0, 0, 0, 0.36);
	}
	.layout-menu-naples.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
		color: #A3BE8C;
	}
	.layout-menu-naples.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
		color: #A3BE8C;
	}
}

.layout-menu-georgia .layout-sidebar {
	background-color: #282C36;
	background-image: url("../../images/menu/georgia.jpg");
	background-size: 240px 100%;
	background-repeat: no-repeat;
}
.layout-menu-georgia .layout-sidebar .sidebar-logo .sidebar-pin {
	background-color: #ffffff;
}
.layout-menu-georgia .layout-sidebar .sidebar-logo .sidebar-pin i {
	color: #2e3440;
}
.layout-menu-georgia .layout-sidebar .sidebar-logo .app-name {
	color: #ffffff;
}
.layout-menu-georgia .layout-sidebar .layout-menu li > a {
	cursor: pointer;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	outline: none;
}
.layout-menu-georgia .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
	color: #ebcb8b;
}
.layout-menu-georgia .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
	color: #ebcb8b;
}
.layout-menu-georgia .layout-sidebar .layout-menu > li > a .menuitem-text {
	color: #eceff4;
}
.layout-menu-georgia .layout-sidebar .layout-menu > li > a i {
	color: #eceff4;
}
.layout-menu-georgia .layout-sidebar .layout-menu > li > a:hover {
	background-color: rgba(255, 255, 255, 0.24);
}
.layout-menu-georgia .layout-sidebar .layout-menu > li.active-menuitem > a {
	background-color: #ebcb8b;
}
.layout-menu-georgia .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
	color: #3b4252;
}
.layout-menu-georgia .layout-sidebar .layout-menu > li.active-menuitem > a i {
	color: #2e3440;
}
.layout-menu-georgia .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
	background-color: #ebcb8b;
}
.layout-menu-georgia .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
	color: #3b4252;
}
.layout-menu-georgia .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
	color: #2e3440;
}
.layout-menu-georgia .layout-sidebar .layout-menu > li ul {
	background-color: rgba(255, 255, 255, 0.12);
}
.layout-menu-georgia .layout-sidebar .layout-menu > li ul li a .menuitem-text {
	color: #eceff4;
}
.layout-menu-georgia .layout-sidebar .layout-menu > li ul li a i {
	color: #eceff4;
}
.layout-menu-georgia .layout-sidebar .layout-menu > li ul li a:hover {
	background-color: rgba(0, 0, 0, 0.36);
}
.layout-menu-georgia .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
	color: #ebcb8b;
}
.layout-menu-georgia .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
	color: #ebcb8b;
}
@media (min-width: 1200px) {
	.layout-menu-georgia.layout-horizontal .layout-sidebar {
		background-color: #282C36;
		background-image: url("../../images/menu/georgia-horizontal.jpg");
		background-size: auto 100%;
		background-repeat: no-repeat;
	}
	.layout-menu-georgia.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
		color: #ebcb8b;
	}
	.layout-menu-georgia.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
		color: #ebcb8b;
	}
	.layout-menu-georgia.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-georgia.layout-horizontal .layout-sidebar .layout-menu > li > a i {
		color: #eceff4;
	}
	.layout-menu-georgia.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
		background-color: rgba(255, 255, 255, 0.24);
	}
	.layout-menu-georgia.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
		background-color: #ebcb8b;
	}
	.layout-menu-georgia.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
		color: #3b4252;
	}
	.layout-menu-georgia.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
		color: #2e3440;
	}
	.layout-menu-georgia.layout-horizontal .layout-sidebar .layout-menu > li ul {
		background-color: #2e3440;
	}
	.layout-menu-georgia.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-georgia.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
		color: #eceff4;
	}
	.layout-menu-georgia.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
		background-color: rgba(0, 0, 0, 0.36);
	}
	.layout-menu-georgia.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
		color: #ebcb8b;
	}
	.layout-menu-georgia.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
		color: #ebcb8b;
	}
}

.layout-menu-infinity .layout-sidebar {
	background-color: #202634;
	background-image: url("../../images/menu/infinity.jpg");
	background-size: 240px 100%;
	background-repeat: no-repeat;
}
.layout-menu-infinity .layout-sidebar .sidebar-logo .sidebar-pin {
	background-color: #ffffff;
}
.layout-menu-infinity .layout-sidebar .sidebar-logo .sidebar-pin i {
	color: #2e3440;
}
.layout-menu-infinity .layout-sidebar .sidebar-logo .app-name {
	color: #ffffff;
}
.layout-menu-infinity .layout-sidebar .layout-menu li > a {
	cursor: pointer;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	outline: none;
}
.layout-menu-infinity .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
	color: #88C0D0;
}
.layout-menu-infinity .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
	color: #88C0D0;
}
.layout-menu-infinity .layout-sidebar .layout-menu > li > a .menuitem-text {
	color: #eceff4;
}
.layout-menu-infinity .layout-sidebar .layout-menu > li > a i {
	color: #eceff4;
}
.layout-menu-infinity .layout-sidebar .layout-menu > li > a:hover {
	background-color: rgba(255, 255, 255, 0.24);
}
.layout-menu-infinity .layout-sidebar .layout-menu > li.active-menuitem > a {
	background-color: #88C0D0;
}
.layout-menu-infinity .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
	color: #3b4252;
}
.layout-menu-infinity .layout-sidebar .layout-menu > li.active-menuitem > a i {
	color: #2e3440;
}
.layout-menu-infinity .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
	background-color: #88C0D0;
}
.layout-menu-infinity .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
	color: #3b4252;
}
.layout-menu-infinity .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
	color: #2e3440;
}
.layout-menu-infinity .layout-sidebar .layout-menu > li ul {
	background-color: rgba(255, 255, 255, 0.12);
}
.layout-menu-infinity .layout-sidebar .layout-menu > li ul li a .menuitem-text {
	color: #eceff4;
}
.layout-menu-infinity .layout-sidebar .layout-menu > li ul li a i {
	color: #eceff4;
}
.layout-menu-infinity .layout-sidebar .layout-menu > li ul li a:hover {
	background-color: rgba(0, 0, 0, 0.36);
}
.layout-menu-infinity .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
	color: #88C0D0;
}
.layout-menu-infinity .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
	color: #88C0D0;
}
@media (min-width: 1200px) {
	.layout-menu-infinity.layout-horizontal .layout-sidebar {
		background-color: #202634;
		background-image: url("../../images/menu/infinity-horizontal.jpg");
		background-size: auto 100%;
		background-repeat: no-repeat;
	}
	.layout-menu-infinity.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
		color: #88C0D0;
	}
	.layout-menu-infinity.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
		color: #88C0D0;
	}
	.layout-menu-infinity.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-infinity.layout-horizontal .layout-sidebar .layout-menu > li > a i {
		color: #eceff4;
	}
	.layout-menu-infinity.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
		background-color: rgba(255, 255, 255, 0.24);
	}
	.layout-menu-infinity.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
		background-color: #88C0D0;
	}
	.layout-menu-infinity.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
		color: #3b4252;
	}
	.layout-menu-infinity.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
		color: #2e3440;
	}
	.layout-menu-infinity.layout-horizontal .layout-sidebar .layout-menu > li ul {
		background-color: #2e3440;
	}
	.layout-menu-infinity.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-infinity.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
		color: #eceff4;
	}
	.layout-menu-infinity.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
		background-color: rgba(0, 0, 0, 0.36);
	}
	.layout-menu-infinity.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
		color: #88C0D0;
	}
	.layout-menu-infinity.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
		color: #88C0D0;
	}
}

.layout-menu-chicago .layout-sidebar {
	background-color: #2A2E38;
	background-image: url("../../images/menu/chicago.jpg");
	background-size: 240px 100%;
	background-repeat: no-repeat;
}
.layout-menu-chicago .layout-sidebar .sidebar-logo .sidebar-pin {
	background-color: #ffffff;
}
.layout-menu-chicago .layout-sidebar .sidebar-logo .sidebar-pin i {
	color: #2e3440;
}
.layout-menu-chicago .layout-sidebar .sidebar-logo .app-name {
	color: #ffffff;
}
.layout-menu-chicago .layout-sidebar .layout-menu li > a {
	cursor: pointer;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	outline: none;
}
.layout-menu-chicago .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
	color: #EBCB8B;
}
.layout-menu-chicago .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
	color: #EBCB8B;
}
.layout-menu-chicago .layout-sidebar .layout-menu > li > a .menuitem-text {
	color: #eceff4;
}
.layout-menu-chicago .layout-sidebar .layout-menu > li > a i {
	color: #eceff4;
}
.layout-menu-chicago .layout-sidebar .layout-menu > li > a:hover {
	background-color: rgba(255, 255, 255, 0.24);
}
.layout-menu-chicago .layout-sidebar .layout-menu > li.active-menuitem > a {
	background-color: #EBCB8B;
}
.layout-menu-chicago .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
	color: #3b4252;
}
.layout-menu-chicago .layout-sidebar .layout-menu > li.active-menuitem > a i {
	color: #2e3440;
}
.layout-menu-chicago .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
	background-color: #EBCB8B;
}
.layout-menu-chicago .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
	color: #3b4252;
}
.layout-menu-chicago .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
	color: #2e3440;
}
.layout-menu-chicago .layout-sidebar .layout-menu > li ul {
	background-color: rgba(255, 255, 255, 0.12);
}
.layout-menu-chicago .layout-sidebar .layout-menu > li ul li a .menuitem-text {
	color: #eceff4;
}
.layout-menu-chicago .layout-sidebar .layout-menu > li ul li a i {
	color: #eceff4;
}
.layout-menu-chicago .layout-sidebar .layout-menu > li ul li a:hover {
	background-color: rgba(0, 0, 0, 0.36);
}
.layout-menu-chicago .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
	color: #EBCB8B;
}
.layout-menu-chicago .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
	color: #EBCB8B;
}
@media (min-width: 1200px) {
	.layout-menu-chicago.layout-horizontal .layout-sidebar {
		background-color: #2A2E38;
		background-image: url("../../images/menu/chicago-horizontal.jpg");
		background-size: auto 100%;
		background-repeat: no-repeat;
	}
	.layout-menu-chicago.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
		color: #EBCB8B;
	}
	.layout-menu-chicago.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
		color: #EBCB8B;
	}
	.layout-menu-chicago.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-chicago.layout-horizontal .layout-sidebar .layout-menu > li > a i {
		color: #eceff4;
	}
	.layout-menu-chicago.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
		background-color: rgba(255, 255, 255, 0.24);
	}
	.layout-menu-chicago.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
		background-color: #EBCB8B;
	}
	.layout-menu-chicago.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
		color: #3b4252;
	}
	.layout-menu-chicago.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
		color: #2e3440;
	}
	.layout-menu-chicago.layout-horizontal .layout-sidebar .layout-menu > li ul {
		background-color: #2e3440;
	}
	.layout-menu-chicago.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-chicago.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
		color: #eceff4;
	}
	.layout-menu-chicago.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
		background-color: rgba(0, 0, 0, 0.36);
	}
	.layout-menu-chicago.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
		color: #EBCB8B;
	}
	.layout-menu-chicago.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
		color: #EBCB8B;
	}
}

.layout-menu-majesty .layout-sidebar {
	background-color: #4C5158;
	background-image: url("../../images/menu/majesty.jpg");
	background-size: 240px 100%;
	background-repeat: no-repeat;
}
.layout-menu-majesty .layout-sidebar .sidebar-logo .sidebar-pin {
	background-color: #ffffff;
}
.layout-menu-majesty .layout-sidebar .sidebar-logo .sidebar-pin i {
	color: #2e3440;
}
.layout-menu-majesty .layout-sidebar .sidebar-logo .app-name {
	color: #ffffff;
}
.layout-menu-majesty .layout-sidebar .layout-menu li > a {
	cursor: pointer;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	outline: none;
}
.layout-menu-majesty .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
	color: #88C0D0;
}
.layout-menu-majesty .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
	color: #88C0D0;
}
.layout-menu-majesty .layout-sidebar .layout-menu > li > a .menuitem-text {
	color: #eceff4;
}
.layout-menu-majesty .layout-sidebar .layout-menu > li > a i {
	color: #eceff4;
}
.layout-menu-majesty .layout-sidebar .layout-menu > li > a:hover {
	background-color: rgba(255, 255, 255, 0.24);
}
.layout-menu-majesty .layout-sidebar .layout-menu > li.active-menuitem > a {
	background-color: #88C0D0;
}
.layout-menu-majesty .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
	color: #3b4252;
}
.layout-menu-majesty .layout-sidebar .layout-menu > li.active-menuitem > a i {
	color: #2e3440;
}
.layout-menu-majesty .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
	background-color: #88C0D0;
}
.layout-menu-majesty .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
	color: #3b4252;
}
.layout-menu-majesty .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
	color: #2e3440;
}
.layout-menu-majesty .layout-sidebar .layout-menu > li ul {
	background-color: rgba(255, 255, 255, 0.12);
}
.layout-menu-majesty .layout-sidebar .layout-menu > li ul li a .menuitem-text {
	color: #eceff4;
}
.layout-menu-majesty .layout-sidebar .layout-menu > li ul li a i {
	color: #eceff4;
}
.layout-menu-majesty .layout-sidebar .layout-menu > li ul li a:hover {
	background-color: rgba(0, 0, 0, 0.36);
}
.layout-menu-majesty .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
	color: #88C0D0;
}
.layout-menu-majesty .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
	color: #88C0D0;
}
@media (min-width: 1200px) {
	.layout-menu-majesty.layout-horizontal .layout-sidebar {
		background-color: #4C5158;
		background-image: url("../../images/menu/majesty-horizontal.jpg");
		background-size: auto 100%;
		background-repeat: no-repeat;
	}
	.layout-menu-majesty.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
		color: #88C0D0;
	}
	.layout-menu-majesty.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
		color: #88C0D0;
	}
	.layout-menu-majesty.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-majesty.layout-horizontal .layout-sidebar .layout-menu > li > a i {
		color: #eceff4;
	}
	.layout-menu-majesty.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
		background-color: rgba(255, 255, 255, 0.24);
	}
	.layout-menu-majesty.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
		background-color: #88C0D0;
	}
	.layout-menu-majesty.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
		color: #3b4252;
	}
	.layout-menu-majesty.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
		color: #2e3440;
	}
	.layout-menu-majesty.layout-horizontal .layout-sidebar .layout-menu > li ul {
		background-color: #2e3440;
	}
	.layout-menu-majesty.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-majesty.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
		color: #eceff4;
	}
	.layout-menu-majesty.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
		background-color: rgba(0, 0, 0, 0.36);
	}
	.layout-menu-majesty.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
		color: #88C0D0;
	}
	.layout-menu-majesty.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
		color: #88C0D0;
	}
}

.layout-menu-fish .layout-sidebar {
	background-color: #264254;
	background-image: url("../../images/menu/fish.jpg");
	background-size: 240px 100%;
	background-repeat: no-repeat;
}
.layout-menu-fish .layout-sidebar .sidebar-logo .sidebar-pin {
	background-color: #ffffff;
}
.layout-menu-fish .layout-sidebar .sidebar-logo .sidebar-pin i {
	color: #2e3440;
}
.layout-menu-fish .layout-sidebar .sidebar-logo .app-name {
	color: #ffffff;
}
.layout-menu-fish .layout-sidebar .layout-menu li > a {
	cursor: pointer;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	outline: none;
}
.layout-menu-fish .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
	color: #B48EAD;
}
.layout-menu-fish .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
	color: #B48EAD;
}
.layout-menu-fish .layout-sidebar .layout-menu > li > a .menuitem-text {
	color: #eceff4;
}
.layout-menu-fish .layout-sidebar .layout-menu > li > a i {
	color: #eceff4;
}
.layout-menu-fish .layout-sidebar .layout-menu > li > a:hover {
	background-color: rgba(255, 255, 255, 0.24);
}
.layout-menu-fish .layout-sidebar .layout-menu > li.active-menuitem > a {
	background-color: #B48EAD;
}
.layout-menu-fish .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
	color: #3b4252;
}
.layout-menu-fish .layout-sidebar .layout-menu > li.active-menuitem > a i {
	color: #2e3440;
}
.layout-menu-fish .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
	background-color: #B48EAD;
}
.layout-menu-fish .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
	color: #3b4252;
}
.layout-menu-fish .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
	color: #2e3440;
}
.layout-menu-fish .layout-sidebar .layout-menu > li ul {
	background-color: rgba(255, 255, 255, 0.12);
}
.layout-menu-fish .layout-sidebar .layout-menu > li ul li a .menuitem-text {
	color: #eceff4;
}
.layout-menu-fish .layout-sidebar .layout-menu > li ul li a i {
	color: #eceff4;
}
.layout-menu-fish .layout-sidebar .layout-menu > li ul li a:hover {
	background-color: rgba(0, 0, 0, 0.36);
}
.layout-menu-fish .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
	color: #B48EAD;
}
.layout-menu-fish .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
	color: #B48EAD;
}
@media (min-width: 1200px) {
	.layout-menu-fish.layout-horizontal .layout-sidebar {
		background-color: #264254;
		background-image: url("../../images/menu/fish-horizontal.jpg");
		background-size: auto 100%;
		background-repeat: no-repeat;
	}
	.layout-menu-fish.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
		color: #B48EAD;
	}
	.layout-menu-fish.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
		color: #B48EAD;
	}
	.layout-menu-fish.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-fish.layout-horizontal .layout-sidebar .layout-menu > li > a i {
		color: #eceff4;
	}
	.layout-menu-fish.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
		background-color: rgba(255, 255, 255, 0.24);
	}
	.layout-menu-fish.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
		background-color: #B48EAD;
	}
	.layout-menu-fish.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
		color: #3b4252;
	}
	.layout-menu-fish.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
		color: #2e3440;
	}
	.layout-menu-fish.layout-horizontal .layout-sidebar .layout-menu > li ul {
		background-color: #2e3440;
	}
	.layout-menu-fish.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-fish.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
		color: #eceff4;
	}
	.layout-menu-fish.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
		background-color: rgba(0, 0, 0, 0.36);
	}
	.layout-menu-fish.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
		color: #B48EAD;
	}
	.layout-menu-fish.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
		color: #B48EAD;
	}
}

.layout-menu-dawn .layout-sidebar {
	background-color: #20505D;
	background-image: url("../../images/menu/dawn.jpg");
	background-size: 240px 100%;
	background-repeat: no-repeat;
}
.layout-menu-dawn .layout-sidebar .sidebar-logo .sidebar-pin {
	background-color: #ffffff;
}
.layout-menu-dawn .layout-sidebar .sidebar-logo .sidebar-pin i {
	color: #2e3440;
}
.layout-menu-dawn .layout-sidebar .sidebar-logo .app-name {
	color: #ffffff;
}
.layout-menu-dawn .layout-sidebar .layout-menu li > a {
	cursor: pointer;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	outline: none;
}
.layout-menu-dawn .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
	color: #EBCB8B;
}
.layout-menu-dawn .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
	color: #EBCB8B;
}
.layout-menu-dawn .layout-sidebar .layout-menu > li > a .menuitem-text {
	color: #eceff4;
}
.layout-menu-dawn .layout-sidebar .layout-menu > li > a i {
	color: #eceff4;
}
.layout-menu-dawn .layout-sidebar .layout-menu > li > a:hover {
	background-color: rgba(255, 255, 255, 0.24);
}
.layout-menu-dawn .layout-sidebar .layout-menu > li.active-menuitem > a {
	background-color: #EBCB8B;
}
.layout-menu-dawn .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
	color: #3b4252;
}
.layout-menu-dawn .layout-sidebar .layout-menu > li.active-menuitem > a i {
	color: #2e3440;
}
.layout-menu-dawn .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
	background-color: #EBCB8B;
}
.layout-menu-dawn .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
	color: #3b4252;
}
.layout-menu-dawn .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
	color: #2e3440;
}
.layout-menu-dawn .layout-sidebar .layout-menu > li ul {
	background-color: rgba(255, 255, 255, 0.12);
}
.layout-menu-dawn .layout-sidebar .layout-menu > li ul li a .menuitem-text {
	color: #eceff4;
}
.layout-menu-dawn .layout-sidebar .layout-menu > li ul li a i {
	color: #eceff4;
}
.layout-menu-dawn .layout-sidebar .layout-menu > li ul li a:hover {
	background-color: rgba(0, 0, 0, 0.36);
}
.layout-menu-dawn .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
	color: #EBCB8B;
}
.layout-menu-dawn .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
	color: #EBCB8B;
}
@media (min-width: 1200px) {
	.layout-menu-dawn.layout-horizontal .layout-sidebar {
		background-color: #20505D;
		background-image: url("../../images/menu/dawn-horizontal.jpg");
		background-size: auto 100%;
		background-repeat: no-repeat;
	}
	.layout-menu-dawn.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
		color: #EBCB8B;
	}
	.layout-menu-dawn.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
		color: #EBCB8B;
	}
	.layout-menu-dawn.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-dawn.layout-horizontal .layout-sidebar .layout-menu > li > a i {
		color: #eceff4;
	}
	.layout-menu-dawn.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
		background-color: rgba(255, 255, 255, 0.24);
	}
	.layout-menu-dawn.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
		background-color: #EBCB8B;
	}
	.layout-menu-dawn.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
		color: #3b4252;
	}
	.layout-menu-dawn.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
		color: #2e3440;
	}
	.layout-menu-dawn.layout-horizontal .layout-sidebar .layout-menu > li ul {
		background-color: #2e3440;
	}
	.layout-menu-dawn.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-dawn.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
		color: #eceff4;
	}
	.layout-menu-dawn.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
		background-color: rgba(0, 0, 0, 0.36);
	}
	.layout-menu-dawn.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
		color: #EBCB8B;
	}
	.layout-menu-dawn.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
		color: #EBCB8B;
	}
}

.layout-menu-record .layout-sidebar {
	background-color: #202928;
	background-image: url("../../images/menu/record.jpg");
	background-size: 240px 100%;
	background-repeat: no-repeat;
}
.layout-menu-record .layout-sidebar .sidebar-logo .sidebar-pin {
	background-color: #ffffff;
}
.layout-menu-record .layout-sidebar .sidebar-logo .sidebar-pin i {
	color: #2e3440;
}
.layout-menu-record .layout-sidebar .sidebar-logo .app-name {
	color: #ffffff;
}
.layout-menu-record .layout-sidebar .layout-menu li > a {
	cursor: pointer;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	outline: none;
}
.layout-menu-record .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
	color: #D08770;
}
.layout-menu-record .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
	color: #D08770;
}
.layout-menu-record .layout-sidebar .layout-menu > li > a .menuitem-text {
	color: #eceff4;
}
.layout-menu-record .layout-sidebar .layout-menu > li > a i {
	color: #eceff4;
}
.layout-menu-record .layout-sidebar .layout-menu > li > a:hover {
	background-color: rgba(255, 255, 255, 0.24);
}
.layout-menu-record .layout-sidebar .layout-menu > li.active-menuitem > a {
	background-color: #D08770;
}
.layout-menu-record .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
	color: #3b4252;
}
.layout-menu-record .layout-sidebar .layout-menu > li.active-menuitem > a i {
	color: #2e3440;
}
.layout-menu-record .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
	background-color: #D08770;
}
.layout-menu-record .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
	color: #3b4252;
}
.layout-menu-record .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
	color: #2e3440;
}
.layout-menu-record .layout-sidebar .layout-menu > li ul {
	background-color: rgba(255, 255, 255, 0.12);
}
.layout-menu-record .layout-sidebar .layout-menu > li ul li a .menuitem-text {
	color: #eceff4;
}
.layout-menu-record .layout-sidebar .layout-menu > li ul li a i {
	color: #eceff4;
}
.layout-menu-record .layout-sidebar .layout-menu > li ul li a:hover {
	background-color: rgba(0, 0, 0, 0.42);
}
.layout-menu-record .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
	color: #D08770;
}
.layout-menu-record .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
	color: #D08770;
}
@media (min-width: 1200px) {
	.layout-menu-record.layout-horizontal .layout-sidebar {
		background-color: #202928;
		background-image: url("../../images/menu/record-horizontal.jpg");
		background-size: auto 100%;
		background-repeat: no-repeat;
	}
	.layout-menu-record.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
		color: #D08770;
	}
	.layout-menu-record.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
		color: #D08770;
	}
	.layout-menu-record.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-record.layout-horizontal .layout-sidebar .layout-menu > li > a i {
		color: #eceff4;
	}
	.layout-menu-record.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
		background-color: rgba(255, 255, 255, 0.24);
	}
	.layout-menu-record.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
		background-color: #D08770;
	}
	.layout-menu-record.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
		color: #3b4252;
	}
	.layout-menu-record.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
		color: #2e3440;
	}
	.layout-menu-record.layout-horizontal .layout-sidebar .layout-menu > li ul {
		background-color: #2e3440;
	}
	.layout-menu-record.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-record.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
		color: #eceff4;
	}
	.layout-menu-record.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
		background-color: rgba(0, 0, 0, 0.42);
	}
	.layout-menu-record.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
		color: #D08770;
	}
	.layout-menu-record.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
		color: #D08770;
	}
}

.layout-menu-pool .layout-sidebar {
	background-color: #475A62;
	background-image: url("../../images/menu/pool.jpg");
	background-size: 240px 100%;
	background-repeat: no-repeat;
}
.layout-menu-pool .layout-sidebar .sidebar-logo .sidebar-pin {
	background-color: #ffffff;
}
.layout-menu-pool .layout-sidebar .sidebar-logo .sidebar-pin i {
	color: #2e3440;
}
.layout-menu-pool .layout-sidebar .sidebar-logo .app-name {
	color: #ffffff;
}
.layout-menu-pool .layout-sidebar .layout-menu li > a {
	cursor: pointer;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	outline: none;
}
.layout-menu-pool .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
	color: #A3BE8C;
}
.layout-menu-pool .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
	color: #A3BE8C;
}
.layout-menu-pool .layout-sidebar .layout-menu > li > a .menuitem-text {
	color: #eceff4;
}
.layout-menu-pool .layout-sidebar .layout-menu > li > a i {
	color: #eceff4;
}
.layout-menu-pool .layout-sidebar .layout-menu > li > a:hover {
	background-color: rgba(255, 255, 255, 0.24);
}
.layout-menu-pool .layout-sidebar .layout-menu > li.active-menuitem > a {
	background-color: #A3BE8C;
}
.layout-menu-pool .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
	color: #3b4252;
}
.layout-menu-pool .layout-sidebar .layout-menu > li.active-menuitem > a i {
	color: #2e3440;
}
.layout-menu-pool .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
	background-color: #A3BE8C;
}
.layout-menu-pool .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
	color: #3b4252;
}
.layout-menu-pool .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
	color: #2e3440;
}
.layout-menu-pool .layout-sidebar .layout-menu > li ul {
	background-color: rgba(255, 255, 255, 0.12);
}
.layout-menu-pool .layout-sidebar .layout-menu > li ul li a .menuitem-text {
	color: #eceff4;
}
.layout-menu-pool .layout-sidebar .layout-menu > li ul li a i {
	color: #eceff4;
}
.layout-menu-pool .layout-sidebar .layout-menu > li ul li a:hover {
	background-color: rgba(0, 0, 0, 0.36);
}
.layout-menu-pool .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
	color: #A3BE8C;
}
.layout-menu-pool .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
	color: #A3BE8C;
}
@media (min-width: 1200px) {
	.layout-menu-pool.layout-horizontal .layout-sidebar {
		background-color: #475A62;
		background-image: url("../../images/menu/pool-horizontal.jpg");
		background-size: auto 100%;
		background-repeat: no-repeat;
	}
	.layout-menu-pool.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
		color: #A3BE8C;
	}
	.layout-menu-pool.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
		color: #A3BE8C;
	}
	.layout-menu-pool.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-pool.layout-horizontal .layout-sidebar .layout-menu > li > a i {
		color: #eceff4;
	}
	.layout-menu-pool.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
		background-color: rgba(255, 255, 255, 0.24);
	}
	.layout-menu-pool.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
		background-color: #A3BE8C;
	}
	.layout-menu-pool.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
		color: #3b4252;
	}
	.layout-menu-pool.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
		color: #2e3440;
	}
	.layout-menu-pool.layout-horizontal .layout-sidebar .layout-menu > li ul {
		background-color: #2e3440;
	}
	.layout-menu-pool.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-pool.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
		color: #eceff4;
	}
	.layout-menu-pool.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
		background-color: rgba(0, 0, 0, 0.36);
	}
	.layout-menu-pool.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
		color: #A3BE8C;
	}
	.layout-menu-pool.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
		color: #A3BE8C;
	}
}

.layout-menu-metal .layout-sidebar {
	background-color: #262A33;
	background-image: url("../../images/menu/metal.jpg");
	background-size: 240px 100%;
	background-repeat: no-repeat;
}
.layout-menu-metal .layout-sidebar .sidebar-logo .sidebar-pin {
	background-color: #ffffff;
}
.layout-menu-metal .layout-sidebar .sidebar-logo .sidebar-pin i {
	color: #2e3440;
}
.layout-menu-metal .layout-sidebar .sidebar-logo .app-name {
	color: #ffffff;
}
.layout-menu-metal .layout-sidebar .layout-menu li > a {
	cursor: pointer;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	outline: none;
}
.layout-menu-metal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
	color: #EBCB8B;
}
.layout-menu-metal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
	color: #EBCB8B;
}
.layout-menu-metal .layout-sidebar .layout-menu > li > a .menuitem-text {
	color: #eceff4;
}
.layout-menu-metal .layout-sidebar .layout-menu > li > a i {
	color: #eceff4;
}
.layout-menu-metal .layout-sidebar .layout-menu > li > a:hover {
	background-color: rgba(255, 255, 255, 0.24);
}
.layout-menu-metal .layout-sidebar .layout-menu > li.active-menuitem > a {
	background-color: #EBCB8B;
}
.layout-menu-metal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
	color: #3b4252;
}
.layout-menu-metal .layout-sidebar .layout-menu > li.active-menuitem > a i {
	color: #2e3440;
}
.layout-menu-metal .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
	background-color: #EBCB8B;
}
.layout-menu-metal .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
	color: #3b4252;
}
.layout-menu-metal .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
	color: #2e3440;
}
.layout-menu-metal .layout-sidebar .layout-menu > li ul {
	background-color: rgba(255, 255, 255, 0.12);
}
.layout-menu-metal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
	color: #eceff4;
}
.layout-menu-metal .layout-sidebar .layout-menu > li ul li a i {
	color: #eceff4;
}
.layout-menu-metal .layout-sidebar .layout-menu > li ul li a:hover {
	background-color: rgba(0, 0, 0, 0.36);
}
.layout-menu-metal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
	color: #EBCB8B;
}
.layout-menu-metal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
	color: #EBCB8B;
}
@media (min-width: 1200px) {
	.layout-menu-metal.layout-horizontal .layout-sidebar {
		background-color: #262A33;
		background-image: url("../../images/menu/metal-horizontal.jpg");
		background-size: auto 100%;
		background-repeat: no-repeat;
	}
	.layout-menu-metal.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
		color: #EBCB8B;
	}
	.layout-menu-metal.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
		color: #EBCB8B;
	}
	.layout-menu-metal.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-metal.layout-horizontal .layout-sidebar .layout-menu > li > a i {
		color: #eceff4;
	}
	.layout-menu-metal.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
		background-color: rgba(255, 255, 255, 0.24);
	}
	.layout-menu-metal.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
		background-color: #EBCB8B;
	}
	.layout-menu-metal.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
		color: #3b4252;
	}
	.layout-menu-metal.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
		color: #2e3440;
	}
	.layout-menu-metal.layout-horizontal .layout-sidebar .layout-menu > li ul {
		background-color: #2e3440;
	}
	.layout-menu-metal.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-metal.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
		color: #eceff4;
	}
	.layout-menu-metal.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
		background-color: rgba(0, 0, 0, 0.36);
	}
	.layout-menu-metal.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
		color: #EBCB8B;
	}
	.layout-menu-metal.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
		color: #EBCB8B;
	}
}

.layout-menu-china .layout-sidebar {
	background-color: #1E2A31;
	background-image: url("../../images/menu/china.jpg");
	background-size: 240px 100%;
	background-repeat: no-repeat;
}
.layout-menu-china .layout-sidebar .sidebar-logo .sidebar-pin {
	background-color: #ffffff;
}
.layout-menu-china .layout-sidebar .sidebar-logo .sidebar-pin i {
	color: #2e3440;
}
.layout-menu-china .layout-sidebar .sidebar-logo .app-name {
	color: #ffffff;
}
.layout-menu-china .layout-sidebar .layout-menu li > a {
	cursor: pointer;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	outline: none;
}
.layout-menu-china .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
	color: #BF616A;
}
.layout-menu-china .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
	color: #BF616A;
}
.layout-menu-china .layout-sidebar .layout-menu > li > a .menuitem-text {
	color: #eceff4;
}
.layout-menu-china .layout-sidebar .layout-menu > li > a i {
	color: #eceff4;
}
.layout-menu-china .layout-sidebar .layout-menu > li > a:hover {
	background-color: rgba(255, 255, 255, 0.24);
}
.layout-menu-china .layout-sidebar .layout-menu > li.active-menuitem > a {
	background-color: #BF616A;
}
.layout-menu-china .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
	color: #3b4252;
}
.layout-menu-china .layout-sidebar .layout-menu > li.active-menuitem > a i {
	color: #2e3440;
}
.layout-menu-china .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
	background-color: #BF616A;
}
.layout-menu-china .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
	color: #3b4252;
}
.layout-menu-china .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
	color: #2e3440;
}
.layout-menu-china .layout-sidebar .layout-menu > li ul {
	background-color: rgba(255, 255, 255, 0.12);
}
.layout-menu-china .layout-sidebar .layout-menu > li ul li a .menuitem-text {
	color: #eceff4;
}
.layout-menu-china .layout-sidebar .layout-menu > li ul li a i {
	color: #eceff4;
}
.layout-menu-china .layout-sidebar .layout-menu > li ul li a:hover {
	background-color: rgba(0, 0, 0, 0.32);
}
.layout-menu-china .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
	color: #BF616A;
}
.layout-menu-china .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
	color: #BF616A;
}
@media (min-width: 1200px) {
	.layout-menu-china.layout-horizontal .layout-sidebar {
		background-color: #1E2A31;
		background-image: url("../../images/menu/china-horizontal.jpg");
		background-size: auto 100%;
		background-repeat: no-repeat;
	}
	.layout-menu-china.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
		color: #BF616A;
	}
	.layout-menu-china.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
		color: #BF616A;
	}
	.layout-menu-china.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-china.layout-horizontal .layout-sidebar .layout-menu > li > a i {
		color: #eceff4;
	}
	.layout-menu-china.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
		background-color: rgba(255, 255, 255, 0.24);
	}
	.layout-menu-china.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
		background-color: #BF616A;
	}
	.layout-menu-china.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
		color: #3b4252;
	}
	.layout-menu-china.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
		color: #2e3440;
	}
	.layout-menu-china.layout-horizontal .layout-sidebar .layout-menu > li ul {
		background-color: #2e3440;
	}
	.layout-menu-china.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-china.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
		color: #eceff4;
	}
	.layout-menu-china.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
		background-color: rgba(0, 0, 0, 0.32);
	}
	.layout-menu-china.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
		color: #BF616A;
	}
	.layout-menu-china.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
		color: #BF616A;
	}
}

.layout-menu-table .layout-sidebar {
	background-color: #4F5259;
	background-image: url("../../images/menu/table.jpg");
	background-size: 240px 100%;
	background-repeat: no-repeat;
}
.layout-menu-table .layout-sidebar .sidebar-logo .sidebar-pin {
	background-color: #ffffff;
}
.layout-menu-table .layout-sidebar .sidebar-logo .sidebar-pin i {
	color: #2e3440;
}
.layout-menu-table .layout-sidebar .sidebar-logo .app-name {
	color: #ffffff;
}
.layout-menu-table .layout-sidebar .layout-menu li > a {
	cursor: pointer;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	outline: none;
}
.layout-menu-table .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
	color: #EBCB8B;
}
.layout-menu-table .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
	color: #EBCB8B;
}
.layout-menu-table .layout-sidebar .layout-menu > li > a .menuitem-text {
	color: #eceff4;
}
.layout-menu-table .layout-sidebar .layout-menu > li > a i {
	color: #eceff4;
}
.layout-menu-table .layout-sidebar .layout-menu > li > a:hover {
	background-color: rgba(255, 255, 255, 0.24);
}
.layout-menu-table .layout-sidebar .layout-menu > li.active-menuitem > a {
	background-color: #EBCB8B;
}
.layout-menu-table .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
	color: #3b4252;
}
.layout-menu-table .layout-sidebar .layout-menu > li.active-menuitem > a i {
	color: #2e3440;
}
.layout-menu-table .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
	background-color: #EBCB8B;
}
.layout-menu-table .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
	color: #3b4252;
}
.layout-menu-table .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
	color: #2e3440;
}
.layout-menu-table .layout-sidebar .layout-menu > li ul {
	background-color: rgba(255, 255, 255, 0.12);
}
.layout-menu-table .layout-sidebar .layout-menu > li ul li a .menuitem-text {
	color: #eceff4;
}
.layout-menu-table .layout-sidebar .layout-menu > li ul li a i {
	color: #eceff4;
}
.layout-menu-table .layout-sidebar .layout-menu > li ul li a:hover {
	background-color: rgba(0, 0, 0, 0.36);
}
.layout-menu-table .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
	color: #EBCB8B;
}
.layout-menu-table .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
	color: #EBCB8B;
}
@media (min-width: 1200px) {
	.layout-menu-table.layout-horizontal .layout-sidebar {
		background-color: #4F5259;
		background-image: url("../../images/menu/table-horizontal.jpg");
		background-size: auto 100%;
		background-repeat: no-repeat;
	}
	.layout-menu-table.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
		color: #EBCB8B;
	}
	.layout-menu-table.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
		color: #EBCB8B;
	}
	.layout-menu-table.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-table.layout-horizontal .layout-sidebar .layout-menu > li > a i {
		color: #eceff4;
	}
	.layout-menu-table.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
		background-color: rgba(255, 255, 255, 0.24);
	}
	.layout-menu-table.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
		background-color: #EBCB8B;
	}
	.layout-menu-table.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
		color: #3b4252;
	}
	.layout-menu-table.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
		color: #2e3440;
	}
	.layout-menu-table.layout-horizontal .layout-sidebar .layout-menu > li ul {
		background-color: #2e3440;
	}
	.layout-menu-table.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-table.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
		color: #eceff4;
	}
	.layout-menu-table.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
		background-color: rgba(0, 0, 0, 0.36);
	}
	.layout-menu-table.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
		color: #EBCB8B;
	}
	.layout-menu-table.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
		color: #EBCB8B;
	}
}

.layout-menu-panorama .layout-sidebar {
	background-color: #252E38;
	background-image: url("../../images/menu/panorama.jpg");
	background-size: 240px 100%;
	background-repeat: no-repeat;
}
.layout-menu-panorama .layout-sidebar .sidebar-logo .sidebar-pin {
	background-color: #ffffff;
}
.layout-menu-panorama .layout-sidebar .sidebar-logo .sidebar-pin i {
	color: #2e3440;
}
.layout-menu-panorama .layout-sidebar .sidebar-logo .app-name {
	color: #ffffff;
}
.layout-menu-panorama .layout-sidebar .layout-menu li > a {
	cursor: pointer;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	outline: none;
}
.layout-menu-panorama .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
	color: #88C0D0;
}
.layout-menu-panorama .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
	color: #88C0D0;
}
.layout-menu-panorama .layout-sidebar .layout-menu > li > a .menuitem-text {
	color: #eceff4;
}
.layout-menu-panorama .layout-sidebar .layout-menu > li > a i {
	color: #eceff4;
}
.layout-menu-panorama .layout-sidebar .layout-menu > li > a:hover {
	background-color: rgba(255, 255, 255, 0.24);
}
.layout-menu-panorama .layout-sidebar .layout-menu > li.active-menuitem > a {
	background-color: #88C0D0;
}
.layout-menu-panorama .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
	color: #3b4252;
}
.layout-menu-panorama .layout-sidebar .layout-menu > li.active-menuitem > a i {
	color: #2e3440;
}
.layout-menu-panorama .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
	background-color: #88C0D0;
}
.layout-menu-panorama .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
	color: #3b4252;
}
.layout-menu-panorama .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
	color: #2e3440;
}
.layout-menu-panorama .layout-sidebar .layout-menu > li ul {
	background-color: rgba(255, 255, 255, 0.12);
}
.layout-menu-panorama .layout-sidebar .layout-menu > li ul li a .menuitem-text {
	color: #eceff4;
}
.layout-menu-panorama .layout-sidebar .layout-menu > li ul li a i {
	color: #eceff4;
}
.layout-menu-panorama .layout-sidebar .layout-menu > li ul li a:hover {
	background-color: rgba(0, 0, 0, 0.32);
}
.layout-menu-panorama .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
	color: #88C0D0;
}
.layout-menu-panorama .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
	color: #88C0D0;
}
@media (min-width: 1200px) {
	.layout-menu-panorama.layout-horizontal .layout-sidebar {
		background-color: #252E38;
		background-image: url("../../images/menu/panorama-horizontal.jpg");
		background-size: auto 100%;
		background-repeat: no-repeat;
	}
	.layout-menu-panorama.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
		color: #88C0D0;
	}
	.layout-menu-panorama.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
		color: #88C0D0;
	}
	.layout-menu-panorama.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-panorama.layout-horizontal .layout-sidebar .layout-menu > li > a i {
		color: #eceff4;
	}
	.layout-menu-panorama.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
		background-color: rgba(255, 255, 255, 0.24);
	}
	.layout-menu-panorama.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
		background-color: #88C0D0;
	}
	.layout-menu-panorama.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
		color: #3b4252;
	}
	.layout-menu-panorama.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
		color: #2e3440;
	}
	.layout-menu-panorama.layout-horizontal .layout-sidebar .layout-menu > li ul {
		background-color: #2e3440;
	}
	.layout-menu-panorama.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-panorama.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
		color: #eceff4;
	}
	.layout-menu-panorama.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
		background-color: rgba(0, 0, 0, 0.32);
	}
	.layout-menu-panorama.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
		color: #88C0D0;
	}
	.layout-menu-panorama.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
		color: #88C0D0;
	}
}

.layout-menu-barcelona .layout-sidebar {
	background-color: #222932;
	background-image: url("../../images/menu/barcelona.jpg");
	background-size: 240px 100%;
	background-repeat: no-repeat;
}
.layout-menu-barcelona .layout-sidebar .sidebar-logo .sidebar-pin {
	background-color: #ffffff;
}
.layout-menu-barcelona .layout-sidebar .sidebar-logo .sidebar-pin i {
	color: #2e3440;
}
.layout-menu-barcelona .layout-sidebar .sidebar-logo .app-name {
	color: #ffffff;
}
.layout-menu-barcelona .layout-sidebar .layout-menu li > a {
	cursor: pointer;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	outline: none;
}
.layout-menu-barcelona .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
	color: #EBCB8B;
}
.layout-menu-barcelona .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
	color: #EBCB8B;
}
.layout-menu-barcelona .layout-sidebar .layout-menu > li > a .menuitem-text {
	color: #eceff4;
}
.layout-menu-barcelona .layout-sidebar .layout-menu > li > a i {
	color: #eceff4;
}
.layout-menu-barcelona .layout-sidebar .layout-menu > li > a:hover {
	background-color: rgba(255, 255, 255, 0.24);
}
.layout-menu-barcelona .layout-sidebar .layout-menu > li.active-menuitem > a {
	background-color: #EBCB8B;
}
.layout-menu-barcelona .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
	color: #3b4252;
}
.layout-menu-barcelona .layout-sidebar .layout-menu > li.active-menuitem > a i {
	color: #2e3440;
}
.layout-menu-barcelona .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
	background-color: #EBCB8B;
}
.layout-menu-barcelona .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
	color: #3b4252;
}
.layout-menu-barcelona .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
	color: #2e3440;
}
.layout-menu-barcelona .layout-sidebar .layout-menu > li ul {
	background-color: rgba(255, 255, 255, 0.12);
}
.layout-menu-barcelona .layout-sidebar .layout-menu > li ul li a .menuitem-text {
	color: #eceff4;
}
.layout-menu-barcelona .layout-sidebar .layout-menu > li ul li a i {
	color: #eceff4;
}
.layout-menu-barcelona .layout-sidebar .layout-menu > li ul li a:hover {
	background-color: rgba(0, 0, 0, 0.42);
}
.layout-menu-barcelona .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
	color: #EBCB8B;
}
.layout-menu-barcelona .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
	color: #EBCB8B;
}
@media (min-width: 1200px) {
	.layout-menu-barcelona.layout-horizontal .layout-sidebar {
		background-color: #222932;
		background-image: url("../../images/menu/barcelona-horizontal.jpg");
		background-size: auto 100%;
		background-repeat: no-repeat;
	}
	.layout-menu-barcelona.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
		color: #EBCB8B;
	}
	.layout-menu-barcelona.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
		color: #EBCB8B;
	}
	.layout-menu-barcelona.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-barcelona.layout-horizontal .layout-sidebar .layout-menu > li > a i {
		color: #eceff4;
	}
	.layout-menu-barcelona.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
		background-color: rgba(255, 255, 255, 0.24);
	}
	.layout-menu-barcelona.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
		background-color: #EBCB8B;
	}
	.layout-menu-barcelona.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
		color: #3b4252;
	}
	.layout-menu-barcelona.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
		color: #2e3440;
	}
	.layout-menu-barcelona.layout-horizontal .layout-sidebar .layout-menu > li ul {
		background-color: #2e3440;
	}
	.layout-menu-barcelona.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-barcelona.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
		color: #eceff4;
	}
	.layout-menu-barcelona.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
		background-color: rgba(0, 0, 0, 0.42);
	}
	.layout-menu-barcelona.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
		color: #EBCB8B;
	}
	.layout-menu-barcelona.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
		color: #EBCB8B;
	}
}

.layout-menu-underwater .layout-sidebar {
	background-color: #202631;
	background-image: url("../../images/menu/underwater.jpg");
	background-size: 240px 100%;
	background-repeat: no-repeat;
}
.layout-menu-underwater .layout-sidebar .sidebar-logo .sidebar-pin {
	background-color: #ffffff;
}
.layout-menu-underwater .layout-sidebar .sidebar-logo .sidebar-pin i {
	color: #2e3440;
}
.layout-menu-underwater .layout-sidebar .sidebar-logo .app-name {
	color: #ffffff;
}
.layout-menu-underwater .layout-sidebar .layout-menu li > a {
	cursor: pointer;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	outline: none;
}
.layout-menu-underwater .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
	color: #8FBCBB;
}
.layout-menu-underwater .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
	color: #8FBCBB;
}
.layout-menu-underwater .layout-sidebar .layout-menu > li > a .menuitem-text {
	color: #eceff4;
}
.layout-menu-underwater .layout-sidebar .layout-menu > li > a i {
	color: #eceff4;
}
.layout-menu-underwater .layout-sidebar .layout-menu > li > a:hover {
	background-color: rgba(255, 255, 255, 0.24);
}
.layout-menu-underwater .layout-sidebar .layout-menu > li.active-menuitem > a {
	background-color: #8FBCBB;
}
.layout-menu-underwater .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
	color: #3b4252;
}
.layout-menu-underwater .layout-sidebar .layout-menu > li.active-menuitem > a i {
	color: #2e3440;
}
.layout-menu-underwater .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
	background-color: #8FBCBB;
}
.layout-menu-underwater .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
	color: #3b4252;
}
.layout-menu-underwater .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
	color: #2e3440;
}
.layout-menu-underwater .layout-sidebar .layout-menu > li ul {
	background-color: rgba(255, 255, 255, 0.12);
}
.layout-menu-underwater .layout-sidebar .layout-menu > li ul li a .menuitem-text {
	color: #eceff4;
}
.layout-menu-underwater .layout-sidebar .layout-menu > li ul li a i {
	color: #eceff4;
}
.layout-menu-underwater .layout-sidebar .layout-menu > li ul li a:hover {
	background-color: rgba(0, 0, 0, 0.42);
}
.layout-menu-underwater .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
	color: #8FBCBB;
}
.layout-menu-underwater .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
	color: #8FBCBB;
}
@media (min-width: 1200px) {
	.layout-menu-underwater.layout-horizontal .layout-sidebar {
		background-color: #202631;
		background-image: url("../../images/menu/underwater-horizontal.jpg");
		background-size: auto 100%;
		background-repeat: no-repeat;
	}
	.layout-menu-underwater.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
		color: #8FBCBB;
	}
	.layout-menu-underwater.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
		color: #8FBCBB;
	}
	.layout-menu-underwater.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-underwater.layout-horizontal .layout-sidebar .layout-menu > li > a i {
		color: #eceff4;
	}
	.layout-menu-underwater.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
		background-color: rgba(255, 255, 255, 0.24);
	}
	.layout-menu-underwater.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
		background-color: #8FBCBB;
	}
	.layout-menu-underwater.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
		color: #3b4252;
	}
	.layout-menu-underwater.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
		color: #2e3440;
	}
	.layout-menu-underwater.layout-horizontal .layout-sidebar .layout-menu > li ul {
		background-color: #2e3440;
	}
	.layout-menu-underwater.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-underwater.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
		color: #eceff4;
	}
	.layout-menu-underwater.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
		background-color: rgba(0, 0, 0, 0.42);
	}
	.layout-menu-underwater.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
		color: #8FBCBB;
	}
	.layout-menu-underwater.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
		color: #8FBCBB;
	}
}

.layout-menu-symmetry .layout-sidebar {
	background-color: #545A65;
	background-image: url("../../images/menu/symmetry.jpg");
	background-size: 240px 100%;
	background-repeat: no-repeat;
}
.layout-menu-symmetry .layout-sidebar .sidebar-logo .sidebar-pin {
	background-color: #ffffff;
}
.layout-menu-symmetry .layout-sidebar .sidebar-logo .sidebar-pin i {
	color: #2e3440;
}
.layout-menu-symmetry .layout-sidebar .sidebar-logo .app-name {
	color: #ffffff;
}
.layout-menu-symmetry .layout-sidebar .layout-menu li > a {
	cursor: pointer;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	outline: none;
}
.layout-menu-symmetry .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
	color: #8FBCBB;
}
.layout-menu-symmetry .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
	color: #8FBCBB;
}
.layout-menu-symmetry .layout-sidebar .layout-menu > li > a .menuitem-text {
	color: #eceff4;
}
.layout-menu-symmetry .layout-sidebar .layout-menu > li > a i {
	color: #eceff4;
}
.layout-menu-symmetry .layout-sidebar .layout-menu > li > a:hover {
	background-color: rgba(255, 255, 255, 0.24);
}
.layout-menu-symmetry .layout-sidebar .layout-menu > li.active-menuitem > a {
	background-color: #8FBCBB;
}
.layout-menu-symmetry .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
	color: #3b4252;
}
.layout-menu-symmetry .layout-sidebar .layout-menu > li.active-menuitem > a i {
	color: #2e3440;
}
.layout-menu-symmetry .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
	background-color: #8FBCBB;
}
.layout-menu-symmetry .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
	color: #3b4252;
}
.layout-menu-symmetry .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
	color: #2e3440;
}
.layout-menu-symmetry .layout-sidebar .layout-menu > li ul {
	background-color: rgba(255, 255, 255, 0.12);
}
.layout-menu-symmetry .layout-sidebar .layout-menu > li ul li a .menuitem-text {
	color: #eceff4;
}
.layout-menu-symmetry .layout-sidebar .layout-menu > li ul li a i {
	color: #eceff4;
}
.layout-menu-symmetry .layout-sidebar .layout-menu > li ul li a:hover {
	background-color: rgba(0, 0, 0, 0.42);
}
.layout-menu-symmetry .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
	color: #8FBCBB;
}
.layout-menu-symmetry .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
	color: #8FBCBB;
}
@media (min-width: 1200px) {
	.layout-menu-symmetry.layout-horizontal .layout-sidebar {
		background-color: #545A65;
		background-image: url("../../images/menu/symmetry-horizontal.jpg");
		background-size: auto 100%;
		background-repeat: no-repeat;
	}
	.layout-menu-symmetry.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
		color: #8FBCBB;
	}
	.layout-menu-symmetry.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
		color: #8FBCBB;
	}
	.layout-menu-symmetry.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-symmetry.layout-horizontal .layout-sidebar .layout-menu > li > a i {
		color: #eceff4;
	}
	.layout-menu-symmetry.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
		background-color: rgba(255, 255, 255, 0.24);
	}
	.layout-menu-symmetry.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
		background-color: #8FBCBB;
	}
	.layout-menu-symmetry.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
		color: #3b4252;
	}
	.layout-menu-symmetry.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
		color: #2e3440;
	}
	.layout-menu-symmetry.layout-horizontal .layout-sidebar .layout-menu > li ul {
		background-color: #2e3440;
	}
	.layout-menu-symmetry.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-symmetry.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
		color: #eceff4;
	}
	.layout-menu-symmetry.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
		background-color: rgba(0, 0, 0, 0.42);
	}
	.layout-menu-symmetry.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
		color: #8FBCBB;
	}
	.layout-menu-symmetry.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
		color: #8FBCBB;
	}
}

.layout-menu-rain .layout-sidebar {
	background-color: #35414F;
	background-image: url("../../images/menu/rain.jpg");
	background-size: 240px 100%;
	background-repeat: no-repeat;
}
.layout-menu-rain .layout-sidebar .sidebar-logo .sidebar-pin {
	background-color: #ffffff;
}
.layout-menu-rain .layout-sidebar .sidebar-logo .sidebar-pin i {
	color: #2e3440;
}
.layout-menu-rain .layout-sidebar .sidebar-logo .app-name {
	color: #ffffff;
}
.layout-menu-rain .layout-sidebar .layout-menu li > a {
	cursor: pointer;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	outline: none;
}
.layout-menu-rain .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
	color: #D08770;
}
.layout-menu-rain .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
	color: #D08770;
}
.layout-menu-rain .layout-sidebar .layout-menu > li > a .menuitem-text {
	color: #eceff4;
}
.layout-menu-rain .layout-sidebar .layout-menu > li > a i {
	color: #eceff4;
}
.layout-menu-rain .layout-sidebar .layout-menu > li > a:hover {
	background-color: rgba(255, 255, 255, 0.24);
}
.layout-menu-rain .layout-sidebar .layout-menu > li.active-menuitem > a {
	background-color: #D08770;
}
.layout-menu-rain .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
	color: #3b4252;
}
.layout-menu-rain .layout-sidebar .layout-menu > li.active-menuitem > a i {
	color: #2e3440;
}
.layout-menu-rain .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
	background-color: #D08770;
}
.layout-menu-rain .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
	color: #3b4252;
}
.layout-menu-rain .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
	color: #2e3440;
}
.layout-menu-rain .layout-sidebar .layout-menu > li ul {
	background-color: rgba(255, 255, 255, 0.12);
}
.layout-menu-rain .layout-sidebar .layout-menu > li ul li a .menuitem-text {
	color: #eceff4;
}
.layout-menu-rain .layout-sidebar .layout-menu > li ul li a i {
	color: #eceff4;
}
.layout-menu-rain .layout-sidebar .layout-menu > li ul li a:hover {
	background-color: rgba(0, 0, 0, 0.36);
}
.layout-menu-rain .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
	color: #D08770;
}
.layout-menu-rain .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
	color: #D08770;
}
@media (min-width: 1200px) {
	.layout-menu-rain.layout-horizontal .layout-sidebar {
		background-color: #35414F;
		background-image: url("../../images/menu/rain-horizontal.jpg");
		background-size: auto 100%;
		background-repeat: no-repeat;
	}
	.layout-menu-rain.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
		color: #D08770;
	}
	.layout-menu-rain.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
		color: #D08770;
	}
	.layout-menu-rain.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-rain.layout-horizontal .layout-sidebar .layout-menu > li > a i {
		color: #eceff4;
	}
	.layout-menu-rain.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
		background-color: rgba(255, 255, 255, 0.24);
	}
	.layout-menu-rain.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
		background-color: #D08770;
	}
	.layout-menu-rain.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
		color: #3b4252;
	}
	.layout-menu-rain.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
		color: #2e3440;
	}
	.layout-menu-rain.layout-horizontal .layout-sidebar .layout-menu > li ul {
		background-color: #2e3440;
	}
	.layout-menu-rain.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-rain.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
		color: #eceff4;
	}
	.layout-menu-rain.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
		background-color: rgba(0, 0, 0, 0.36);
	}
	.layout-menu-rain.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
		color: #D08770;
	}
	.layout-menu-rain.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
		color: #D08770;
	}
}

.layout-menu-utah .layout-sidebar {
	background-color: #3D2E34;
	background-image: url("../../images/menu/utah.jpg");
	background-size: 240px 100%;
	background-repeat: no-repeat;
}
.layout-menu-utah .layout-sidebar .sidebar-logo .sidebar-pin {
	background-color: #ffffff;
}
.layout-menu-utah .layout-sidebar .sidebar-logo .sidebar-pin i {
	color: #2e3440;
}
.layout-menu-utah .layout-sidebar .sidebar-logo .app-name {
	color: #ffffff;
}
.layout-menu-utah .layout-sidebar .layout-menu li > a {
	cursor: pointer;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	outline: none;
}
.layout-menu-utah .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
	color: #EBCB8B;
}
.layout-menu-utah .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
	color: #EBCB8B;
}
.layout-menu-utah .layout-sidebar .layout-menu > li > a .menuitem-text {
	color: #eceff4;
}
.layout-menu-utah .layout-sidebar .layout-menu > li > a i {
	color: #eceff4;
}
.layout-menu-utah .layout-sidebar .layout-menu > li > a:hover {
	background-color: rgba(255, 255, 255, 0.24);
}
.layout-menu-utah .layout-sidebar .layout-menu > li.active-menuitem > a {
	background-color: #EBCB8B;
}
.layout-menu-utah .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
	color: #3b4252;
}
.layout-menu-utah .layout-sidebar .layout-menu > li.active-menuitem > a i {
	color: #2e3440;
}
.layout-menu-utah .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
	background-color: #EBCB8B;
}
.layout-menu-utah .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
	color: #3b4252;
}
.layout-menu-utah .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
	color: #2e3440;
}
.layout-menu-utah .layout-sidebar .layout-menu > li ul {
	background-color: rgba(255, 255, 255, 0.12);
}
.layout-menu-utah .layout-sidebar .layout-menu > li ul li a .menuitem-text {
	color: #eceff4;
}
.layout-menu-utah .layout-sidebar .layout-menu > li ul li a i {
	color: #eceff4;
}
.layout-menu-utah .layout-sidebar .layout-menu > li ul li a:hover {
	background-color: rgba(0, 0, 0, 0.36);
}
.layout-menu-utah .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
	color: #EBCB8B;
}
.layout-menu-utah .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
	color: #EBCB8B;
}
@media (min-width: 1200px) {
	.layout-menu-utah.layout-horizontal .layout-sidebar {
		background-color: #3D2E34;
		background-image: url("../../images/menu/utah-horizontal.jpg");
		background-size: auto 100%;
		background-repeat: no-repeat;
	}
	.layout-menu-utah.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
		color: #EBCB8B;
	}
	.layout-menu-utah.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
		color: #EBCB8B;
	}
	.layout-menu-utah.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-utah.layout-horizontal .layout-sidebar .layout-menu > li > a i {
		color: #eceff4;
	}
	.layout-menu-utah.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
		background-color: rgba(255, 255, 255, 0.24);
	}
	.layout-menu-utah.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
		background-color: #EBCB8B;
	}
	.layout-menu-utah.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
		color: #3b4252;
	}
	.layout-menu-utah.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
		color: #2e3440;
	}
	.layout-menu-utah.layout-horizontal .layout-sidebar .layout-menu > li ul {
		background-color: #2e3440;
	}
	.layout-menu-utah.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-utah.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
		color: #eceff4;
	}
	.layout-menu-utah.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
		background-color: rgba(0, 0, 0, 0.36);
	}
	.layout-menu-utah.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
		color: #EBCB8B;
	}
	.layout-menu-utah.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
		color: #EBCB8B;
	}
}

.layout-menu-wave .layout-sidebar {
	background-color: #1A2B39;
	background-image: url("../../images/menu/wave.jpg");
	background-size: 240px 100%;
	background-repeat: no-repeat;
}
.layout-menu-wave .layout-sidebar .sidebar-logo .sidebar-pin {
	background-color: #ffffff;
}
.layout-menu-wave .layout-sidebar .sidebar-logo .sidebar-pin i {
	color: #2e3440;
}
.layout-menu-wave .layout-sidebar .sidebar-logo .app-name {
	color: #ffffff;
}
.layout-menu-wave .layout-sidebar .layout-menu li > a {
	cursor: pointer;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	outline: none;
}
.layout-menu-wave .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
	color: #81A1C1;
}
.layout-menu-wave .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
	color: #81A1C1;
}
.layout-menu-wave .layout-sidebar .layout-menu > li > a .menuitem-text {
	color: #eceff4;
}
.layout-menu-wave .layout-sidebar .layout-menu > li > a i {
	color: #eceff4;
}
.layout-menu-wave .layout-sidebar .layout-menu > li > a:hover {
	background-color: rgba(255, 255, 255, 0.24);
}
.layout-menu-wave .layout-sidebar .layout-menu > li.active-menuitem > a {
	background-color: #81A1C1;
}
.layout-menu-wave .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
	color: #3b4252;
}
.layout-menu-wave .layout-sidebar .layout-menu > li.active-menuitem > a i {
	color: #2e3440;
}
.layout-menu-wave .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
	background-color: #81A1C1;
}
.layout-menu-wave .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
	color: #3b4252;
}
.layout-menu-wave .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
	color: #2e3440;
}
.layout-menu-wave .layout-sidebar .layout-menu > li ul {
	background-color: rgba(255, 255, 255, 0.12);
}
.layout-menu-wave .layout-sidebar .layout-menu > li ul li a .menuitem-text {
	color: #eceff4;
}
.layout-menu-wave .layout-sidebar .layout-menu > li ul li a i {
	color: #eceff4;
}
.layout-menu-wave .layout-sidebar .layout-menu > li ul li a:hover {
	background-color: rgba(0, 0, 0, 0.36);
}
.layout-menu-wave .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
	color: #81A1C1;
}
.layout-menu-wave .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
	color: #81A1C1;
}
@media (min-width: 1200px) {
	.layout-menu-wave.layout-horizontal .layout-sidebar {
		background-color: #1A2B39;
		background-image: url("../../images/menu/wave-horizontal.jpg");
		background-size: auto 100%;
		background-repeat: no-repeat;
	}
	.layout-menu-wave.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
		color: #81A1C1;
	}
	.layout-menu-wave.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
		color: #81A1C1;
	}
	.layout-menu-wave.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-wave.layout-horizontal .layout-sidebar .layout-menu > li > a i {
		color: #eceff4;
	}
	.layout-menu-wave.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
		background-color: rgba(255, 255, 255, 0.24);
	}
	.layout-menu-wave.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
		background-color: #81A1C1;
	}
	.layout-menu-wave.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
		color: #3b4252;
	}
	.layout-menu-wave.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
		color: #2e3440;
	}
	.layout-menu-wave.layout-horizontal .layout-sidebar .layout-menu > li ul {
		background-color: #2e3440;
	}
	.layout-menu-wave.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-wave.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
		color: #eceff4;
	}
	.layout-menu-wave.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
		background-color: rgba(0, 0, 0, 0.36);
	}
	.layout-menu-wave.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
		color: #81A1C1;
	}
	.layout-menu-wave.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
		color: #81A1C1;
	}
}

.layout-menu-flora .layout-sidebar {
	background-color: #202422;
	background-image: url("../../images/menu/flora.jpg");
	background-size: 240px 100%;
	background-repeat: no-repeat;
}
.layout-menu-flora .layout-sidebar .sidebar-logo .sidebar-pin {
	background-color: #ffffff;
}
.layout-menu-flora .layout-sidebar .sidebar-logo .sidebar-pin i {
	color: #2e3440;
}
.layout-menu-flora .layout-sidebar .sidebar-logo .app-name {
	color: #ffffff;
}
.layout-menu-flora .layout-sidebar .layout-menu li > a {
	cursor: pointer;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	outline: none;
}
.layout-menu-flora .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
	color: #EBCB8B;
}
.layout-menu-flora .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
	color: #EBCB8B;
}
.layout-menu-flora .layout-sidebar .layout-menu > li > a .menuitem-text {
	color: #eceff4;
}
.layout-menu-flora .layout-sidebar .layout-menu > li > a i {
	color: #eceff4;
}
.layout-menu-flora .layout-sidebar .layout-menu > li > a:hover {
	background-color: rgba(255, 255, 255, 0.24);
}
.layout-menu-flora .layout-sidebar .layout-menu > li.active-menuitem > a {
	background-color: #EBCB8B;
}
.layout-menu-flora .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
	color: #3b4252;
}
.layout-menu-flora .layout-sidebar .layout-menu > li.active-menuitem > a i {
	color: #2e3440;
}
.layout-menu-flora .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
	background-color: #EBCB8B;
}
.layout-menu-flora .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
	color: #3b4252;
}
.layout-menu-flora .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
	color: #2e3440;
}
.layout-menu-flora .layout-sidebar .layout-menu > li ul {
	background-color: rgba(255, 255, 255, 0.12);
}
.layout-menu-flora .layout-sidebar .layout-menu > li ul li a .menuitem-text {
	color: #eceff4;
}
.layout-menu-flora .layout-sidebar .layout-menu > li ul li a i {
	color: #eceff4;
}
.layout-menu-flora .layout-sidebar .layout-menu > li ul li a:hover {
	background-color: rgba(0, 0, 0, 0.42);
}
.layout-menu-flora .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
	color: #EBCB8B;
}
.layout-menu-flora .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
	color: #EBCB8B;
}
@media (min-width: 1200px) {
	.layout-menu-flora.layout-horizontal .layout-sidebar {
		background-color: #202422;
		background-image: url("../../images/menu/flora-horizontal.jpg");
		background-size: auto 100%;
		background-repeat: no-repeat;
	}
	.layout-menu-flora.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
		color: #EBCB8B;
	}
	.layout-menu-flora.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
		color: #EBCB8B;
	}
	.layout-menu-flora.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-flora.layout-horizontal .layout-sidebar .layout-menu > li > a i {
		color: #eceff4;
	}
	.layout-menu-flora.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
		background-color: rgba(255, 255, 255, 0.24);
	}
	.layout-menu-flora.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
		background-color: #EBCB8B;
	}
	.layout-menu-flora.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
		color: #3b4252;
	}
	.layout-menu-flora.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
		color: #2e3440;
	}
	.layout-menu-flora.layout-horizontal .layout-sidebar .layout-menu > li ul {
		background-color: #2e3440;
	}
	.layout-menu-flora.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-flora.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
		color: #eceff4;
	}
	.layout-menu-flora.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
		background-color: rgba(0, 0, 0, 0.42);
	}
	.layout-menu-flora.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
		color: #EBCB8B;
	}
	.layout-menu-flora.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
		color: #EBCB8B;
	}
}

.layout-menu-speed .layout-sidebar {
	background-color: #272634;
	background-image: url("../../images/menu/speed.jpg");
	background-size: 240px 100%;
	background-repeat: no-repeat;
}
.layout-menu-speed .layout-sidebar .sidebar-logo .sidebar-pin {
	background-color: #ffffff;
}
.layout-menu-speed .layout-sidebar .sidebar-logo .sidebar-pin i {
	color: #2e3440;
}
.layout-menu-speed .layout-sidebar .sidebar-logo .app-name {
	color: #ffffff;
}
.layout-menu-speed .layout-sidebar .layout-menu li > a {
	cursor: pointer;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	outline: none;
}
.layout-menu-speed .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
	color: #EBCB8B;
}
.layout-menu-speed .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
	color: #EBCB8B;
}
.layout-menu-speed .layout-sidebar .layout-menu > li > a .menuitem-text {
	color: #eceff4;
}
.layout-menu-speed .layout-sidebar .layout-menu > li > a i {
	color: #eceff4;
}
.layout-menu-speed .layout-sidebar .layout-menu > li > a:hover {
	background-color: rgba(255, 255, 255, 0.24);
}
.layout-menu-speed .layout-sidebar .layout-menu > li.active-menuitem > a {
	background-color: #EBCB8B;
}
.layout-menu-speed .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
	color: #3b4252;
}
.layout-menu-speed .layout-sidebar .layout-menu > li.active-menuitem > a i {
	color: #2e3440;
}
.layout-menu-speed .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
	background-color: #EBCB8B;
}
.layout-menu-speed .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
	color: #3b4252;
}
.layout-menu-speed .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
	color: #2e3440;
}
.layout-menu-speed .layout-sidebar .layout-menu > li ul {
	background-color: rgba(255, 255, 255, 0.12);
}
.layout-menu-speed .layout-sidebar .layout-menu > li ul li a .menuitem-text {
	color: #eceff4;
}
.layout-menu-speed .layout-sidebar .layout-menu > li ul li a i {
	color: #eceff4;
}
.layout-menu-speed .layout-sidebar .layout-menu > li ul li a:hover {
	background-color: rgba(0, 0, 0, 0.32);
}
.layout-menu-speed .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
	color: #EBCB8B;
}
.layout-menu-speed .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
	color: #EBCB8B;
}
@media (min-width: 1200px) {
	.layout-menu-speed.layout-horizontal .layout-sidebar {
		background-color: #272634;
		background-image: url("../../images/menu/speed-horizontal.jpg");
		background-size: auto 100%;
		background-repeat: no-repeat;
	}
	.layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
		color: #EBCB8B;
	}
	.layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
		color: #EBCB8B;
	}
	.layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu > li > a i {
		color: #eceff4;
	}
	.layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
		background-color: rgba(255, 255, 255, 0.24);
	}
	.layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
		background-color: #EBCB8B;
	}
	.layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
		color: #3b4252;
	}
	.layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
		color: #2e3440;
	}
	.layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu > li ul {
		background-color: #2e3440;
	}
	.layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
		color: #eceff4;
	}
	.layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
		background-color: rgba(0, 0, 0, 0.32);
	}
	.layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
		color: #EBCB8B;
	}
	.layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
		color: #EBCB8B;
	}
}

.layout-menu-speed .layout-sidebar {
	background-color: #272634;
	background-image: url("../../images/menu/speed.jpg");
	background-size: 240px 100%;
	background-repeat: no-repeat;
}
.layout-menu-speed .layout-sidebar .sidebar-logo .sidebar-pin {
	background-color: #ffffff;
}
.layout-menu-speed .layout-sidebar .sidebar-logo .sidebar-pin i {
	color: #2e3440;
}
.layout-menu-speed .layout-sidebar .sidebar-logo .app-name {
	color: #ffffff;
}
.layout-menu-speed .layout-sidebar .layout-menu li > a {
	cursor: pointer;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	outline: none;
}
.layout-menu-speed .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
	color: #EBCB8B;
}
.layout-menu-speed .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
	color: #EBCB8B;
}
.layout-menu-speed .layout-sidebar .layout-menu > li > a .menuitem-text {
	color: #eceff4;
}
.layout-menu-speed .layout-sidebar .layout-menu > li > a i {
	color: #eceff4;
}
.layout-menu-speed .layout-sidebar .layout-menu > li > a:hover {
	background-color: rgba(255, 255, 255, 0.24);
}
.layout-menu-speed .layout-sidebar .layout-menu > li.active-menuitem > a {
	background-color: #EBCB8B;
}
.layout-menu-speed .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
	color: #3b4252;
}
.layout-menu-speed .layout-sidebar .layout-menu > li.active-menuitem > a i {
	color: #2e3440;
}
.layout-menu-speed .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
	background-color: #EBCB8B;
}
.layout-menu-speed .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
	color: #3b4252;
}
.layout-menu-speed .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
	color: #2e3440;
}
.layout-menu-speed .layout-sidebar .layout-menu > li ul {
	background-color: rgba(255, 255, 255, 0.12);
}
.layout-menu-speed .layout-sidebar .layout-menu > li ul li a .menuitem-text {
	color: #eceff4;
}
.layout-menu-speed .layout-sidebar .layout-menu > li ul li a i {
	color: #eceff4;
}
.layout-menu-speed .layout-sidebar .layout-menu > li ul li a:hover {
	background-color: rgba(0, 0, 0, 0.32);
}
.layout-menu-speed .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
	color: #EBCB8B;
}
.layout-menu-speed .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
	color: #EBCB8B;
}
@media (min-width: 1200px) {
	.layout-menu-speed.layout-horizontal .layout-sidebar {
		background-color: #272634;
		background-image: url("../../images/menu/speed-horizontal.jpg");
		background-size: auto 100%;
		background-repeat: no-repeat;
	}
	.layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
		color: #EBCB8B;
	}
	.layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
		color: #EBCB8B;
	}
	.layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu > li > a i {
		color: #eceff4;
	}
	.layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
		background-color: rgba(255, 255, 255, 0.24);
	}
	.layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
		background-color: #EBCB8B;
	}
	.layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
		color: #3b4252;
	}
	.layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
		color: #2e3440;
	}
	.layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu > li ul {
		background-color: #2e3440;
	}
	.layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
		color: #eceff4;
	}
	.layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
		background-color: rgba(0, 0, 0, 0.32);
	}
	.layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
		color: #EBCB8B;
	}
	.layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
		color: #EBCB8B;
	}
}

.layout-menu-canopy .layout-sidebar {
	background-color: #22272F;
	background-image: url("../../images/menu/canopy.jpg");
	background-size: 240px 100%;
	background-repeat: no-repeat;
}
.layout-menu-canopy .layout-sidebar .sidebar-logo .sidebar-pin {
	background-color: #ffffff;
}
.layout-menu-canopy .layout-sidebar .sidebar-logo .sidebar-pin i {
	color: #2e3440;
}
.layout-menu-canopy .layout-sidebar .sidebar-logo .app-name {
	color: #ffffff;
}
.layout-menu-canopy .layout-sidebar .layout-menu li > a {
	cursor: pointer;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	outline: none;
}
.layout-menu-canopy .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
	color: #A3BE8C;
}
.layout-menu-canopy .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
	color: #A3BE8C;
}
.layout-menu-canopy .layout-sidebar .layout-menu > li > a .menuitem-text {
	color: #eceff4;
}
.layout-menu-canopy .layout-sidebar .layout-menu > li > a i {
	color: #eceff4;
}
.layout-menu-canopy .layout-sidebar .layout-menu > li > a:hover {
	background-color: rgba(255, 255, 255, 0.24);
}
.layout-menu-canopy .layout-sidebar .layout-menu > li.active-menuitem > a {
	background-color: #A3BE8C;
}
.layout-menu-canopy .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
	color: #3b4252;
}
.layout-menu-canopy .layout-sidebar .layout-menu > li.active-menuitem > a i {
	color: #2e3440;
}
.layout-menu-canopy .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
	background-color: #A3BE8C;
}
.layout-menu-canopy .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
	color: #3b4252;
}
.layout-menu-canopy .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
	color: #2e3440;
}
.layout-menu-canopy .layout-sidebar .layout-menu > li ul {
	background-color: rgba(255, 255, 255, 0.12);
}
.layout-menu-canopy .layout-sidebar .layout-menu > li ul li a .menuitem-text {
	color: #eceff4;
}
.layout-menu-canopy .layout-sidebar .layout-menu > li ul li a i {
	color: #eceff4;
}
.layout-menu-canopy .layout-sidebar .layout-menu > li ul li a:hover {
	background-color: rgba(0, 0, 0, 0.36);
}
.layout-menu-canopy .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
	color: #A3BE8C;
}
.layout-menu-canopy .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
	color: #A3BE8C;
}
@media (min-width: 1200px) {
	.layout-menu-canopy.layout-horizontal .layout-sidebar {
		background-color: #22272F;
		background-image: url("../../images/menu/canopy-horizontal.jpg");
		background-size: auto 100%;
		background-repeat: no-repeat;
	}
	.layout-menu-canopy.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
		color: #A3BE8C;
	}
	.layout-menu-canopy.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
		color: #A3BE8C;
	}
	.layout-menu-canopy.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-canopy.layout-horizontal .layout-sidebar .layout-menu > li > a i {
		color: #eceff4;
	}
	.layout-menu-canopy.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
		background-color: rgba(255, 255, 255, 0.24);
	}
	.layout-menu-canopy.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
		background-color: #A3BE8C;
	}
	.layout-menu-canopy.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
		color: #3b4252;
	}
	.layout-menu-canopy.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
		color: #2e3440;
	}
	.layout-menu-canopy.layout-horizontal .layout-sidebar .layout-menu > li ul {
		background-color: #2e3440;
	}
	.layout-menu-canopy.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-canopy.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
		color: #eceff4;
	}
	.layout-menu-canopy.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
		background-color: rgba(0, 0, 0, 0.36);
	}
	.layout-menu-canopy.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
		color: #A3BE8C;
	}
	.layout-menu-canopy.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
		color: #A3BE8C;
	}
}

.layout-menu-sanpaolo .layout-sidebar {
	background-color: #252A32;
	background-image: url("../../images/menu/sanpaolo.jpg");
	background-size: 240px 100%;
	background-repeat: no-repeat;
}
.layout-menu-sanpaolo .layout-sidebar .sidebar-logo .sidebar-pin {
	background-color: #ffffff;
}
.layout-menu-sanpaolo .layout-sidebar .sidebar-logo .sidebar-pin i {
	color: #2e3440;
}
.layout-menu-sanpaolo .layout-sidebar .sidebar-logo .app-name {
	color: #ffffff;
}
.layout-menu-sanpaolo .layout-sidebar .layout-menu li > a {
	cursor: pointer;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	outline: none;
}
.layout-menu-sanpaolo .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
	color: #8FBCBB;
}
.layout-menu-sanpaolo .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
	color: #8FBCBB;
}
.layout-menu-sanpaolo .layout-sidebar .layout-menu > li > a .menuitem-text {
	color: #eceff4;
}
.layout-menu-sanpaolo .layout-sidebar .layout-menu > li > a i {
	color: #eceff4;
}
.layout-menu-sanpaolo .layout-sidebar .layout-menu > li > a:hover {
	background-color: rgba(255, 255, 255, 0.24);
}
.layout-menu-sanpaolo .layout-sidebar .layout-menu > li.active-menuitem > a {
	background-color: #8FBCBB;
}
.layout-menu-sanpaolo .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
	color: #3b4252;
}
.layout-menu-sanpaolo .layout-sidebar .layout-menu > li.active-menuitem > a i {
	color: #2e3440;
}
.layout-menu-sanpaolo .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
	background-color: #8FBCBB;
}
.layout-menu-sanpaolo .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
	color: #3b4252;
}
.layout-menu-sanpaolo .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
	color: #2e3440;
}
.layout-menu-sanpaolo .layout-sidebar .layout-menu > li ul {
	background-color: rgba(255, 255, 255, 0.12);
}
.layout-menu-sanpaolo .layout-sidebar .layout-menu > li ul li a .menuitem-text {
	color: #eceff4;
}
.layout-menu-sanpaolo .layout-sidebar .layout-menu > li ul li a i {
	color: #eceff4;
}
.layout-menu-sanpaolo .layout-sidebar .layout-menu > li ul li a:hover {
	background-color: rgba(0, 0, 0, 0.32);
}
.layout-menu-sanpaolo .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
	color: #8FBCBB;
}
.layout-menu-sanpaolo .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
	color: #8FBCBB;
}
@media (min-width: 1200px) {
	.layout-menu-sanpaolo.layout-horizontal .layout-sidebar {
		background-color: #252A32;
		background-image: url("../../images/menu/sanpaolo-horizontal.jpg");
		background-size: auto 100%;
		background-repeat: no-repeat;
	}
	.layout-menu-sanpaolo.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
		color: #8FBCBB;
	}
	.layout-menu-sanpaolo.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
		color: #8FBCBB;
	}
	.layout-menu-sanpaolo.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-sanpaolo.layout-horizontal .layout-sidebar .layout-menu > li > a i {
		color: #eceff4;
	}
	.layout-menu-sanpaolo.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
		background-color: rgba(255, 255, 255, 0.24);
	}
	.layout-menu-sanpaolo.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
		background-color: #8FBCBB;
	}
	.layout-menu-sanpaolo.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
		color: #3b4252;
	}
	.layout-menu-sanpaolo.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
		color: #2e3440;
	}
	.layout-menu-sanpaolo.layout-horizontal .layout-sidebar .layout-menu > li ul {
		background-color: #2e3440;
	}
	.layout-menu-sanpaolo.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-sanpaolo.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
		color: #eceff4;
	}
	.layout-menu-sanpaolo.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
		background-color: rgba(0, 0, 0, 0.32);
	}
	.layout-menu-sanpaolo.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
		color: #8FBCBB;
	}
	.layout-menu-sanpaolo.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
		color: #8FBCBB;
	}
}

.layout-menu-basketball .layout-sidebar {
	background-color: #293531;
	background-image: url("../../images/menu/basketball.jpg");
	background-size: 240px 100%;
	background-repeat: no-repeat;
}
.layout-menu-basketball .layout-sidebar .sidebar-logo .sidebar-pin {
	background-color: #ffffff;
}
.layout-menu-basketball .layout-sidebar .sidebar-logo .sidebar-pin i {
	color: #2e3440;
}
.layout-menu-basketball .layout-sidebar .sidebar-logo .app-name {
	color: #ffffff;
}
.layout-menu-basketball .layout-sidebar .layout-menu li > a {
	cursor: pointer;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	outline: none;
}
.layout-menu-basketball .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
	color: #D08770;
}
.layout-menu-basketball .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
	color: #D08770;
}
.layout-menu-basketball .layout-sidebar .layout-menu > li > a .menuitem-text {
	color: #eceff4;
}
.layout-menu-basketball .layout-sidebar .layout-menu > li > a i {
	color: #eceff4;
}
.layout-menu-basketball .layout-sidebar .layout-menu > li > a:hover {
	background-color: rgba(255, 255, 255, 0.24);
}
.layout-menu-basketball .layout-sidebar .layout-menu > li.active-menuitem > a {
	background-color: #D08770;
}
.layout-menu-basketball .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
	color: #3b4252;
}
.layout-menu-basketball .layout-sidebar .layout-menu > li.active-menuitem > a i {
	color: #2e3440;
}
.layout-menu-basketball .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
	background-color: #D08770;
}
.layout-menu-basketball .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
	color: #3b4252;
}
.layout-menu-basketball .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
	color: #2e3440;
}
.layout-menu-basketball .layout-sidebar .layout-menu > li ul {
	background-color: rgba(255, 255, 255, 0.12);
}
.layout-menu-basketball .layout-sidebar .layout-menu > li ul li a .menuitem-text {
	color: #eceff4;
}
.layout-menu-basketball .layout-sidebar .layout-menu > li ul li a i {
	color: #eceff4;
}
.layout-menu-basketball .layout-sidebar .layout-menu > li ul li a:hover {
	background-color: rgba(0, 0, 0, 0.42);
}
.layout-menu-basketball .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
	color: #D08770;
}
.layout-menu-basketball .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
	color: #D08770;
}
@media (min-width: 1200px) {
	.layout-menu-basketball.layout-horizontal .layout-sidebar {
		background-color: #293531;
		background-image: url("../../images/menu/basketball-horizontal.jpg");
		background-size: auto 100%;
		background-repeat: no-repeat;
	}
	.layout-menu-basketball.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
		color: #D08770;
	}
	.layout-menu-basketball.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
		color: #D08770;
	}
	.layout-menu-basketball.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-basketball.layout-horizontal .layout-sidebar .layout-menu > li > a i {
		color: #eceff4;
	}
	.layout-menu-basketball.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
		background-color: rgba(255, 255, 255, 0.24);
	}
	.layout-menu-basketball.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
		background-color: #D08770;
	}
	.layout-menu-basketball.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
		color: #3b4252;
	}
	.layout-menu-basketball.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
		color: #2e3440;
	}
	.layout-menu-basketball.layout-horizontal .layout-sidebar .layout-menu > li ul {
		background-color: #2e3440;
	}
	.layout-menu-basketball.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-basketball.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
		color: #eceff4;
	}
	.layout-menu-basketball.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
		background-color: rgba(0, 0, 0, 0.42);
	}
	.layout-menu-basketball.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
		color: #D08770;
	}
	.layout-menu-basketball.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
		color: #D08770;
	}
}

.layout-menu-misty .layout-sidebar {
	background-color: #202734;
	background-image: url("../../images/menu/misty.jpg");
	background-size: 240px 100%;
	background-repeat: no-repeat;
}
.layout-menu-misty .layout-sidebar .sidebar-logo .sidebar-pin {
	background-color: #ffffff;
}
.layout-menu-misty .layout-sidebar .sidebar-logo .sidebar-pin i {
	color: #2e3440;
}
.layout-menu-misty .layout-sidebar .sidebar-logo .app-name {
	color: #ffffff;
}
.layout-menu-misty .layout-sidebar .layout-menu li > a {
	cursor: pointer;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	outline: none;
}
.layout-menu-misty .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
	color: #D08770;
}
.layout-menu-misty .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
	color: #D08770;
}
.layout-menu-misty .layout-sidebar .layout-menu > li > a .menuitem-text {
	color: #eceff4;
}
.layout-menu-misty .layout-sidebar .layout-menu > li > a i {
	color: #eceff4;
}
.layout-menu-misty .layout-sidebar .layout-menu > li > a:hover {
	background-color: rgba(255, 255, 255, 0.24);
}
.layout-menu-misty .layout-sidebar .layout-menu > li.active-menuitem > a {
	background-color: #D08770;
}
.layout-menu-misty .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
	color: #3b4252;
}
.layout-menu-misty .layout-sidebar .layout-menu > li.active-menuitem > a i {
	color: #2e3440;
}
.layout-menu-misty .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
	background-color: #D08770;
}
.layout-menu-misty .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
	color: #3b4252;
}
.layout-menu-misty .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
	color: #2e3440;
}
.layout-menu-misty .layout-sidebar .layout-menu > li ul {
	background-color: rgba(255, 255, 255, 0.12);
}
.layout-menu-misty .layout-sidebar .layout-menu > li ul li a .menuitem-text {
	color: #eceff4;
}
.layout-menu-misty .layout-sidebar .layout-menu > li ul li a i {
	color: #eceff4;
}
.layout-menu-misty .layout-sidebar .layout-menu > li ul li a:hover {
	background-color: rgba(0, 0, 0, 0.36);
}
.layout-menu-misty .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
	color: #D08770;
}
.layout-menu-misty .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
	color: #D08770;
}
@media (min-width: 1200px) {
	.layout-menu-misty.layout-horizontal .layout-sidebar {
		background-color: #202734;
		background-image: url("../../images/menu/misty-horizontal.jpg");
		background-size: auto 100%;
		background-repeat: no-repeat;
	}
	.layout-menu-misty.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
		color: #D08770;
	}
	.layout-menu-misty.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
		color: #D08770;
	}
	.layout-menu-misty.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-misty.layout-horizontal .layout-sidebar .layout-menu > li > a i {
		color: #eceff4;
	}
	.layout-menu-misty.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
		background-color: rgba(255, 255, 255, 0.24);
	}
	.layout-menu-misty.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
		background-color: #D08770;
	}
	.layout-menu-misty.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
		color: #3b4252;
	}
	.layout-menu-misty.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
		color: #2e3440;
	}
	.layout-menu-misty.layout-horizontal .layout-sidebar .layout-menu > li ul {
		background-color: #2e3440;
	}
	.layout-menu-misty.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-misty.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
		color: #eceff4;
	}
	.layout-menu-misty.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
		background-color: rgba(0, 0, 0, 0.36);
	}
	.layout-menu-misty.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
		color: #D08770;
	}
	.layout-menu-misty.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
		color: #D08770;
	}
}

.layout-menu-summit .layout-sidebar {
	background-color: #212733;
	background-image: url("../../images/menu/summit.jpg");
	background-size: 240px 100%;
	background-repeat: no-repeat;
}
.layout-menu-summit .layout-sidebar .sidebar-logo .sidebar-pin {
	background-color: #ffffff;
}
.layout-menu-summit .layout-sidebar .sidebar-logo .sidebar-pin i {
	color: #2e3440;
}
.layout-menu-summit .layout-sidebar .sidebar-logo .app-name {
	color: #ffffff;
}
.layout-menu-summit .layout-sidebar .layout-menu li > a {
	cursor: pointer;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	outline: none;
}
.layout-menu-summit .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
	color: #88C0D0;
}
.layout-menu-summit .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
	color: #88C0D0;
}
.layout-menu-summit .layout-sidebar .layout-menu > li > a .menuitem-text {
	color: #eceff4;
}
.layout-menu-summit .layout-sidebar .layout-menu > li > a i {
	color: #eceff4;
}
.layout-menu-summit .layout-sidebar .layout-menu > li > a:hover {
	background-color: rgba(255, 255, 255, 0.24);
}
.layout-menu-summit .layout-sidebar .layout-menu > li.active-menuitem > a {
	background-color: #88C0D0;
}
.layout-menu-summit .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
	color: #3b4252;
}
.layout-menu-summit .layout-sidebar .layout-menu > li.active-menuitem > a i {
	color: #2e3440;
}
.layout-menu-summit .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
	background-color: #88C0D0;
}
.layout-menu-summit .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
	color: #3b4252;
}
.layout-menu-summit .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
	color: #2e3440;
}
.layout-menu-summit .layout-sidebar .layout-menu > li ul {
	background-color: rgba(255, 255, 255, 0.12);
}
.layout-menu-summit .layout-sidebar .layout-menu > li ul li a .menuitem-text {
	color: #eceff4;
}
.layout-menu-summit .layout-sidebar .layout-menu > li ul li a i {
	color: #eceff4;
}
.layout-menu-summit .layout-sidebar .layout-menu > li ul li a:hover {
	background-color: rgba(0, 0, 0, 0.36);
}
.layout-menu-summit .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
	color: #88C0D0;
}
.layout-menu-summit .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
	color: #88C0D0;
}
@media (min-width: 1200px) {
	.layout-menu-summit.layout-horizontal .layout-sidebar {
		background-color: #212733;
		background-image: url("../../images/menu/summit-horizontal.jpg");
		background-size: auto 100%;
		background-repeat: no-repeat;
	}
	.layout-menu-summit.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
		color: #88C0D0;
	}
	.layout-menu-summit.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
		color: #88C0D0;
	}
	.layout-menu-summit.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-summit.layout-horizontal .layout-sidebar .layout-menu > li > a i {
		color: #eceff4;
	}
	.layout-menu-summit.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
		background-color: rgba(255, 255, 255, 0.24);
	}
	.layout-menu-summit.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
		background-color: #88C0D0;
	}
	.layout-menu-summit.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
		color: #3b4252;
	}
	.layout-menu-summit.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
		color: #2e3440;
	}
	.layout-menu-summit.layout-horizontal .layout-sidebar .layout-menu > li ul {
		background-color: #2e3440;
	}
	.layout-menu-summit.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-summit.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
		color: #eceff4;
	}
	.layout-menu-summit.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
		background-color: rgba(0, 0, 0, 0.36);
	}
	.layout-menu-summit.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
		color: #88C0D0;
	}
	.layout-menu-summit.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
		color: #88C0D0;
	}
}

.layout-menu-snow .layout-sidebar {
	background-color: #414A55;
	background-image: url("../../images/menu/snow.jpg");
	background-size: 240px 100%;
	background-repeat: no-repeat;
}
.layout-menu-snow .layout-sidebar .sidebar-logo .sidebar-pin {
	background-color: #ffffff;
}
.layout-menu-snow .layout-sidebar .sidebar-logo .sidebar-pin i {
	color: #2e3440;
}
.layout-menu-snow .layout-sidebar .sidebar-logo .app-name {
	color: #ffffff;
}
.layout-menu-snow .layout-sidebar .layout-menu li > a {
	cursor: pointer;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	outline: none;
}
.layout-menu-snow .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
	color: #81A1C1;
}
.layout-menu-snow .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
	color: #81A1C1;
}
.layout-menu-snow .layout-sidebar .layout-menu > li > a .menuitem-text {
	color: #eceff4;
}
.layout-menu-snow .layout-sidebar .layout-menu > li > a i {
	color: #eceff4;
}
.layout-menu-snow .layout-sidebar .layout-menu > li > a:hover {
	background-color: rgba(255, 255, 255, 0.24);
}
.layout-menu-snow .layout-sidebar .layout-menu > li.active-menuitem > a {
	background-color: #81A1C1;
}
.layout-menu-snow .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
	color: #3b4252;
}
.layout-menu-snow .layout-sidebar .layout-menu > li.active-menuitem > a i {
	color: #2e3440;
}
.layout-menu-snow .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
	background-color: #81A1C1;
}
.layout-menu-snow .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
	color: #3b4252;
}
.layout-menu-snow .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
	color: #2e3440;
}
.layout-menu-snow .layout-sidebar .layout-menu > li ul {
	background-color: rgba(255, 255, 255, 0.12);
}
.layout-menu-snow .layout-sidebar .layout-menu > li ul li a .menuitem-text {
	color: #eceff4;
}
.layout-menu-snow .layout-sidebar .layout-menu > li ul li a i {
	color: #eceff4;
}
.layout-menu-snow .layout-sidebar .layout-menu > li ul li a:hover {
	background-color: rgba(0, 0, 0, 0.36);
}
.layout-menu-snow .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
	color: #81A1C1;
}
.layout-menu-snow .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
	color: #81A1C1;
}
@media (min-width: 1200px) {
	.layout-menu-snow.layout-horizontal .layout-sidebar {
		background-color: #414A55;
		background-image: url("../../images/menu/snow-horizontal.jpg");
		background-size: auto 100%;
		background-repeat: no-repeat;
	}
	.layout-menu-snow.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
		color: #81A1C1;
	}
	.layout-menu-snow.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
		color: #81A1C1;
	}
	.layout-menu-snow.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-snow.layout-horizontal .layout-sidebar .layout-menu > li > a i {
		color: #eceff4;
	}
	.layout-menu-snow.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
		background-color: rgba(255, 255, 255, 0.24);
	}
	.layout-menu-snow.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
		background-color: #81A1C1;
	}
	.layout-menu-snow.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
		color: #3b4252;
	}
	.layout-menu-snow.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
		color: #2e3440;
	}
	.layout-menu-snow.layout-horizontal .layout-sidebar .layout-menu > li ul {
		background-color: #2e3440;
	}
	.layout-menu-snow.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-snow.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
		color: #eceff4;
	}
	.layout-menu-snow.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
		background-color: rgba(0, 0, 0, 0.36);
	}
	.layout-menu-snow.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
		color: #81A1C1;
	}
	.layout-menu-snow.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
		color: #81A1C1;
	}
}

.layout-menu-wall .layout-sidebar {
	background-color: #50555F;
	background-image: url("../../images/menu/wall.jpg");
	background-size: 240px 100%;
	background-repeat: no-repeat;
}
.layout-menu-wall .layout-sidebar .sidebar-logo .sidebar-pin {
	background-color: #ffffff;
}
.layout-menu-wall .layout-sidebar .sidebar-logo .sidebar-pin i {
	color: #2e3440;
}
.layout-menu-wall .layout-sidebar .sidebar-logo .app-name {
	color: #ffffff;
}
.layout-menu-wall .layout-sidebar .layout-menu li > a {
	cursor: pointer;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	outline: none;
}
.layout-menu-wall .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
	color: #81A1C1;
}
.layout-menu-wall .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
	color: #81A1C1;
}
.layout-menu-wall .layout-sidebar .layout-menu > li > a .menuitem-text {
	color: #eceff4;
}
.layout-menu-wall .layout-sidebar .layout-menu > li > a i {
	color: #eceff4;
}
.layout-menu-wall .layout-sidebar .layout-menu > li > a:hover {
	background-color: rgba(255, 255, 255, 0.24);
}
.layout-menu-wall .layout-sidebar .layout-menu > li.active-menuitem > a {
	background-color: #81A1C1;
}
.layout-menu-wall .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
	color: #3b4252;
}
.layout-menu-wall .layout-sidebar .layout-menu > li.active-menuitem > a i {
	color: #2e3440;
}
.layout-menu-wall .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
	background-color: #81A1C1;
}
.layout-menu-wall .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
	color: #3b4252;
}
.layout-menu-wall .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
	color: #2e3440;
}
.layout-menu-wall .layout-sidebar .layout-menu > li ul {
	background-color: rgba(255, 255, 255, 0.12);
}
.layout-menu-wall .layout-sidebar .layout-menu > li ul li a .menuitem-text {
	color: #eceff4;
}
.layout-menu-wall .layout-sidebar .layout-menu > li ul li a i {
	color: #eceff4;
}
.layout-menu-wall .layout-sidebar .layout-menu > li ul li a:hover {
	background-color: rgba(0, 0, 0, 0.42);
}
.layout-menu-wall .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
	color: #81A1C1;
}
.layout-menu-wall .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
	color: #81A1C1;
}
@media (min-width: 1200px) {
	.layout-menu-wall.layout-horizontal .layout-sidebar {
		background-color: #50555F;
		background-image: url("../../images/menu/wall-horizontal.jpg");
		background-size: auto 100%;
		background-repeat: no-repeat;
	}
	.layout-menu-wall.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
		color: #81A1C1;
	}
	.layout-menu-wall.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
		color: #81A1C1;
	}
	.layout-menu-wall.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-wall.layout-horizontal .layout-sidebar .layout-menu > li > a i {
		color: #eceff4;
	}
	.layout-menu-wall.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
		background-color: rgba(255, 255, 255, 0.24);
	}
	.layout-menu-wall.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
		background-color: #81A1C1;
	}
	.layout-menu-wall.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
		color: #3b4252;
	}
	.layout-menu-wall.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
		color: #2e3440;
	}
	.layout-menu-wall.layout-horizontal .layout-sidebar .layout-menu > li ul {
		background-color: #2e3440;
	}
	.layout-menu-wall.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-wall.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
		color: #eceff4;
	}
	.layout-menu-wall.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
		background-color: rgba(0, 0, 0, 0.42);
	}
	.layout-menu-wall.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
		color: #81A1C1;
	}
	.layout-menu-wall.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
		color: #81A1C1;
	}
}

.layout-menu-ferris .layout-sidebar {
	background-color: #262B33;
	background-image: url("../../images/menu/ferris.jpg");
	background-size: 240px 100%;
	background-repeat: no-repeat;
}
.layout-menu-ferris .layout-sidebar .sidebar-logo .sidebar-pin {
	background-color: #ffffff;
}
.layout-menu-ferris .layout-sidebar .sidebar-logo .sidebar-pin i {
	color: #2e3440;
}
.layout-menu-ferris .layout-sidebar .sidebar-logo .app-name {
	color: #ffffff;
}
.layout-menu-ferris .layout-sidebar .layout-menu li > a {
	cursor: pointer;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	outline: none;
}
.layout-menu-ferris .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
	color: #A3BE8C;
}
.layout-menu-ferris .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
	color: #A3BE8C;
}
.layout-menu-ferris .layout-sidebar .layout-menu > li > a .menuitem-text {
	color: #eceff4;
}
.layout-menu-ferris .layout-sidebar .layout-menu > li > a i {
	color: #eceff4;
}
.layout-menu-ferris .layout-sidebar .layout-menu > li > a:hover {
	background-color: rgba(255, 255, 255, 0.24);
}
.layout-menu-ferris .layout-sidebar .layout-menu > li.active-menuitem > a {
	background-color: #A3BE8C;
}
.layout-menu-ferris .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
	color: #3b4252;
}
.layout-menu-ferris .layout-sidebar .layout-menu > li.active-menuitem > a i {
	color: #2e3440;
}
.layout-menu-ferris .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
	background-color: #A3BE8C;
}
.layout-menu-ferris .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
	color: #3b4252;
}
.layout-menu-ferris .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
	color: #2e3440;
}
.layout-menu-ferris .layout-sidebar .layout-menu > li ul {
	background-color: rgba(255, 255, 255, 0.12);
}
.layout-menu-ferris .layout-sidebar .layout-menu > li ul li a .menuitem-text {
	color: #eceff4;
}
.layout-menu-ferris .layout-sidebar .layout-menu > li ul li a i {
	color: #eceff4;
}
.layout-menu-ferris .layout-sidebar .layout-menu > li ul li a:hover {
	background-color: rgba(0, 0, 0, 0.32);
}
.layout-menu-ferris .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
	color: #A3BE8C;
}
.layout-menu-ferris .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
	color: #A3BE8C;
}
@media (min-width: 1200px) {
	.layout-menu-ferris.layout-horizontal .layout-sidebar {
		background-color: #262B33;
		background-image: url("../../images/menu/ferris-horizontal.jpg");
		background-size: auto 100%;
		background-repeat: no-repeat;
	}
	.layout-menu-ferris.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
		color: #A3BE8C;
	}
	.layout-menu-ferris.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
		color: #A3BE8C;
	}
	.layout-menu-ferris.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-ferris.layout-horizontal .layout-sidebar .layout-menu > li > a i {
		color: #eceff4;
	}
	.layout-menu-ferris.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
		background-color: rgba(255, 255, 255, 0.24);
	}
	.layout-menu-ferris.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
		background-color: #A3BE8C;
	}
	.layout-menu-ferris.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
		color: #3b4252;
	}
	.layout-menu-ferris.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
		color: #2e3440;
	}
	.layout-menu-ferris.layout-horizontal .layout-sidebar .layout-menu > li ul {
		background-color: #2e3440;
	}
	.layout-menu-ferris.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-ferris.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
		color: #eceff4;
	}
	.layout-menu-ferris.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
		background-color: rgba(0, 0, 0, 0.32);
	}
	.layout-menu-ferris.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
		color: #A3BE8C;
	}
	.layout-menu-ferris.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
		color: #A3BE8C;
	}
}

.layout-menu-ship .layout-sidebar {
	background-color: #262B35;
	background-image: url("../../images/menu/ship.jpg");
	background-size: 240px 100%;
	background-repeat: no-repeat;
}
.layout-menu-ship .layout-sidebar .sidebar-logo .sidebar-pin {
	background-color: #ffffff;
}
.layout-menu-ship .layout-sidebar .sidebar-logo .sidebar-pin i {
	color: #2e3440;
}
.layout-menu-ship .layout-sidebar .sidebar-logo .app-name {
	color: #ffffff;
}
.layout-menu-ship .layout-sidebar .layout-menu li > a {
	cursor: pointer;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	outline: none;
}
.layout-menu-ship .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
	color: #EBCB8B;
}
.layout-menu-ship .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
	color: #EBCB8B;
}
.layout-menu-ship .layout-sidebar .layout-menu > li > a .menuitem-text {
	color: #eceff4;
}
.layout-menu-ship .layout-sidebar .layout-menu > li > a i {
	color: #eceff4;
}
.layout-menu-ship .layout-sidebar .layout-menu > li > a:hover {
	background-color: rgba(255, 255, 255, 0.24);
}
.layout-menu-ship .layout-sidebar .layout-menu > li.active-menuitem > a {
	background-color: #EBCB8B;
}
.layout-menu-ship .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
	color: #3b4252;
}
.layout-menu-ship .layout-sidebar .layout-menu > li.active-menuitem > a i {
	color: #2e3440;
}
.layout-menu-ship .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
	background-color: #EBCB8B;
}
.layout-menu-ship .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
	color: #3b4252;
}
.layout-menu-ship .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
	color: #2e3440;
}
.layout-menu-ship .layout-sidebar .layout-menu > li ul {
	background-color: rgba(255, 255, 255, 0.12);
}
.layout-menu-ship .layout-sidebar .layout-menu > li ul li a .menuitem-text {
	color: #eceff4;
}
.layout-menu-ship .layout-sidebar .layout-menu > li ul li a i {
	color: #eceff4;
}
.layout-menu-ship .layout-sidebar .layout-menu > li ul li a:hover {
	background-color: rgba(0, 0, 0, 0.42);
}
.layout-menu-ship .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
	color: #EBCB8B;
}
.layout-menu-ship .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
	color: #EBCB8B;
}
@media (min-width: 1200px) {
	.layout-menu-ship.layout-horizontal .layout-sidebar {
		background-color: #262B35;
		background-image: url("../../images/menu/ship-horizontal.jpg");
		background-size: auto 100%;
		background-repeat: no-repeat;
	}
	.layout-menu-ship.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
		color: #EBCB8B;
	}
	.layout-menu-ship.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
		color: #EBCB8B;
	}
	.layout-menu-ship.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-ship.layout-horizontal .layout-sidebar .layout-menu > li > a i {
		color: #eceff4;
	}
	.layout-menu-ship.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
		background-color: rgba(255, 255, 255, 0.24);
	}
	.layout-menu-ship.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
		background-color: #EBCB8B;
	}
	.layout-menu-ship.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
		color: #3b4252;
	}
	.layout-menu-ship.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
		color: #2e3440;
	}
	.layout-menu-ship.layout-horizontal .layout-sidebar .layout-menu > li ul {
		background-color: #2e3440;
	}
	.layout-menu-ship.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-ship.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
		color: #eceff4;
	}
	.layout-menu-ship.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
		background-color: rgba(0, 0, 0, 0.42);
	}
	.layout-menu-ship.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
		color: #EBCB8B;
	}
	.layout-menu-ship.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
		color: #EBCB8B;
	}
}

.layout-menu-ny .layout-sidebar {
	background-color: #585D67;
	background-image: url("../../images/menu/ny.jpg");
	background-size: 240px 100%;
	background-repeat: no-repeat;
}
.layout-menu-ny .layout-sidebar .sidebar-logo .sidebar-pin {
	background-color: #ffffff;
}
.layout-menu-ny .layout-sidebar .sidebar-logo .sidebar-pin i {
	color: #2e3440;
}
.layout-menu-ny .layout-sidebar .sidebar-logo .app-name {
	color: #ffffff;
}
.layout-menu-ny .layout-sidebar .layout-menu li > a {
	cursor: pointer;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	outline: none;
}
.layout-menu-ny .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
	color: #8FBCBB;
}
.layout-menu-ny .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
	color: #8FBCBB;
}
.layout-menu-ny .layout-sidebar .layout-menu > li > a .menuitem-text {
	color: #eceff4;
}
.layout-menu-ny .layout-sidebar .layout-menu > li > a i {
	color: #eceff4;
}
.layout-menu-ny .layout-sidebar .layout-menu > li > a:hover {
	background-color: rgba(255, 255, 255, 0.24);
}
.layout-menu-ny .layout-sidebar .layout-menu > li.active-menuitem > a {
	background-color: #8FBCBB;
}
.layout-menu-ny .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
	color: #3b4252;
}
.layout-menu-ny .layout-sidebar .layout-menu > li.active-menuitem > a i {
	color: #2e3440;
}
.layout-menu-ny .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
	background-color: #8FBCBB;
}
.layout-menu-ny .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
	color: #3b4252;
}
.layout-menu-ny .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
	color: #2e3440;
}
.layout-menu-ny .layout-sidebar .layout-menu > li ul {
	background-color: rgba(255, 255, 255, 0.12);
}
.layout-menu-ny .layout-sidebar .layout-menu > li ul li a .menuitem-text {
	color: #eceff4;
}
.layout-menu-ny .layout-sidebar .layout-menu > li ul li a i {
	color: #eceff4;
}
.layout-menu-ny .layout-sidebar .layout-menu > li ul li a:hover {
	background-color: rgba(0, 0, 0, 0.36);
}
.layout-menu-ny .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
	color: #8FBCBB;
}
.layout-menu-ny .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
	color: #8FBCBB;
}
@media (min-width: 1200px) {
	.layout-menu-ny.layout-horizontal .layout-sidebar {
		background-color: #585D67;
		background-image: url("../../images/menu/ny-horizontal.jpg");
		background-size: auto 100%;
		background-repeat: no-repeat;
	}
	.layout-menu-ny.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
		color: #8FBCBB;
	}
	.layout-menu-ny.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
		color: #8FBCBB;
	}
	.layout-menu-ny.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-ny.layout-horizontal .layout-sidebar .layout-menu > li > a i {
		color: #eceff4;
	}
	.layout-menu-ny.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
		background-color: rgba(255, 255, 255, 0.24);
	}
	.layout-menu-ny.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
		background-color: #8FBCBB;
	}
	.layout-menu-ny.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
		color: #3b4252;
	}
	.layout-menu-ny.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
		color: #2e3440;
	}
	.layout-menu-ny.layout-horizontal .layout-sidebar .layout-menu > li ul {
		background-color: #2e3440;
	}
	.layout-menu-ny.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
		color: #eceff4;
	}
	.layout-menu-ny.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
		color: #eceff4;
	}
	.layout-menu-ny.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
		background-color: rgba(0, 0, 0, 0.36);
	}
	.layout-menu-ny.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
		color: #8FBCBB;
	}
	.layout-menu-ny.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
		color: #8FBCBB;
	}
}

.layout-menu-cyan .layout-sidebar {
	background-color: #3C5259;
}
.layout-menu-cyan .layout-sidebar .sidebar-logo .sidebar-pin {
	background-color: #ffffff;
}
.layout-menu-cyan .layout-sidebar .sidebar-logo .sidebar-pin i {
	color: #2e3440;
}
.layout-menu-cyan .layout-sidebar .sidebar-logo .app-name {
	color: #ffffff;
}
.layout-menu-cyan .layout-sidebar .layout-menu li > a {
	cursor: pointer;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	outline: none;
}
.layout-menu-cyan .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
	color: #D08770;
}
.layout-menu-cyan .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
	color: #D08770;
}
.layout-menu-cyan .layout-sidebar .layout-menu > li > a .menuitem-text {
	color: #ffffff;
}
.layout-menu-cyan .layout-sidebar .layout-menu > li > a i {
	color: #ffffff;
}
.layout-menu-cyan .layout-sidebar .layout-menu > li > a:hover {
	background-color: rgba(255, 255, 255, 0.24);
}
.layout-menu-cyan .layout-sidebar .layout-menu > li.active-menuitem > a {
	background-color: #D08770;
}
.layout-menu-cyan .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
	color: #343a40;
}
.layout-menu-cyan .layout-sidebar .layout-menu > li.active-menuitem > a i {
	color: #343a40;
}
.layout-menu-cyan .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
	background-color: #D08770;
}
.layout-menu-cyan .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
	color: #343a40;
}
.layout-menu-cyan .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
	color: #343a40;
}
.layout-menu-cyan .layout-sidebar .layout-menu > li ul {
	background-color: rgba(255, 255, 255, 0.12);
}
.layout-menu-cyan .layout-sidebar .layout-menu > li ul li a .menuitem-text {
	color: #ffffff;
}
.layout-menu-cyan .layout-sidebar .layout-menu > li ul li a i {
	color: #ffffff;
}
.layout-menu-cyan .layout-sidebar .layout-menu > li ul li a:hover {
	background-color: rgba(0, 0, 0, 0.36);
}
.layout-menu-cyan .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
	color: #D08770;
}
.layout-menu-cyan .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
	color: #D08770;
}
@media (min-width: 1200px) {
	.layout-menu-cyan.layout-horizontal .layout-sidebar {
		background-color: #3C5259;
	}
	.layout-menu-cyan.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
		color: #D08770;
	}
	.layout-menu-cyan.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
		color: #D08770;
	}
	.layout-menu-cyan.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
		color: #ffffff;
	}
	.layout-menu-cyan.layout-horizontal .layout-sidebar .layout-menu > li > a i {
		color: #ffffff;
	}
	.layout-menu-cyan.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
		background-color: rgba(255, 255, 255, 0.24);
	}
	.layout-menu-cyan.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
		background-color: #D08770;
	}
	.layout-menu-cyan.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
		color: #343a40;
	}
	.layout-menu-cyan.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
		color: #343a40;
	}
	.layout-menu-cyan.layout-horizontal .layout-sidebar .layout-menu > li ul {
		background-color: #2e3440;
	}
	.layout-menu-cyan.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
		color: #ffffff;
	}
	.layout-menu-cyan.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
		color: #ffffff;
	}
	.layout-menu-cyan.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
		background-color: rgba(0, 0, 0, 0.36);
	}
	.layout-menu-cyan.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
		color: #D08770;
	}
	.layout-menu-cyan.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
		color: #D08770;
	}
}

.layout-menu-violet .layout-sidebar {
	background-color: #4A4358;
}
.layout-menu-violet .layout-sidebar .sidebar-logo .sidebar-pin {
	background-color: #ffffff;
}
.layout-menu-violet .layout-sidebar .sidebar-logo .sidebar-pin i {
	color: #2e3440;
}
.layout-menu-violet .layout-sidebar .sidebar-logo .app-name {
	color: #ffffff;
}
.layout-menu-violet .layout-sidebar .layout-menu li > a {
	cursor: pointer;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	outline: none;
}
.layout-menu-violet .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
	color: #F8C869;
}
.layout-menu-violet .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
	color: #F8C869;
}
.layout-menu-violet .layout-sidebar .layout-menu > li > a .menuitem-text {
	color: #ffffff;
}
.layout-menu-violet .layout-sidebar .layout-menu > li > a i {
	color: #ffffff;
}
.layout-menu-violet .layout-sidebar .layout-menu > li > a:hover {
	background-color: rgba(255, 255, 255, 0.24);
}
.layout-menu-violet .layout-sidebar .layout-menu > li.active-menuitem > a {
	background-color: #F8C869;
}
.layout-menu-violet .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
	color: #343a40;
}
.layout-menu-violet .layout-sidebar .layout-menu > li.active-menuitem > a i {
	color: #343a40;
}
.layout-menu-violet .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
	background-color: #F8C869;
}
.layout-menu-violet .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
	color: #343a40;
}
.layout-menu-violet .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
	color: #343a40;
}
.layout-menu-violet .layout-sidebar .layout-menu > li ul {
	background-color: rgba(255, 255, 255, 0.12);
}
.layout-menu-violet .layout-sidebar .layout-menu > li ul li a .menuitem-text {
	color: #ffffff;
}
.layout-menu-violet .layout-sidebar .layout-menu > li ul li a i {
	color: #ffffff;
}
.layout-menu-violet .layout-sidebar .layout-menu > li ul li a:hover {
	background-color: rgba(0, 0, 0, 0.36);
}
.layout-menu-violet .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
	color: #F8C869;
}
.layout-menu-violet .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
	color: #F8C869;
}
@media (min-width: 1200px) {
	.layout-menu-violet.layout-horizontal .layout-sidebar {
		background-color: #4A4358;
	}
	.layout-menu-violet.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
		color: #F8C869;
	}
	.layout-menu-violet.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
		color: #F8C869;
	}
	.layout-menu-violet.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
		color: #ffffff;
	}
	.layout-menu-violet.layout-horizontal .layout-sidebar .layout-menu > li > a i {
		color: #ffffff;
	}
	.layout-menu-violet.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
		background-color: rgba(255, 255, 255, 0.24);
	}
	.layout-menu-violet.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
		background-color: #F8C869;
	}
	.layout-menu-violet.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
		color: #343a40;
	}
	.layout-menu-violet.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
		color: #343a40;
	}
	.layout-menu-violet.layout-horizontal .layout-sidebar .layout-menu > li ul {
		background-color: #2e3440;
	}
	.layout-menu-violet.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
		color: #ffffff;
	}
	.layout-menu-violet.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
		color: #ffffff;
	}
	.layout-menu-violet.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
		background-color: rgba(0, 0, 0, 0.36);
	}
	.layout-menu-violet.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
		color: #F8C869;
	}
	.layout-menu-violet.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
		color: #F8C869;
	}
}

.layout-menu-red .layout-sidebar {
	background-color: #5E4346;
}
.layout-menu-red .layout-sidebar .sidebar-logo .sidebar-pin {
	background-color: #ffffff;
}
.layout-menu-red .layout-sidebar .sidebar-logo .sidebar-pin i {
	color: #2e3440;
}
.layout-menu-red .layout-sidebar .sidebar-logo .app-name {
	color: #ffffff;
}
.layout-menu-red .layout-sidebar .layout-menu li > a {
	cursor: pointer;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	outline: none;
}
.layout-menu-red .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
	color: #8FBCBB;
}
.layout-menu-red .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
	color: #8FBCBB;
}
.layout-menu-red .layout-sidebar .layout-menu > li > a .menuitem-text {
	color: #ffffff;
}
.layout-menu-red .layout-sidebar .layout-menu > li > a i {
	color: #ffffff;
}
.layout-menu-red .layout-sidebar .layout-menu > li > a:hover {
	background-color: rgba(255, 255, 255, 0.24);
}
.layout-menu-red .layout-sidebar .layout-menu > li.active-menuitem > a {
	background-color: #8FBCBB;
}
.layout-menu-red .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
	color: #343a40;
}
.layout-menu-red .layout-sidebar .layout-menu > li.active-menuitem > a i {
	color: #343a40;
}
.layout-menu-red .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
	background-color: #8FBCBB;
}
.layout-menu-red .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
	color: #343a40;
}
.layout-menu-red .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
	color: #343a40;
}
.layout-menu-red .layout-sidebar .layout-menu > li ul {
	background-color: rgba(255, 255, 255, 0.12);
}
.layout-menu-red .layout-sidebar .layout-menu > li ul li a .menuitem-text {
	color: #ffffff;
}
.layout-menu-red .layout-sidebar .layout-menu > li ul li a i {
	color: #ffffff;
}
.layout-menu-red .layout-sidebar .layout-menu > li ul li a:hover {
	background-color: rgba(0, 0, 0, 0.36);
}
.layout-menu-red .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
	color: #8FBCBB;
}
.layout-menu-red .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
	color: #8FBCBB;
}
@media (min-width: 1200px) {
	.layout-menu-red.layout-horizontal .layout-sidebar {
		background-color: #5E4346;
	}
	.layout-menu-red.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
		color: #8FBCBB;
	}
	.layout-menu-red.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
		color: #8FBCBB;
	}
	.layout-menu-red.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
		color: #ffffff;
	}
	.layout-menu-red.layout-horizontal .layout-sidebar .layout-menu > li > a i {
		color: #ffffff;
	}
	.layout-menu-red.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
		background-color: rgba(255, 255, 255, 0.24);
	}
	.layout-menu-red.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
		background-color: #8FBCBB;
	}
	.layout-menu-red.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
		color: #343a40;
	}
	.layout-menu-red.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
		color: #343a40;
	}
	.layout-menu-red.layout-horizontal .layout-sidebar .layout-menu > li ul {
		background-color: #2e3440;
	}
	.layout-menu-red.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
		color: #ffffff;
	}
	.layout-menu-red.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
		color: #ffffff;
	}
	.layout-menu-red.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
		background-color: rgba(0, 0, 0, 0.36);
	}
	.layout-menu-red.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
		color: #8FBCBB;
	}
	.layout-menu-red.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
		color: #8FBCBB;
	}
}

.layout-menu-blue .layout-sidebar {
	background-color: #354563;
}
.layout-menu-blue .layout-sidebar .sidebar-logo .sidebar-pin {
	background-color: #ffffff;
}
.layout-menu-blue .layout-sidebar .sidebar-logo .sidebar-pin i {
	color: #2e3440;
}
.layout-menu-blue .layout-sidebar .sidebar-logo .app-name {
	color: #ffffff;
}
.layout-menu-blue .layout-sidebar .layout-menu li > a {
	cursor: pointer;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	outline: none;
}
.layout-menu-blue .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
	color: #f8c869;
}
.layout-menu-blue .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
	color: #f8c869;
}
.layout-menu-blue .layout-sidebar .layout-menu > li > a .menuitem-text {
	color: #ffffff;
}
.layout-menu-blue .layout-sidebar .layout-menu > li > a i {
	color: #ffffff;
}
.layout-menu-blue .layout-sidebar .layout-menu > li > a:hover {
	background-color: rgba(255, 255, 255, 0.24);
}
.layout-menu-blue .layout-sidebar .layout-menu > li.active-menuitem > a {
	background-color: #f8c869;
}
.layout-menu-blue .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
	color: #343a40;
}
.layout-menu-blue .layout-sidebar .layout-menu > li.active-menuitem > a i {
	color: #343a40;
}
.layout-menu-blue .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
	background-color: #f8c869;
}
.layout-menu-blue .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
	color: #343a40;
}
.layout-menu-blue .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
	color: #343a40;
}
.layout-menu-blue .layout-sidebar .layout-menu > li ul {
	background-color: rgba(255, 255, 255, 0.12);
}
.layout-menu-blue .layout-sidebar .layout-menu > li ul li a .menuitem-text {
	color: #ffffff;
}
.layout-menu-blue .layout-sidebar .layout-menu > li ul li a i {
	color: #ffffff;
}
.layout-menu-blue .layout-sidebar .layout-menu > li ul li a:hover {
	background-color: rgba(0, 0, 0, 0.36);
}
.layout-menu-blue .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
	color: #f8c869;
}
.layout-menu-blue .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
	color: #f8c869;
}
@media (min-width: 1200px) {
	.layout-menu-blue.layout-horizontal .layout-sidebar {
		background-color: #354563;
	}
	.layout-menu-blue.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
		color: #f8c869;
	}
	.layout-menu-blue.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
		color: #f8c869;
	}
	.layout-menu-blue.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
		color: #ffffff;
	}
	.layout-menu-blue.layout-horizontal .layout-sidebar .layout-menu > li > a i {
		color: #ffffff;
	}
	.layout-menu-blue.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
		background-color: rgba(255, 255, 255, 0.24);
	}
	.layout-menu-blue.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
		background-color: #f8c869;
	}
	.layout-menu-blue.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
		color: #343a40;
	}
	.layout-menu-blue.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
		color: #343a40;
	}
	.layout-menu-blue.layout-horizontal .layout-sidebar .layout-menu > li ul {
		background-color: #2e3440;
	}
	.layout-menu-blue.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
		color: #ffffff;
	}
	.layout-menu-blue.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
		color: #ffffff;
	}
	.layout-menu-blue.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
		background-color: rgba(0, 0, 0, 0.36);
	}
	.layout-menu-blue.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
		color: #f8c869;
	}
	.layout-menu-blue.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
		color: #f8c869;
	}
}

.layout-menu-porsuk .layout-sidebar {
	background-color: #21596E;
}
.layout-menu-porsuk .layout-sidebar .sidebar-logo .sidebar-pin {
	background-color: #ffffff;
}
.layout-menu-porsuk .layout-sidebar .sidebar-logo .sidebar-pin i {
	color: #2e3440;
}
.layout-menu-porsuk .layout-sidebar .sidebar-logo .app-name {
	color: #ffffff;
}
.layout-menu-porsuk .layout-sidebar .layout-menu li > a {
	cursor: pointer;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	outline: none;
}
.layout-menu-porsuk .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
	color: #A3BE8C;
}
.layout-menu-porsuk .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
	color: #A3BE8C;
}
.layout-menu-porsuk .layout-sidebar .layout-menu > li > a .menuitem-text {
	color: #ffffff;
}
.layout-menu-porsuk .layout-sidebar .layout-menu > li > a i {
	color: #ffffff;
}
.layout-menu-porsuk .layout-sidebar .layout-menu > li > a:hover {
	background-color: rgba(255, 255, 255, 0.24);
}
.layout-menu-porsuk .layout-sidebar .layout-menu > li.active-menuitem > a {
	background-color: #A3BE8C;
}
.layout-menu-porsuk .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
	color: #343a40;
}
.layout-menu-porsuk .layout-sidebar .layout-menu > li.active-menuitem > a i {
	color: #343a40;
}
.layout-menu-porsuk .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
	background-color: #A3BE8C;
}
.layout-menu-porsuk .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
	color: #343a40;
}
.layout-menu-porsuk .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
	color: #343a40;
}
.layout-menu-porsuk .layout-sidebar .layout-menu > li ul {
	background-color: rgba(255, 255, 255, 0.12);
}
.layout-menu-porsuk .layout-sidebar .layout-menu > li ul li a .menuitem-text {
	color: #ffffff;
}
.layout-menu-porsuk .layout-sidebar .layout-menu > li ul li a i {
	color: #ffffff;
}
.layout-menu-porsuk .layout-sidebar .layout-menu > li ul li a:hover {
	background-color: rgba(0, 0, 0, 0.36);
}
.layout-menu-porsuk .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
	color: #A3BE8C;
}
.layout-menu-porsuk .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
	color: #A3BE8C;
}
@media (min-width: 1200px) {
	.layout-menu-porsuk.layout-horizontal .layout-sidebar {
		background-color: #21596E;
	}
	.layout-menu-porsuk.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
		color: #A3BE8C;
	}
	.layout-menu-porsuk.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
		color: #A3BE8C;
	}
	.layout-menu-porsuk.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
		color: #ffffff;
	}
	.layout-menu-porsuk.layout-horizontal .layout-sidebar .layout-menu > li > a i {
		color: #ffffff;
	}
	.layout-menu-porsuk.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
		background-color: rgba(255, 255, 255, 0.24);
	}
	.layout-menu-porsuk.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
		background-color: #A3BE8C;
	}
	.layout-menu-porsuk.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
		color: #343a40;
	}
	.layout-menu-porsuk.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
		color: #343a40;
	}
	.layout-menu-porsuk.layout-horizontal .layout-sidebar .layout-menu > li ul {
		background-color: #2e3440;
	}
	.layout-menu-porsuk.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
		color: #ffffff;
	}
	.layout-menu-porsuk.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
		color: #ffffff;
	}
	.layout-menu-porsuk.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
		background-color: rgba(0, 0, 0, 0.36);
	}
	.layout-menu-porsuk.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
		color: #A3BE8C;
	}
	.layout-menu-porsuk.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
		color: #A3BE8C;
	}
}

.layout-menu-pink .layout-sidebar {
	background-color: #8C515E;
}
.layout-menu-pink .layout-sidebar .sidebar-logo .sidebar-pin {
	background-color: #ffffff;
}
.layout-menu-pink .layout-sidebar .sidebar-logo .sidebar-pin i {
	color: #2e3440;
}
.layout-menu-pink .layout-sidebar .sidebar-logo .app-name {
	color: #ffffff;
}
.layout-menu-pink .layout-sidebar .layout-menu li > a {
	cursor: pointer;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	outline: none;
}
.layout-menu-pink .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
	color: #f8c869;
}
.layout-menu-pink .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
	color: #f8c869;
}
.layout-menu-pink .layout-sidebar .layout-menu > li > a .menuitem-text {
	color: #ffffff;
}
.layout-menu-pink .layout-sidebar .layout-menu > li > a i {
	color: #ffffff;
}
.layout-menu-pink .layout-sidebar .layout-menu > li > a:hover {
	background-color: rgba(255, 255, 255, 0.24);
}
.layout-menu-pink .layout-sidebar .layout-menu > li.active-menuitem > a {
	background-color: #f8c869;
}
.layout-menu-pink .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
	color: #343a40;
}
.layout-menu-pink .layout-sidebar .layout-menu > li.active-menuitem > a i {
	color: #343a40;
}
.layout-menu-pink .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
	background-color: #f8c869;
}
.layout-menu-pink .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
	color: #343a40;
}
.layout-menu-pink .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
	color: #343a40;
}
.layout-menu-pink .layout-sidebar .layout-menu > li ul {
	background-color: rgba(255, 255, 255, 0.12);
}
.layout-menu-pink .layout-sidebar .layout-menu > li ul li a .menuitem-text {
	color: #ffffff;
}
.layout-menu-pink .layout-sidebar .layout-menu > li ul li a i {
	color: #ffffff;
}
.layout-menu-pink .layout-sidebar .layout-menu > li ul li a:hover {
	background-color: rgba(0, 0, 0, 0.36);
}
.layout-menu-pink .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
	color: #f8c869;
}
.layout-menu-pink .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
	color: #f8c869;
}
@media (min-width: 1200px) {
	.layout-menu-pink.layout-horizontal .layout-sidebar {
		background-color: #8C515E;
	}
	.layout-menu-pink.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
		color: #f8c869;
	}
	.layout-menu-pink.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
		color: #f8c869;
	}
	.layout-menu-pink.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
		color: #ffffff;
	}
	.layout-menu-pink.layout-horizontal .layout-sidebar .layout-menu > li > a i {
		color: #ffffff;
	}
	.layout-menu-pink.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
		background-color: rgba(255, 255, 255, 0.24);
	}
	.layout-menu-pink.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
		background-color: #f8c869;
	}
	.layout-menu-pink.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
		color: #343a40;
	}
	.layout-menu-pink.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
		color: #343a40;
	}
	.layout-menu-pink.layout-horizontal .layout-sidebar .layout-menu > li ul {
		background-color: #2e3440;
	}
	.layout-menu-pink.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
		color: #ffffff;
	}
	.layout-menu-pink.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
		color: #ffffff;
	}
	.layout-menu-pink.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
		background-color: rgba(0, 0, 0, 0.36);
	}
	.layout-menu-pink.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
		color: #f8c869;
	}
	.layout-menu-pink.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
		color: #f8c869;
	}
}

.layout-menu-purple .layout-sidebar {
	background-color: #60476f;
}
.layout-menu-purple .layout-sidebar .sidebar-logo .sidebar-pin {
	background-color: #ffffff;
}
.layout-menu-purple .layout-sidebar .sidebar-logo .sidebar-pin i {
	color: #2e3440;
}
.layout-menu-purple .layout-sidebar .sidebar-logo .app-name {
	color: #ffffff;
}
.layout-menu-purple .layout-sidebar .layout-menu li > a {
	cursor: pointer;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	outline: none;
}
.layout-menu-purple .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
	color: #f8c869;
}
.layout-menu-purple .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
	color: #f8c869;
}
.layout-menu-purple .layout-sidebar .layout-menu > li > a .menuitem-text {
	color: #ffffff;
}
.layout-menu-purple .layout-sidebar .layout-menu > li > a i {
	color: #ffffff;
}
.layout-menu-purple .layout-sidebar .layout-menu > li > a:hover {
	background-color: rgba(255, 255, 255, 0.24);
}
.layout-menu-purple .layout-sidebar .layout-menu > li.active-menuitem > a {
	background-color: #f8c869;
}
.layout-menu-purple .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
	color: #343a40;
}
.layout-menu-purple .layout-sidebar .layout-menu > li.active-menuitem > a i {
	color: #343a40;
}
.layout-menu-purple .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
	background-color: #f8c869;
}
.layout-menu-purple .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
	color: #343a40;
}
.layout-menu-purple .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
	color: #343a40;
}
.layout-menu-purple .layout-sidebar .layout-menu > li ul {
	background-color: rgba(255, 255, 255, 0.12);
}
.layout-menu-purple .layout-sidebar .layout-menu > li ul li a .menuitem-text {
	color: #ffffff;
}
.layout-menu-purple .layout-sidebar .layout-menu > li ul li a i {
	color: #ffffff;
}
.layout-menu-purple .layout-sidebar .layout-menu > li ul li a:hover {
	background-color: rgba(0, 0, 0, 0.36);
}
.layout-menu-purple .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
	color: #f8c869;
}
.layout-menu-purple .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
	color: #f8c869;
}
@media (min-width: 1200px) {
	.layout-menu-purple.layout-horizontal .layout-sidebar {
		background-color: #60476f;
	}
	.layout-menu-purple.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
		color: #f8c869;
	}
	.layout-menu-purple.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
		color: #f8c869;
	}
	.layout-menu-purple.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
		color: #ffffff;
	}
	.layout-menu-purple.layout-horizontal .layout-sidebar .layout-menu > li > a i {
		color: #ffffff;
	}
	.layout-menu-purple.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
		background-color: rgba(255, 255, 255, 0.24);
	}
	.layout-menu-purple.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
		background-color: #f8c869;
	}
	.layout-menu-purple.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
		color: #343a40;
	}
	.layout-menu-purple.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
		color: #343a40;
	}
	.layout-menu-purple.layout-horizontal .layout-sidebar .layout-menu > li ul {
		background-color: #2e3440;
	}
	.layout-menu-purple.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
		color: #ffffff;
	}
	.layout-menu-purple.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
		color: #ffffff;
	}
	.layout-menu-purple.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
		background-color: rgba(0, 0, 0, 0.36);
	}
	.layout-menu-purple.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
		color: #f8c869;
	}
	.layout-menu-purple.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
		color: #f8c869;
	}
}

.layout-menu-orange .layout-sidebar {
	background-color: #9B6F61;
}
.layout-menu-orange .layout-sidebar .sidebar-logo .sidebar-pin {
	background-color: #ffffff;
}
.layout-menu-orange .layout-sidebar .sidebar-logo .sidebar-pin i {
	color: #2e3440;
}
.layout-menu-orange .layout-sidebar .sidebar-logo .app-name {
	color: #ffffff;
}
.layout-menu-orange .layout-sidebar .layout-menu li > a {
	cursor: pointer;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	outline: none;
}
.layout-menu-orange .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
	color: #88C0D0;
}
.layout-menu-orange .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
	color: #88C0D0;
}
.layout-menu-orange .layout-sidebar .layout-menu > li > a .menuitem-text {
	color: #ffffff;
}
.layout-menu-orange .layout-sidebar .layout-menu > li > a i {
	color: #ffffff;
}
.layout-menu-orange .layout-sidebar .layout-menu > li > a:hover {
	background-color: rgba(255, 255, 255, 0.24);
}
.layout-menu-orange .layout-sidebar .layout-menu > li.active-menuitem > a {
	background-color: #88C0D0;
}
.layout-menu-orange .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
	color: #343a40;
}
.layout-menu-orange .layout-sidebar .layout-menu > li.active-menuitem > a i {
	color: #343a40;
}
.layout-menu-orange .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
	background-color: #88C0D0;
}
.layout-menu-orange .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
	color: #343a40;
}
.layout-menu-orange .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
	color: #343a40;
}
.layout-menu-orange .layout-sidebar .layout-menu > li ul {
	background-color: rgba(255, 255, 255, 0.12);
}
.layout-menu-orange .layout-sidebar .layout-menu > li ul li a .menuitem-text {
	color: #ffffff;
}
.layout-menu-orange .layout-sidebar .layout-menu > li ul li a i {
	color: #ffffff;
}
.layout-menu-orange .layout-sidebar .layout-menu > li ul li a:hover {
	background-color: rgba(0, 0, 0, 0.36);
}
.layout-menu-orange .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
	color: #88C0D0;
}
.layout-menu-orange .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
	color: #88C0D0;
}
@media (min-width: 1200px) {
	.layout-menu-orange.layout-horizontal .layout-sidebar {
		background-color: #9B6F61;
	}
	.layout-menu-orange.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
		color: #88C0D0;
	}
	.layout-menu-orange.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
		color: #88C0D0;
	}
	.layout-menu-orange.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
		color: #ffffff;
	}
	.layout-menu-orange.layout-horizontal .layout-sidebar .layout-menu > li > a i {
		color: #ffffff;
	}
	.layout-menu-orange.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
		background-color: rgba(255, 255, 255, 0.24);
	}
	.layout-menu-orange.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
		background-color: #88C0D0;
	}
	.layout-menu-orange.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
		color: #343a40;
	}
	.layout-menu-orange.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
		color: #343a40;
	}
	.layout-menu-orange.layout-horizontal .layout-sidebar .layout-menu > li ul {
		background-color: #2e3440;
	}
	.layout-menu-orange.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
		color: #ffffff;
	}
	.layout-menu-orange.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
		color: #ffffff;
	}
	.layout-menu-orange.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
		background-color: rgba(0, 0, 0, 0.36);
	}
	.layout-menu-orange.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
		color: #88C0D0;
	}
	.layout-menu-orange.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
		color: #88C0D0;
	}
}

.layout-topbar {
	background-color: #eceff4;
	position: fixed;
	top: 0;
	z-index: 998;
	width: calc(100% - 60px);
	-moz-transition: width 0.15s;
	-o-transition: width 0.15s;
	-webkit-transition: width 0.15s;
	transition: width 0.15s;
	-webkit-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
	box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
}
.layout-topbar .layout-topbar-wrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	height: 75px;
	padding: 0 2em;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-left {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	flex-grow: 1;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-left .sidebar-menu-button,
.layout-topbar .layout-topbar-wrapper .layout-topbar-left .megamenu-mobile-button,
.layout-topbar .layout-topbar-wrapper .layout-topbar-left .topbar-menu-mobile-button,
.layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-topbar-logo {
	display: none;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu-button {
	padding: 8px;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	font-size: 13px;
	border: none;
	cursor: pointer;
	height: 35px;
	background-color: #ffffff;
	color: #4c566a;
	margin-top: 2px;
	-moz-transition: background-color 0.15s;
	-o-transition: background-color 0.15s;
	-webkit-transition: background-color 0.15s;
	transition: background-color 0.15s;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu-button i {
	font-size: 20px;
	vertical-align: middle;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu-button:hover, .layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu-button:focus {
	background-color: #5e81ac;
	color: #ffffff;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu {
	position: absolute;
	padding: 12px 18px;
	padding-bottom: 24px;
	background-color: #ffffff;
	top: 60px;
	display: none;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	list-style-type: none;
	-webkit-box-shadow: 0 3px 5px 0 rgba(33, 35, 39, 0.15);
	-moz-box-shadow: 0 3px 5px 0 rgba(33, 35, 39, 0.15);
	box-shadow: 0 3px 5px 0 rgba(33, 35, 39, 0.15);
	z-index: 1000;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu:before {
	width: 100%;
	height: 6px;
	background-color: #5e81ac;
	content: " ";
	-moz-border-radius-topleft: 2px;
	-webkit-border-top-left-radius: 2px;
	border-top-left-radius: 2px;
	-moz-border-radius-topright: 2px;
	-webkit-border-top-right-radius: 2px;
	border-top-right-radius: 2px;
	position: absolute;
	top: -5px;
	left: 0px;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu > li {
	min-width: 200px;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu > li > a {
	font-size: 12px;
	color: #4c566a;
	padding-bottom: 6px;
	border-bottom: 1px solid rgba(195, 204, 221, 0.5);
	width: 90%;
	display: block;
	cursor: default;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu > li > a i {
	display: none;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu > li > ul {
	padding: 0;
	margin-top: 15px;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu > li > ul > li {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	cursor: pointer;
	margin-top: 12px;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu > li > ul > li i {
	font-size: 10px;
	color: #4c566a;
	padding-right: 10px;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu > li > ul > li > span h5 {
	color: #4c566a;
	font-weight: 500;
	margin: 0;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu > li > ul > li > span span {
	color: #9199a9;
	font-size: 12px;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu > li > ul > li.active-row i {
	color: #a3be8c;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu > li > ul > li:hover i {
	color: #5e81ac;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu > li > ul > li:hover > span h5 {
	color: #5e81ac;
	-moz-transition: color 0.15s;
	-o-transition: color 0.15s;
	-webkit-transition: color 0.15s;
	transition: color 0.15s;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu.layout-megamenu-active {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	list-style-type: none;
	margin: 0;
	padding: 0;
	height: 100%;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	height: 100%;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > a {
	width: 100%;
	padding: 0.7em;
	margin: 0 0.5em;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > a.layout-rightpanel-button i {
	font-size: 22px;
	font-weight: bold;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > a i {
	color: #4c566a;
	font-size: 22px;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > a:hover i, .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > a:focus i {
	color: #5e81ac;
	-moz-transition: color 0.15s;
	-o-transition: color 0.15s;
	-webkit-transition: color 0.15s;
	transition: color 0.15s;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > a.layout-rightpanel-button i {
	-moz-transition: transform 0.15s;
	-o-transition: transform 0.15s;
	-webkit-transition: transform 0.15s;
	transition: transform 0.15s;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul {
	display: none;
	position: absolute;
	top: 75px;
	left: 5px;
	list-style-type: none;
	margin: 0;
	padding: 0;
	z-index: 1000;
	min-width: 250px;
	background-color: #ffffff;
	-webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
	-moz-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul:before {
	width: 50px;
	height: 6px;
	background-color: #5e81ac;
	content: " ";
	-moz-border-radius-topleft: 2px;
	-webkit-border-top-left-radius: 2px;
	border-top-left-radius: 2px;
	-moz-border-radius-topright: 2px;
	-webkit-border-top-right-radius: 2px;
	border-top-right-radius: 2px;
	position: absolute;
	top: -5px;
	left: 0px;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul .layout-submenu-header {
	background-color: #5e81ac;
	padding: 18px;
	display: block;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul .layout-submenu-header h1 {
	font-size: 16px;
	font-weight: normal;
	margin: 0;
	color: #ffffff;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul .layout-submenu-header span {
	font-size: 13px;
	color: #f0f4ec;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li {
	padding: 10px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	width: 100%;
	color: #4c566a;
	cursor: pointer;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li img {
	margin-right: 8px;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li i {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	flex-grow: 1;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li .menu-text p {
	margin: 0;
	color: #4c566a;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li .menu-text span {
	color: #9199a9;
	font-size: 12px;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li.layout-submenu-item:hover {
	background-color: #f9fafb;
	-moz-transition: background-color 0.15s;
	-o-transition: background-color 0.15s;
	-webkit-transition: background-color 0.15s;
	transition: background-color 0.15s;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li.layout-submenu-item:hover i {
	color: #4c566a;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li.calendar {
	padding: 0;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li.calendar p-calendar, .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li.calendar .p-calendar {
	width: 100%;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li.calendar .p-datepicker-inline {
	background-color: #ffffff;
	border: none;
	width: 100%;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li.calendar .p-datepicker-inline .p-datepicker-next,
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li.calendar .p-datepicker-inline .p-datepicker-prev {
	display: none;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li.calendar .p-datepicker-inline .p-datepicker-calendar th {
	color: #9199a9;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li.calendar .p-datepicker-inline .p-datepicker-calendar td {
	color: #4c566a;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li.deals {
	padding: 0;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li.deals > ul {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	list-style-type: none;
	padding: 0;
	margin: 0;
	width: 100%;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li.deals > ul > li {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	border-bottom: 0.5px solid rgba(145, 153, 169, 0.3);
	border-right: 0.5px solid rgba(145, 153, 169, 0.3);
	padding: 15px 5px;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li.deals > ul > li:hover {
	background-color: #f9fafb;
	-moz-transition: background-color 0.15s;
	-o-transition: background-color 0.15s;
	-webkit-transition: background-color 0.15s;
	transition: background-color 0.15s;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li.deals > ul > li:hover i {
	color: #4c566a;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul.search-item-submenu {
	display: block;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	position: static;
	min-width: auto;
	margin-right: 0.5em;
	list-style-type: none;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul.search-item-submenu:before {
	display: none;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul.search-item-submenu > li {
	padding: 0;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul.search-item-submenu > li .search-input-wrapper {
	position: relative;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul.search-item-submenu > li .search-input-wrapper input {
	padding: 10px 40px 10px 10px;
	width: 180px;
	border: none;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	background-color: #ffffff;
	color: #4c566a;
	font-size: 13px;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul.search-item-submenu > li .search-input-wrapper input::placeholder {
	color: #c9ccd2;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul.search-item-submenu > li .search-input-wrapper i {
	position: absolute;
	right: 12px;
	top: 50%;
	margin-top: -10px;
	color: #d8dee9;
	font-size: 20px;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.active-topmenuitem > ul {
	display: block;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.search-item > a {
	display: none;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item {
	position: relative;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item > a {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	width: 100%;
	height: 100%;
	padding: 0.5em;
	margin: 0 0.5em;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item > a .profile-image-wrapper img {
	height: 45px;
	width: 45px;
	border: 0 none;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item > a .profile-info-wrapper {
	margin-left: 15px;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item > a .profile-info-wrapper h3 {
	font-size: 16px;
	font-weight: 500;
	color: #4c566a;
	margin: 0;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item > a .profile-info-wrapper span {
	font-size: 13px;
	font-weight: 500;
	color: #9199a9;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item > ul {
	display: none;
	position: absolute;
	list-style-type: none;
	margin: 0;
	padding: 0;
	z-index: 1000;
	min-width: 250px;
	background-color: #ffffff;
	-webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
	-moz-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item > ul:before {
	width: 54px;
	height: 6px;
	background-color: #5e81ac;
	content: " ";
	-moz-border-radius-topleft: 2px;
	-webkit-border-top-left-radius: 2px;
	border-top-left-radius: 2px;
	-moz-border-radius-topright: 2px;
	-webkit-border-top-right-radius: 2px;
	border-top-right-radius: 2px;
	position: absolute;
	top: -5px;
	left: 0px;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item > ul > li {
	border-bottom: 1px solid rgba(195, 204, 221, 0.3);
	border-top: none;
	cursor: pointer;
	padding: 10px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	width: 100%;
	color: #4c566a;
	cursor: pointer;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item > ul > li i {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	flex-grow: 1;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item > ul > li:first-child {
	border-bottom: none;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item > ul > li:last-child {
	cursor: initial;
	border: none;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item > ul > li.profile-submenu-header {
	padding: 12px 18px 0 12px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	background-image: url("../../images/topbar/bg-profilemenu@2x.jpg");
	background-size: cover;
	background-repeat: no-repeat;
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item > ul > li.profile-submenu-header .performance {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item > ul > li.profile-submenu-header .performance span {
	margin-bottom: 12px;
	font-size: 11px;
	color: #c3ccdd;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item > ul > li.profile-submenu-header .profile {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
	text-align: right;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item > ul > li.profile-submenu-header .profile img {
	margin: 0;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item > ul > li.profile-submenu-header .profile h1 {
	color: #eceff4;
	margin: 0;
	font-size: 16px;
	font-weight: normal;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item > ul > li.profile-submenu-header .profile span {
	color: #c3ccdd;
	font-size: 13px;
	margin-bottom: 10px;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item > ul > li .icon {
	flex-grow: 0;
	font-size: 18px;
	margin-right: 10px;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item > ul > li .icon.icon-1 {
	color: #d08770;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item > ul > li .icon.icon-2 {
	color: #81a1c1;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item > ul > li .icon.icon-3 {
	color: #a3be8c;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item > ul > li.layout-submenu-footer {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item > ul > li.layout-submenu-footer button {
	padding: 6px 12px;
	border: none;
	background-color: transparent;
	cursor: pointer;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item > ul > li.layout-submenu-footer button.signout-button {
	color: #434c5e;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	background-color: #d8dee9;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item > ul > li.layout-submenu-footer button.buy-mirage-button {
	color: #5e81ac;
	text-decoration: underline;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item.active-topmenuitem > ul {
	display: block;
	left: auto;
	right: 0;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item.active-topmenuitem > ul::before {
	left: auto;
	right: 0;
}
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-mobile-wrapper {
	display: none;
}

@media (min-width: 1200px) {
	.layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu > li > ul {
		display: block !important;
	}
}
@media (max-width: 1199px) {
	.layout-wrapper .layout-topbar .layout-topbar-wrapper {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		height: auto;
		padding: 0;
		-webkit-box-shadow: 0 3px 5px 0 rgba(33, 35, 39, 0.15);
		-moz-box-shadow: 0 3px 5px 0 rgba(33, 35, 39, 0.15);
		box-shadow: 0 3px 5px 0 rgba(33, 35, 39, 0.15);
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left {
		padding: 0 1em;
		height: 75px;
		-webkit-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
		-moz-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
		box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .sidebar-menu-button,
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .megamenu-mobile-button,
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .topbar-menu-mobile-button {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		width: 40px;
		height: 40px;
		margin-left: 10px;
		-moz-border-radius: 50%;
		-webkit-border-radius: 50%;
		border-radius: 50%;
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .sidebar-menu-button i,
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .megamenu-mobile-button i,
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .topbar-menu-mobile-button i {
		color: #4c566a;
		font-size: 26px;
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .sidebar-menu-button:hover i,
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .megamenu-mobile-button:hover i,
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .topbar-menu-mobile-button:hover i {
		color: #5e81ac;
		font-size: 26px;
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-topbar-logo-wrapper {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		flex-grow: 1;
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-topbar-logo-wrapper .layout-topbar-logo {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-topbar-logo-wrapper .layout-topbar-logo .app-name {
		font-size: 22.5px;
		color: #4c566a;
		margin-left: 10px;
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-topbar-logo-wrapper .layout-topbar-logo img {
		height: 45px;
		width: 45px;
		border: 0 none;
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu-button {
		display: none;
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu {
		display: block;
		position: fixed;
		top: 75px;
		left: 0;
		width: 240px;
		height: calc(100% - 75px);
		transform: translate3d(-240px, 0px, 0px);
		-moz-transition: transform 0.15s;
		-o-transition: transform 0.15s;
		-webkit-transition: transform 0.15s;
		transition: transform 0.15s;
		margin-top: 0;
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu::before {
		display: none;
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu > li {
		margin-bottom: 10px;
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu > li > a {
		border: none;
		font-size: 14px;
		color: #4c566a;
		padding: 10px;
		width: 100%;
		-moz-border-radius: 6px;
		-webkit-border-radius: 6px;
		border-radius: 6px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		cursor: pointer;
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu > li > a i {
		display: block;
		-moz-transition: all 0.15s;
		-o-transition: all 0.15s;
		-webkit-transition: all 0.15s;
		transition: all 0.15s;
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu > li > a:hover {
		background-color: #f9fafb;
		-moz-transition: background-color 0.15s;
		-o-transition: background-color 0.15s;
		-webkit-transition: background-color 0.15s;
		transition: background-color 0.15s;
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu > li > ul {
		max-height: 0;
		overflow: hidden;
		background-color: #f9fafb;
		margin-top: 0;
		-moz-transition: 400ms cubic-bezier(0.86, 0, 0.07, 1);
		-o-transition: 400ms cubic-bezier(0.86, 0, 0.07, 1);
		-webkit-transition: 400ms cubic-bezier(0.86, 0, 0.07, 1);
		transition: 400ms cubic-bezier(0.86, 0, 0.07, 1);
		-moz-border-radius-bottomleft: 6px;
		-webkit-border-bottom-left-radius: 6px;
		border-bottom-left-radius: 6px;
		-moz-border-radius-bottomright: 6px;
		-webkit-border-bottom-right-radius: 6px;
		border-bottom-right-radius: 6px;
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu > li > ul > li {
		padding: 10px;
		padding-left: 20px;
		margin: 0;
		-webkit-box-pack: end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		-moz-border-radius: 6px;
		-webkit-border-radius: 6px;
		border-radius: 6px;
		flex-direction: row-reverse;
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu > li > ul > li i {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		flex-grow: 1;
		-webkit-box-pack: end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu > li > ul > li > span h5 {
		font-size: 14px;
		font-weight: normal;
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu > li > ul > li > span > span {
		display: none;
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu > li > ul > li:hover {
		background-color: #ffffff;
		-moz-transition: background-color 0.15s;
		-o-transition: background-color 0.15s;
		-webkit-transition: background-color 0.15s;
		transition: background-color 0.15s;
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu > li.active-topmenuitem > a {
		background-color: #5e81ac;
		-moz-transition: background-color 0.15s;
		-o-transition: background-color 0.15s;
		-webkit-transition: background-color 0.15s;
		transition: background-color 0.15s;
		color: #ffffff;
		border-radius: 0;
		-moz-border-radius-topleft: 4px;
		-webkit-border-top-left-radius: 4px;
		border-top-left-radius: 4px;
		-moz-border-radius-topright: 4px;
		-webkit-border-top-right-radius: 4px;
		border-top-right-radius: 4px;
		-webkit-box-shadow: 0 2px 4px 0 rgba(29, 29, 33, 0.24);
		-moz-box-shadow: 0 2px 4px 0 rgba(29, 29, 33, 0.24);
		box-shadow: 0 2px 4px 0 rgba(29, 29, 33, 0.24);
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu > li.active-topmenuitem > a i {
		-webkit-transform: rotate(-180deg);
		-moz-transform: rotate(-180deg);
		-o-transform: rotate(-180deg);
		-ms-transform: rotate(-180deg);
		transform: rotate(-180deg);
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu > li.active-topmenuitem > ul {
		max-height: 450px;
		-webkit-box-shadow: 3px 0 5px 0 rgba(33, 35, 39, 0.15);
		-moz-box-shadow: 3px 0 5px 0 rgba(33, 35, 39, 0.15);
		box-shadow: 3px 0 5px 0 rgba(33, 35, 39, 0.15);
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right {
		height: 150px;
		display: none;
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions {
		padding: 0;
		height: 50%;
		border-bottom: 1px solid rgba(145, 153, 169, 0.3);
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > a .topbar-icon {
		font-size: 26px;
		color: #4c566a;
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > a:hover i, .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > a:focus i {
		color: #5e81ac;
		-moz-transition: color 0.15s;
		-o-transition: color 0.15s;
		-webkit-transition: color 0.15s;
		transition: color 0.15s;
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > a.layout-rightpanel-button i {
		-moz-transition: transform 0.15s;
		-o-transition: transform 0.15s;
		-webkit-transition: transform 0.15s;
		transition: transform 0.15s;
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li::after {
		display: none;
		width: 100%;
		height: 6px;
		background-color: #5e81ac;
		content: " ";
		-moz-border-radius-topleft: 2px;
		-webkit-border-top-left-radius: 2px;
		border-top-left-radius: 2px;
		-moz-border-radius-topright: 2px;
		-webkit-border-top-right-radius: 2px;
		border-top-right-radius: 2px;
		position: absolute;
		bottom: -1px;
		left: 0px;
		-webkit-animation: fadeInDown 0.5s;
		/* Safari 4.0 - 8.0 */
		animation: fadeInDown 0.5s;
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul {
		position: fixed;
		top: 150px;
		left: 1em;
		right: 1em;
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul::before {
		display: none;
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li {
		padding: 18px;
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li.seach-item {
		padding: 10px;
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li.seach-item > ul {
		padding: 0;
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li.calendar {
		padding: 0;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li.deals {
		padding: 0;
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li.deals > ul {
		padding: 0;
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li.deals > ul > li {
		padding: 18px;
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.search-item > a {
		display: block;
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.search-item > .search-item-submenu {
		display: none;
		position: fixed;
		list-style-type: none;
		margin: 0;
		top: 150px;
		left: 1em;
		right: 1em;
		z-index: 1000;
		min-width: 250px;
		background-color: #ffffff;
		-webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
		-moz-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
		box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.search-item > .search-item-submenu span {
		width: 100%;
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.search-item > .search-item-submenu span input {
		width: 100%;
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.search-item::after {
		left: 14px;
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.search-item.active-topmenuitem .search-item-submenu {
		display: block;
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.active-topmenuitem::after {
		display: block;
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions .profile-item {
		display: none;
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-mobile-wrapper {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		list-style-type: none;
		margin: 0;
		padding: 0 1em;
		height: 50%;
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-mobile-wrapper > li {
		width: 100%;
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-mobile-wrapper > li > a {
		-webkit-box-pack: start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		padding: 0;
		margin: 0;
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-mobile-wrapper > li > a .profile-image-wrapper img {
		width: 48px;
		height: 48px;
		border: 0 none;
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-mobile-wrapper > li > ul {
		display: none;
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-mobile-wrapper > li > ul .menu-text > p {
		margin: 3px;
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-mobile-wrapper > li > ul > li:not(.layout-submenu-footer):hover {
		background-color: #f9fafb;
		-moz-transition: background-color 0.15s;
		-o-transition: background-color 0.15s;
		-webkit-transition: background-color 0.15s;
		transition: background-color 0.15s;
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-mobile-wrapper > li > ul > li:not(.layout-submenu-footer):hover i {
		color: #4c566a;
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-mobile-wrapper > li.active-topmenuitem > ul {
		display: block;
		left: auto;
		right: 0;
		width: 100%;
		top: 62px;
	}
	.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-mobile-wrapper > li.active-topmenuitem > ul::before {
		left: auto;
		right: 0;
		width: 100%;
	}
	.layout-wrapper.layout-topbar-mobile-active .layout-topbar .layout-topbar-wrapper .layout-topbar-left .topbar-menu-mobile-button {
		background-color: #4c566a;
		-moz-transition: background-color 0.15s;
		-o-transition: background-color 0.15s;
		-webkit-transition: background-color 0.15s;
		transition: background-color 0.15s;
	}
	.layout-wrapper.layout-topbar-mobile-active .layout-topbar .layout-topbar-wrapper .layout-topbar-left .topbar-menu-mobile-button i {
		color: #ffffff;
	}
	.layout-wrapper.layout-topbar-mobile-active .layout-topbar .layout-topbar-wrapper .layout-topbar-left .topbar-menu-mobile-button:hover {
		background-color: #5e81ac;
		-moz-transition: background-color 0.15s;
		-o-transition: background-color 0.15s;
		-webkit-transition: background-color 0.15s;
		transition: background-color 0.15s;
	}
	.layout-wrapper.layout-topbar-mobile-active .layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu {
		top: 225px;
		height: calc(100% - 225px);
	}
	.layout-wrapper.layout-topbar-mobile-active .layout-topbar .layout-topbar-wrapper .layout-topbar-right {
		display: block;
	}
	.layout-wrapper.layout-topbar-mobile-active .layout-rightpanel {
		top: 225px;
		height: calc(100% - 225px);
	}
	.layout-wrapper.layout-topbar-mobile-active .layout-sidebar {
		top: 225px;
	}
	.layout-wrapper.layout-topbar-mobile-active .layout-sidebar .layout-menu-container {
		height: calc(100% - 225px);
	}
	.layout-wrapper.layout-megamenu-mobile-active .layout-topbar .layout-topbar-wrapper .layout-topbar-left .megamenu-mobile-button {
		background-color: #4c566a;
		-moz-transition: background-color 0.15s;
		-o-transition: background-color 0.15s;
		-webkit-transition: background-color 0.15s;
		transition: background-color 0.15s;
	}
	.layout-wrapper.layout-megamenu-mobile-active .layout-topbar .layout-topbar-wrapper .layout-topbar-left .megamenu-mobile-button i {
		color: #ffffff;
	}
	.layout-wrapper.layout-megamenu-mobile-active .layout-topbar .layout-topbar-wrapper .layout-topbar-left .megamenu-mobile-button:hover {
		background-color: #5e81ac;
		-moz-transition: background-color 0.15s;
		-o-transition: background-color 0.15s;
		-webkit-transition: background-color 0.15s;
		transition: background-color 0.15s;
	}
	.layout-wrapper.layout-megamenu-mobile-active .layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu {
		transform: translate3d(0px, 0px, 0px);
	}
	.layout-wrapper.layout-megamenu-mobile-active .layout-main-mask {
		display: block;
	}
}
.layout-rightpanel {
	position: fixed;
	z-index: 999;
	right: 0;
	top: 75px;
	background-color: #ffffff;
	height: calc(100% - 75px);
	padding: 0;
	min-width: 285px;
	box-shadow: -3px 0 5px 0 rgba(33, 35, 39, 0.15);
	overflow: auto;
	transform: translate3d(400px, 0px, 0px);
	-moz-transition: transform 0.15s;
	-o-transition: transform 0.15s;
	-webkit-transition: transform 0.15s;
	transition: transform 0.15s;
}
.layout-rightpanel .right-panel-header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	background-color: #20252e;
	padding: 24px 18px;
}
.layout-rightpanel .right-panel-header .title span {
	font-size: 14px;
	padding: 2px 6px;
	color: #ffffff;
	border-radius: 4px;
	background-color: #5e81ac;
}
.layout-rightpanel .right-panel-header .title h1 {
	color: #ffffff;
	font-size: 16px;
	margin: 0;
	margin-top: 12px;
	font-weight: normal;
}
.layout-rightpanel .right-panel-header .rightpanel-exit-button:hover i {
	color: #9199a9;
	-moz-transition: color 0.15s;
	-o-transition: color 0.15s;
	-webkit-transition: color 0.15s;
	transition: color 0.15s;
}
.layout-rightpanel .right-panel-header .rightpanel-exit-button i {
	font-size: 20px;
	color: #eceff4;
}
.layout-rightpanel .right-panel-content {
	padding-bottom: 60px;
}
.layout-rightpanel .right-panel-content .right-panel-content-row {
	border-bottom: 1px solid rgba(145, 153, 169, 0.3);
	padding: 24px 0;
}
.layout-rightpanel .right-panel-content .right-panel-content-row:last-child {
	border-bottom: none;
}
.layout-rightpanel .right-panel-content .right-panel-content-row h1 {
	font-size: 18px;
	margin: 0;
	margin-bottom: 18px;
	color: #4c566a;
	font-weight: normal;
}
.layout-rightpanel .right-panel-content .right-panel-content-row .tasks {
	padding: 0;
}
.layout-rightpanel .right-panel-content .right-panel-content-row .tasks .tasks-header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 18px;
	margin-bottom: 18px;
}
.layout-rightpanel .right-panel-content .right-panel-content-row .tasks .tasks-header .title h1 {
	margin-bottom: 0px;
}
.layout-rightpanel .right-panel-content .right-panel-content-row .tasks .tasks-header .tasks-info span {
	font-size: 12px;
	color: #4c566a;
}
.layout-rightpanel .right-panel-content .right-panel-content-row .tasks .tasks-header .tasks-info span.highlighted {
	color: #4c566a;
	font-weight: 500;
}
.layout-rightpanel .right-panel-content .right-panel-content-row .tasks .tasks-list {
	list-style: none;
	padding: 0;
}
.layout-rightpanel .right-panel-content .right-panel-content-row .tasks .tasks-list .tasks-list-item {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 12px 18px;
}
.layout-rightpanel .right-panel-content .right-panel-content-row .tasks .tasks-list .tasks-list-item:nth-child(even) {
	background-color: #f9fafb;
}
.layout-rightpanel .right-panel-content .right-panel-content-row .tasks .tasks-list .tasks-list-item .checkbox {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
}
.layout-rightpanel .right-panel-content .right-panel-content-row .tasks .tasks-list .tasks-list-item .checkbox p {
	margin: 0;
	color: #4c566a;
	margin-left: 10px;
}
.layout-rightpanel .right-panel-content .right-panel-content-row .tasks .tasks-list .tasks-list-item .tasks-day .time {
	border-radius: 2px;
	background-color: #88c0d0;
	color: #ffffff;
	font-size: 12px;
	padding: 3px 10px;
	text-align: center;
	min-width: 55px;
}
.layout-rightpanel .right-panel-content .right-panel-content-row .tasks .tasks-list .tasks-list-item .tasks-day .time.later {
	background-color: #9199a9;
}
.layout-rightpanel .right-panel-content .right-panel-content-row .calendar {
	padding: 18px;
}
.layout-rightpanel .right-panel-content .right-panel-content-row .calendar .p-datepicker-inline {
	background-color: #ffffff;
	border: none;
}
.layout-rightpanel .right-panel-content .right-panel-content-row .calendar .p-datepicker-inline .p-datepicker-header {
	font-size: 13px;
	color: #4c566a;
}
.layout-rightpanel .right-panel-content .right-panel-content-row .calendar .p-datepicker-inline .p-datepicker-header .p-datepicker-title span {
	color: #4c566a;
}
.layout-rightpanel .right-panel-content .right-panel-content-row .calendar .p-datepicker-inline .p-datepicker-calendar th {
	color: #9199a9;
}
.layout-rightpanel .right-panel-content .right-panel-content-row .calendar .p-datepicker-inline .p-datepicker-calendar td a {
	color: #4c566a;
}
.layout-rightpanel .right-panel-content .right-panel-content-row .weather {
	padding: 0;
}
.layout-rightpanel .right-panel-content .right-panel-content-row .weather h1 {
	padding-left: 18px;
}
.layout-rightpanel .right-panel-content .right-panel-content-row .weather .weather-list {
	list-style: none;
	padding: 0;
}
.layout-rightpanel .right-panel-content .right-panel-content-row .weather .weather-list .weather-list-item {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px 18px;
}
.layout-rightpanel .right-panel-content .right-panel-content-row .weather .weather-list .weather-list-item:nth-child(even) {
	background-color: #f9fafb;
}
.layout-rightpanel .right-panel-content .right-panel-content-row .weather .weather-list .weather-list-item .time-location span {
	font-size: 12px;
	color: #9199a9;
}
.layout-rightpanel .right-panel-content .right-panel-content-row .weather .weather-list .weather-list-item .time-location p {
	font-weight: 500;
	margin: 0;
	margin-top: 6px;
	color: #4c566a;
}
.layout-rightpanel .right-panel-content .right-panel-content-row .weather .weather-list .weather-list-item .weather-info {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	width: 75px;
	justify-content: flex-start;
}
.layout-rightpanel .right-panel-content .right-panel-content-row .weather .weather-list .weather-list-item .weather-info .weather-icon {
	height: 36px;
	width: 36px;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
}
.layout-rightpanel .right-panel-content .right-panel-content-row .weather .weather-list .weather-list-item .weather-info .weather-icon.icon-1 {
	background-color: #ebcb8b;
}
.layout-rightpanel .right-panel-content .right-panel-content-row .weather .weather-list .weather-list-item .weather-info .weather-icon.icon-2 {
	background-color: #88c0d0;
}
.layout-rightpanel .right-panel-content .right-panel-content-row .weather .weather-list .weather-list-item .weather-info .weather-icon.icon-3 {
	background-color: #5e81ac;
}
.layout-rightpanel .right-panel-content .right-panel-content-row .weather .weather-list .weather-list-item .weather-info .weather-icon.icon-4 {
	background-color: #d08770;
}
.layout-rightpanel .right-panel-content .right-panel-content-row .weather .weather-list .weather-list-item .weather-info .weather-value {
	font-size: 18px;
	color: #4c566a;
}

.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-rightpanel-button i {
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	-ms-transform: rotate(0deg);
	transform: rotate(0deg);
	-moz-transition: transform 0.15s;
	-o-transition: transform 0.15s;
	-webkit-transition: transform 0.15s;
	transition: transform 0.15s;
}
.layout-wrapper.layout-rightpanel-active .layout-topbar .layout-topbar-wrapper .layout-rightpanel-button i {
	-webkit-transform: rotate(-180deg);
	-moz-transform: rotate(-180deg);
	-o-transform: rotate(-180deg);
	-ms-transform: rotate(-180deg);
	transform: rotate(-180deg);
	-moz-transition: transform 0.15s;
	-o-transition: transform 0.15s;
	-webkit-transition: transform 0.15s;
	transition: transform 0.15s;
}
.layout-wrapper.layout-rightpanel-active .layout-rightpanel {
	transform: translate3d(0px, 0px, 0px);
}

@media (max-width: 1199px) {
	.layout-rightpanel-active .layout-rightpanel {
		transform: translate3d(0px, 0px, 0px);
	}
	.layout-rightpanel-active .layout-main-mask {
		display: block;
	}
}
.layout-breadcrumb {
	background-color: #d8dee9;
	padding: 10px 30px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.layout-breadcrumb .breadcrumb .route-bar-breadcrumb {
	list-style: none;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: space-around;
	align-items: center;
	padding: 0;
}
.layout-breadcrumb .breadcrumb .route-bar-breadcrumb li {
	padding: 0 5px;
	font-size: 13px;
	color: #9199a9;
}
.layout-breadcrumb .breadcrumb .route-bar-breadcrumb li:first-child {
	padding-left: 0;
}
.layout-breadcrumb .breadcrumb .route-bar-breadcrumb li i {
	font-weight: bold;
	font-size: 10px;
}

@media (max-width: 1199px) {
	.layout-breadcrumb {
		flex-flow: column;
		align-items: center;
		justify-content: center;
		padding: 10px 0;
	}
	.layout-breadcrumb .breadcrumb {
		width: 100%;
		border-bottom: 1px solid rgba(145, 153, 169, 0.3);
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		justify-content: center;
		padding: 0 30px;
		padding-bottom: 10px;
	}
	.layout-breadcrumb .notification {
		padding: 0 30px;
		padding-top: 10px;
		text-align: center;
	}
}
.layout-content {
	flex: 1 0 auto;
	padding: 2em;
}

.layout-dashboard .list-effect {
	height: 80px;
	width: 100%;
	background-color: transparent;
	background-image: -webkit-gradient(linear, left bottom, left top, from(#ffffff), to(transparent));
	background-image: -webkit-linear-gradient(to top, #ffffff, transparent);
	background-image: -moz-linear-gradient(to top, #ffffff, transparent);
	background-image: -ms-linear-gradient(to top, #ffffff, transparent);
	background-image: -o-linear-gradient(to top, #ffffff, transparent);
	background-image: linear-gradient(to top, #ffffff, transparent);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="transparent");
	position: absolute;
	bottom: 0;
	left: 0;
}
.layout-dashboard .map {
	padding: 0;
}
.layout-dashboard .map .map-image {
	position: relative;
}
.layout-dashboard .map .map-image img {
	width: 100%;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}
.layout-dashboard .map .map-info {
	padding: 12px;
}
.layout-dashboard .map .map-info .map-info-header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
}
.layout-dashboard .map .map-info .map-info-header h6 {
	color: #4c566a;
	font-size: 14px;
	margin: 0;
}
.layout-dashboard .map .map-info .map-info-header .location-change {
	text-align: right;
	cursor: pointer;
}
.layout-dashboard .map .map-info .map-info-header .location-change span, .layout-dashboard .map .map-info .map-info-header .location-change i {
	color: #9199a9;
	font-size: 12px;
}
.layout-dashboard .map .map-info .map-info-header .location-change:hover span, .layout-dashboard .map .map-info .map-info-header .location-change:hover i {
	color: #4c566a;
	-moz-transition: color 0.15s;
	-o-transition: color 0.15s;
	-webkit-transition: color 0.15s;
	transition: color 0.15s;
}
.layout-dashboard .map .map-info .map-info-bar {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
}
.layout-dashboard .map .map-info .map-info-bar .info {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-top: 1em;
	cursor: pointer;
}
.layout-dashboard .map .map-info .map-info-bar .info:hover span {
	color: #9199a9;
	-moz-transition: color 0.15s;
	-o-transition: color 0.15s;
	-webkit-transition: color 0.15s;
	transition: color 0.15s;
}
.layout-dashboard .map .map-info .map-info-bar .info:last-child {
	border-right: none;
}
.layout-dashboard .map .map-info .map-info-bar .info .location-point {
	height: 18px;
	width: 18px;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	cursor: pointer;
	margin-right: 5px;
}
.layout-dashboard .map .map-info .map-info-bar .info .location-point.point-1 {
	background-color: #bf616a;
}
.layout-dashboard .map .map-info .map-info-bar .info .location-point.point-2 {
	background-color: #b48ead;
}
.layout-dashboard .map .map-info .map-info-bar .info .location-point.point-3 {
	background-color: #d08770;
}
.layout-dashboard .map .map-info .map-info-bar .info a {
	text-decoration: underline;
	color: #5e81ac;
}

.login-body .login-content {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.login-body .login-content .night-switch {
	position: absolute;
	right: 20px;
	top: 10px;
}
.login-body .login-content .night-switch .p-button {
	background-color: transparent;
	border-color: transparent;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	width: 60px;
	height: 60px;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	position: relative;
	outline: none;
	z-index: 101;
}
.login-body .login-content .night-switch .p-button .pi {
	width: 32px;
	height: 32px;
	font-size: 32px;
	font-weight: bold;
	position: absolute;
	left: 50%;
	top: 50%;
	margin-top: -16px;
	margin-left: -16px;
}
.login-body .login-content .night-switch .p-button .p-button-label {
	display: none;
}
.login-body .login-content .night-switch .p-button:enabled:hover {
	border: none;
	opacity: 0.7;
	-moz-transition: opacity 0.15s;
	-o-transition: opacity 0.15s;
	-webkit-transition: opacity 0.15s;
	transition: opacity 0.15s;
}
.login-body .login-content .login-panel {
	width: 40%;
	height: 100vh;
	text-align: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.login-body .login-content .login-panel .login-panel-content {
	z-index: 100;
	width: 80%;
	padding: 0 6%;
	text-align: center;
}
.login-body .login-content .login-panel .login-panel-content .logo {
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.login-body .login-content .login-panel .login-panel-content .logo img {
	width: 80px;
}
.login-body .login-content .login-panel .login-panel-content h1 {
	font-size: 42px;
	font-weight: 100;
	letter-spacing: 1px;
	margin: 20px 0;
	color: #ffffff;
}
.login-body .login-content .login-panel .login-panel-content p {
	margin-top: 10px;
	font-size: 16px;
	color: #d8dee9;
}
.login-body .login-content .login-panel .login-panel-content .forms {
	margin-top: 30px;
	text-align: left;
}
.login-body .login-content .login-panel .login-panel-content .forms .login-input-wrapper {
	position: relative;
	margin-bottom: 24px;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	text-align: left;
}
.login-body .login-content .login-panel .login-panel-content .forms .login-input-wrapper input {
	width: 100%;
	padding-right: 16px;
	background-color: transparent;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	border: solid 1px #c3ccdd;
	color: #d8dee9;
	margin-top: 6px;
}
.login-body .login-content .login-panel .login-panel-content .forms .login-input-wrapper i {
	position: absolute;
	color: #d8dee9;
	font-size: 16px;
	top: 50%;
	right: 0.429em;
	margin-top: 3px;
}
.login-body .login-content .login-panel .login-panel-content .forms .p-checkbox.p-component {
	margin-left: -6px;
}
.login-body .login-content .login-panel .login-panel-content .forms label {
	color: #c3ccdd;
}
.login-body .login-content .login-panel .login-panel-content .buttons {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
.login-body .login-content .login-panel .login-panel-content .buttons .p-button {
	margin-top: 15px;
	background-color: #bd69ae;
	border: none;
	min-width: 100px;
}
.login-body .login-content .login-panel .login-panel-content .buttons a.secondary-button {
	background-color: transparent;
	border: none;
	color: #c3ccdd;
	padding: 2px 0;
	text-decoration: underline;
}
.login-body .login-content .desert {
	width: 100%;
	height: 30vh;
	background-repeat: no-repeat;
	background-size: cover;
	position: absolute;
	bottom: 0;
	background-image: url("../../images/pages/asset-desert.svg");
}
.login-body.layout-light {
	background-color: #eceff4;
}
.login-body.layout-light .login-content .night-switch .p-button {
	color: #5a657c;
	border: none;
}
.login-body.layout-light .login-content .night-switch .p-button.light-switch {
	display: none;
}
.login-body.layout-light .login-content .night-switch .p-button.dark-switch {
	display: block;
}
.login-body.layout-light .login-content .login-panel {
	background-color: rgba(216, 222, 233, 0.3);
}
.login-body.layout-light .login-content .login-panel .login-panel-content h1 {
	color: #2e3440;
}
.login-body.layout-light .login-content .login-panel .login-panel-content p {
	color: #5a657c;
}
.login-body.layout-light .login-content .login-panel .login-panel-content .forms .login-input-wrapper input {
	border: solid 1px #cccccc;
	background-color: #ffffff;
	color: #9199a9;
}
.login-body.layout-light .login-content .login-panel .login-panel-content .forms .login-input-wrapper i {
	color: #9199a9;
}
.login-body.layout-light .login-content .login-panel .login-panel-content .forms label {
	color: #4c566a;
}
.login-body.layout-light .login-content .login-panel .login-panel-content .buttons button, .login-body.layout-light .login-content .login-panel .login-panel-content .buttons a {
	background-color: #5e81ac;
}
.login-body.layout-light .login-content .login-panel .login-panel-content .buttons button.secondary-button, .login-body.layout-light .login-content .login-panel .login-panel-content .buttons a.secondary-button {
	background-color: transparent;
	color: #9199a9;
}
.login-body.layout-light .login-content .login-panel .login-panel-content .buttons button.secondary-button:enabled:hover, .login-body.layout-light .login-content .login-panel .login-panel-content .buttons a.secondary-button:enabled:hover {
	background-color: transparent;
	color: #636975;
	-moz-transition: color 0.15s;
	-o-transition: color 0.15s;
	-webkit-transition: color 0.15s;
	transition: color 0.15s;
}
.login-body.layout-light .login-content .login-panel .login-panel-content .buttons button:enabled:hover, .login-body.layout-light .login-content .login-panel .login-panel-content .buttons a:enabled:hover {
	background-color: #76a1d6;
	-moz-transition: background-color 0.15s;
	-o-transition: background-color 0.15s;
	-webkit-transition: background-color 0.15s;
	transition: background-color 0.15s;
}
.login-body.layout-light .login-content .desert {
	display: block;
}
.login-body.layout-dark {
	background-image: url("../../images/pages/asset-login-bg@3x.jpg");
	background-repeat: no-repeat;
	background-size: cover;
}
.login-body.layout-dark .login-content .night-switch .p-button {
	color: #ffffff;
}
.login-body.layout-dark .login-content .night-switch .p-button.dark-switch {
	display: none;
}
.login-body.layout-dark .login-content .night-switch .p-button.light-switch {
	display: block;
}
.login-body.layout-dark .login-content .login-panel {
	background-color: rgba(32, 37, 46, 0.3);
}
.login-body.layout-dark .login-content .login-panel .login-panel-content h1 {
	color: #ffffff;
}
.login-body.layout-dark .login-content .login-panel .login-panel-content p {
	color: #d8dee9;
}
.login-body.layout-dark .login-content .login-panel .login-panel-content .forms .login-input-wrapper input {
	border: solid 1px #c3ccdd;
	color: #d8dee9;
}
.login-body.layout-dark .login-content .login-panel .login-panel-content .forms .login-input-wrapper i {
	color: #d8dee9;
}
.login-body.layout-dark .login-content .login-panel .login-panel-content .forms label {
	color: #c3ccdd;
}
.login-body.layout-dark .login-content .login-panel .login-panel-content .buttons button, .login-body.layout-dark .login-content .login-panel .login-panel-content .buttons a {
	background-color: #bd69ae;
}
.login-body.layout-dark .login-content .login-panel .login-panel-content .buttons button.secondary-button, .login-body.layout-dark .login-content .login-panel .login-panel-content .buttons a.secondary-button {
	background-color: transparent;
	color: #c3ccdd;
}
.login-body.layout-dark .login-content .login-panel .login-panel-content .buttons button.secondary-button:enabled:hover, .login-body.layout-dark .login-content .login-panel .login-panel-content .buttons a.secondary-button:enabled:hover {
	background-color: transparent;
	color: #ffffff;
	-moz-transition: color 0.15s;
	-o-transition: color 0.15s;
	-webkit-transition: color 0.15s;
	transition: color 0.15s;
}
.login-body.layout-dark .login-content .login-panel .login-panel-content .buttons button:enabled:hover, .login-body.layout-dark .login-content .login-panel .login-panel-content .buttons a:enabled:hover {
	background-color: #e27dd0;
	-moz-transition: background-color 0.15s;
	-o-transition: background-color 0.15s;
	-webkit-transition: background-color 0.15s;
	transition: background-color 0.15s;
}
.login-body.layout-dark .login-content .desert {
	display: none;
}

@media (max-width: 740px) {
	.login-body .login-content .login-panel {
		width: 100%;
	}
	.login-body .login-content .login-panel .login-panel-content {
		width: 85%;
		padding: 20px 15px 50px;
	}
	.login-body .login-content .login-panel .login-panel-content h1 {
		font-size: 18px;
	}
	.login-body .login-content .login-panel .login-panel-content P {
		font-size: 16px;
	}
	.login-body .login-content .desert {
		background-position: right;
	}
}
.exception-body::after {
	content: " ";
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	opacity: 0.7;
}
.exception-body .exception-content {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	position: relative;
}
.exception-body .exception-content .moon {
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: flex-end;
	align-items: flex-start;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
}
.exception-body .exception-content .exception-panel {
	height: 100vh;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid;
	border-top: none;
	width: 50%;
}
.exception-body .exception-content .exception-panel .exception-panel-content {
	z-index: 100;
	height: 50%;
	width: 50%;
}
.exception-body .exception-content .exception-panel .exception-panel-content h1 {
	font-size: 42px;
	font-weight: 100;
	letter-spacing: 1px;
	margin: 20px 0;
}
.exception-body .exception-content .exception-panel .exception-panel-content .seperator {
	width: 99px;
	height: 1px;
	background-color: #9199a9;
}
.exception-body .exception-content .exception-panel .exception-panel-content .tag {
	font-size: 14px;
	font-weight: 500;
	border-radius: 4px;
	padding: 3px 6px;
	margin-bottom: 20px;
}
.exception-body .exception-content .exception-panel .exception-panel-content p {
	margin-top: 25px;
	font-size: 16px;
}
.exception-body .exception-content .exception-panel .exception-panel-content button {
	margin-top: 30px;
	background-color: #5e81ac;
	border: none;
}
.exception-body .exception-content .desert {
	width: 100%;
	height: 30vh;
	background-repeat: no-repeat;
	background-size: cover;
	position: absolute;
	bottom: 0;
	left: 0;
}
.exception-body.notfound {
	background-color: #20252e;
}
.exception-body.notfound::after {
	background-color: #444c5b -9%;
	background-image: -webkit-gradient(linear, left top, left bottom, from(#444c5b -9%), to(#20252e));
	background-image: -webkit-linear-gradient(to bottom, #444c5b -9%, #20252e);
	background-image: -moz-linear-gradient(to bottom, #444c5b -9%, #20252e);
	background-image: -ms-linear-gradient(to bottom, #444c5b -9%, #20252e);
	background-image: -o-linear-gradient(to bottom, #444c5b -9%, #20252e);
	background-image: linear-gradient(to bottom, #444c5b -9%, #20252e);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#444c5b -9%", endColorstr="#20252e");
}
.exception-body.notfound .exception-content .exception-panel {
	border-color: rgba(76, 86, 106, 0.3);
	background-color: rgba(0, 0, 0, 0.3);
}
.exception-body.notfound .exception-content .exception-panel .exception-panel-content h1 {
	color: #9199a9;
}
.exception-body.notfound .exception-content .exception-panel .exception-panel-content .tag {
	background-color: #eceff4;
}
.exception-body.notfound .exception-content .exception-panel .exception-panel-content p {
	color: #c3ccdd;
}
.exception-body.notfound .exception-content .desert {
	background-image: url("../../images/pages/asset-desert-night.svg");
}
.exception-body.error {
	background-color: #d07b70;
}
.exception-body.error::after {
	background-color: #5b4444 -9%;
	background-image: -webkit-gradient(linear, left top, left bottom, from(#5b4444 -9%), to(#20262e));
	background-image: -webkit-linear-gradient(to bottom, #5b4444 -9%, #20262e);
	background-image: -moz-linear-gradient(to bottom, #5b4444 -9%, #20262e);
	background-image: -ms-linear-gradient(to bottom, #5b4444 -9%, #20262e);
	background-image: -o-linear-gradient(to bottom, #5b4444 -9%, #20262e);
	background-image: linear-gradient(to bottom, #5b4444 -9%, #20262e);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#5b4444 -9%", endColorstr="#20262e");
}
.exception-body.error .exception-content .exception-panel {
	border-color: rgba(195, 204, 221, 0.3);
	background-color: rgba(255, 255, 255, 0.3);
}
.exception-body.error .exception-content .exception-panel .exception-panel-content h1 {
	color: #c3ccdd;
}
.exception-body.error .exception-content .exception-panel .exception-panel-content .tag {
	background-color: #bf616a;
	color: #ffffff;
}
.exception-body.error .exception-content .exception-panel .exception-panel-content p {
	color: #c3ccdd;
}
.exception-body.error .exception-content .desert {
	background-image: url("../../images/pages/asset-desert-error.svg");
}
.exception-body.access {
	background-color: #d08770;
}
.exception-body.access::after {
	background-color: #5b5544 -9%;
	background-image: -webkit-gradient(linear, left top, left bottom, from(#5b5544 -9%), to(#20262e));
	background-image: -webkit-linear-gradient(to bottom, #5b5544 -9%, #20262e);
	background-image: -moz-linear-gradient(to bottom, #5b5544 -9%, #20262e);
	background-image: -ms-linear-gradient(to bottom, #5b5544 -9%, #20262e);
	background-image: -o-linear-gradient(to bottom, #5b5544 -9%, #20262e);
	background-image: linear-gradient(to bottom, #5b5544 -9%, #20262e);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#5b5544 -9%", endColorstr="#20262e");
}
.exception-body.access .exception-content .exception-panel {
	border-color: rgba(76, 86, 106, 0.3);
	background-color: rgba(255, 255, 255, 0.3);
}
.exception-body.access .exception-content .exception-panel .exception-panel-content h1 {
	color: #c3ccdd;
}
.exception-body.access .exception-content .exception-panel .exception-panel-content .tag {
	background-color: #ebcb8b;
}
.exception-body.access .exception-content .exception-panel .exception-panel-content p {
	color: #c3ccdd;
}
.exception-body.access .exception-content .desert {
	background-image: url("../../images/pages/asset-desert-night-orange.svg");
}

@media (max-width: 740px) {
	.exception-body .exception-content .exception-panel {
		width: 100%;
	}
	.exception-body .exception-content .exception-panel .exception-panel-content {
		width: 90%;
	}
	.exception-body .exception-content .desert {
		background-position: right;
		height: 50vh;
	}
}
.landing-body {
	display: flex;
	flex-flow: column;
	background-color: #ffffff;
}
.landing-body h1 {
	font-size: 42px;
	letter-spacing: 1px;
	color: #20252e;
	margin: 0;
	font-weight: 100;
}
.landing-body .landing-nav-menu {
	width: 100%;
	height: 72px;
	background-color: #eceff4;
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: 1000;
	padding: 0 10%;
}
.landing-body .landing-nav-menu .logo {
	width: 155px;
}
.landing-body .landing-nav-menu .logo a {
	display: flex;
	align-items: center;
}
.landing-body .landing-nav-menu .logo .app-name {
	font-size: 22.5px;
	color: #2e3440;
	margin-left: 10px;
}
.landing-body .landing-nav-menu .logo img {
	width: 50px;
}
.landing-body .landing-nav-menu .nav-menu-wrapper ul {
	display: flex;
	align-items: center;
	justify-content: space-around;
	list-style: none;
	padding: 0;
}
.landing-body .landing-nav-menu .nav-menu-wrapper ul li {
	padding: 15px;
}
.landing-body .landing-nav-menu .nav-menu-wrapper ul li a {
	color: #5a657c;
	font-size: 16px;
	font-weight: 500;
}
.landing-body .landing-nav-menu .nav-menu-wrapper ul li a:hover {
	color: #8292b3;
	-moz-transition: color 0.15s;
	-o-transition: color 0.15s;
	-webkit-transition: color 0.15s;
	transition: color 0.15s;
}
.landing-body .landing-nav-menu .buy-button {
	width: 155px;
}
.landing-body .landing-nav-menu .buy-button button {
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	background-color: #d08770;
	padding: 6px 12px;
	border: none;
	padding: 15px 20px;
}
.landing-body .landing-nav-menu .buy-button button:hover {
	background-color: #ea987c;
	-moz-transition: background-color 0.15s;
	-o-transition: background-color 0.15s;
	-webkit-transition: background-color 0.15s;
	transition: background-color 0.15s;
}
.landing-body .landing-nav-menu .buy-button button a {
	color: #ffffff;
	font-size: 14px;
}
.landing-body .landing-nav-menu .menu-button {
	display: none;
}
.landing-body .landing-nav-menu .menu-button .landing-menu-button {
	width: 36px;
	height: 36px;
	justify-content: center;
	align-items: center;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	color: #2e3440;
	font-size: 22px;
}
.landing-body .landing-nav-menu .menu-button .landing-menu-button .landing-menu-button-icon {
	background: url("../../images/landing/icon-mainmenu-black.svg");
	background-repeat: no-repeat;
	background-size: 18px 23px;
	width: 18px;
	height: 24px;
	text-align: center;
}
.landing-body .landing-header-wrapper {
	height: 600px;
	position: relative;
	background-color: #20252e;
}
.landing-body .landing-header-wrapper .sun {
	display: flex;
	justify-content: flex-end;
	height: 100%;
	align-items: flex-start;
	position: absolute;
	top: 0px;
	z-index: 999;
	width: 100%;
}
.landing-body .landing-header-wrapper .sun img {
	height: 100%;
}
.landing-body .landing-header-wrapper .landing-header-content {
	height: 100%;
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	top: 0;
	position: absolute;
	width: 100%;
	z-index: 999;
}
.landing-body .landing-header-wrapper .landing-header-content h1 {
	color: #c3ccdd;
}
.landing-body .landing-header-wrapper .landing-header-content p {
	font-size: 24px;
	color: #c3ccdd;
	margin: 1.5rem 0 1.5rem 0;
}
.landing-body .landing-header-wrapper .landing-header-content .video-button button {
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	background-color: #d08770;
	padding: 15px 20px;
	color: #ffffff;
	font-size: 14px;
	border: none;
	cursor: pointer;
}
.landing-body .landing-header-wrapper .landing-header-content .video-button button:hover {
	background-color: #ea987c;
	-moz-transition: background-color 0.15s;
	-o-transition: background-color 0.15s;
	-webkit-transition: background-color 0.15s;
	transition: background-color 0.15s;
}
.landing-body .landing-header-wrapper .tree {
	display: flex;
	justify-content: flex-start;
	align-items: flex-end;
	height: 100%;
}
.landing-body .section .section-title {
	text-align: center;
}
.landing-body .section .section-title p {
	font-size: 16px;
	color: #4c566a;
	margin: 0;
	margin-top: 8px;
}
.landing-body .section#meet-mirage {
	background-color: #eceff4;
	padding: 24px 10% 170px;
	position: relative;
}
.landing-body .section#meet-mirage .feature-cards {
	display: flex;
	justify-content: space-around;
	align-items: center;
	padding-top: 65px;
	flex-flow: wrap;
}
.landing-body .section#meet-mirage .feature-cards .feature-card {
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	background-color: #ffffff;
	text-align: center;
	margin-bottom: 32px;
}
.landing-body .section#meet-mirage .feature-cards .feature-card .feature-card-img {
	margin-bottom: 16px;
}
.landing-body .section#meet-mirage .feature-cards .feature-card .feature-card-img img {
	width: 250px;
	height: auto;
}
.landing-body .section#meet-mirage .feature-cards .feature-card .feature-card-title {
	color: #20252e;
	font-size: 16px;
	font-weight: 500;
}
.landing-body .section#meet-mirage .feature-cards .feature-card .feature-card-content {
	color: #5a657c;
	padding: 12px 18px 25px;
	width: 300px;
}
.landing-body .section#meet-mirage .buy-mirage-promo-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	bottom: -50%;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 998;
}
.landing-body .section#meet-mirage .buy-mirage-promo-wrapper .buy-mirage-promo {
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	background-color: #2e3440;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 18px 40px;
	flex-flow: wrap;
}
.landing-body .section#meet-mirage .buy-mirage-promo-wrapper .buy-mirage-promo .promo-content {
	width: 70%;
}
.landing-body .section#meet-mirage .buy-mirage-promo-wrapper .buy-mirage-promo .promo-content h1 {
	color: #c3ccdd;
}
.landing-body .section#meet-mirage .buy-mirage-promo-wrapper .buy-mirage-promo .promo-content p {
	color: #ffffff;
	font-size: 16px;
}
.landing-body .section#meet-mirage .buy-mirage-promo-wrapper .buy-mirage-promo .promo-button button {
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	background-color: #d08770;
	padding: 15px 20px;
	color: #ffffff;
	font-size: 14px;
	border: none;
	cursor: pointer;
}
.landing-body .section#meet-mirage .buy-mirage-promo-wrapper .buy-mirage-promo .promo-button button:hover {
	background-color: #ea987c;
	-moz-transition: background-color 0.15s;
	-o-transition: background-color 0.15s;
	-webkit-transition: background-color 0.15s;
	transition: background-color 0.15s;
}
.landing-body .section#features {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 140px 10% 60px;
	position: relative;
	flex-flow: wrap;
}
.landing-body .section#features .features-img {
	z-index: 998;
	margin-bottom: 24px;
}
.landing-body .section#features .features-img img {
	height: 400px;
	width: auto;
}
.landing-body .section#features .features-rows {
	display: flex;
	flex-flow: column;
	z-index: 998;
}
.landing-body .section#features .features-rows .features-row {
	display: flex;
	align-items: center;
	justify-content: space-around;
	z-index: 998;
	padding: 24px;
}
.landing-body .section#features .features-rows .features-row .features-row-icon {
	background-color: #ffffff;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 70px;
	height: 70px;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	border-radius: 50%;
}
.landing-body .section#features .features-rows .features-row .features-row-icon .feature {
	width: 32px;
	height: 32px;
	text-align: center;
}
.landing-body .section#features .features-rows .features-row .features-row-icon .feature.first {
	background: url("../../images/landing/icon-feature-1.svg");
	background-repeat: no-repeat;
	background-size: 32px 32px;
}
.landing-body .section#features .features-rows .features-row .features-row-icon .feature.second {
	background: url("../../images/landing/icon-feature-2.svg");
	background-repeat: no-repeat;
	background-size: 32px 32px;
}
.landing-body .section#features .features-rows .features-row .features-row-icon .feature.third {
	background: url("../../images/landing/icon-feature-3.svg");
	background-repeat: no-repeat;
	background-size: 32px 32px;
}
.landing-body .section#features .features-rows .features-row .features-row-content {
	margin-left: 24px;
	width: 80%;
}
.landing-body .section#features .features-rows .features-row .features-row-content h3 {
	color: #20252e;
	font-size: 16px;
	font-weight: 500;
	margin: 0;
}
.landing-body .section#features .features-rows .features-row .features-row-content span {
	color: #5a657c;
	font-size: 14px;
}
.landing-body .section#features .features-rows .features-row:hover {
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	box-shadow: 0 1px 30px 3px rgba(0, 0, 0, 0.07);
	background-color: #ffffff;
}
.landing-body .section#features .features-rows .features-row:hover .features-row-icon .feature {
	width: 70px;
	height: 70px;
}
.landing-body .section#features .features-rows .features-row:hover .features-row-icon .feature.first {
	background: url("../../images/landing/icon-feature-1-hover.svg");
	background-repeat: no-repeat;
	background-size: 70px 70px;
}
.landing-body .section#features .features-rows .features-row:hover .features-row-icon .feature.second {
	background: url("../../images/landing/icon-feature-2-hover.svg");
	background-repeat: no-repeat;
	background-size: 70px 70px;
}
.landing-body .section#features .features-rows .features-row:hover .features-row-icon .feature.third {
	background: url("../../images/landing/icon-feature-3-hover.svg");
	background-repeat: no-repeat;
	background-size: 70px 70px;
}
.landing-body .section#features .effect-feature {
	position: absolute;
	bottom: 0px;
	left: 0;
	width: 100%;
	max-height: 350px;
	z-index: 997;
}
.landing-body .section#pricing {
	background-color: #eceff4;
	padding: 36px 10% 60px;
}
.landing-body .section#pricing .pricing-cards {
	display: flex;
	justify-content: space-around;
	align-items: center;
	padding-top: 35px;
	flex-flow: wrap;
}
.landing-body .section#pricing .pricing-cards .pricing-card {
	position: relative;
	display: flex;
	flex-flow: column;
	-moz-border-radius: 4.4px;
	-webkit-border-radius: 4.4px;
	border-radius: 4.4px;
	box-shadow: 0 1px 30px 3px rgba(0, 0, 0, 0.07);
	background-color: #ffffff;
	padding: 40px 60px 23px;
	text-align: center;
	min-height: 400px;
	margin-bottom: 36px;
}
.landing-body .section#pricing .pricing-cards .pricing-card .pricing-card-title h2 {
	color: #2e3440;
	font-size: 20px;
	margin: 0;
}
.landing-body .section#pricing .pricing-cards .pricing-card .pricing-card-title p {
	color: #5a657c;
	font-size: 18px;
	margin: 0px;
}
.landing-body .section#pricing .pricing-cards .pricing-card .pricing-card-price {
	color: #5e81ac;
	font-weight: bold;
	font-size: 32px;
	padding: 24px 0;
}
.landing-body .section#pricing .pricing-cards .pricing-card .pricing-license-rows .pricing-license-row {
	color: #5a657c;
	font-size: 14px;
	font-weight: 500;
	padding: 4px 0;
}
.landing-body .section#pricing .pricing-cards .pricing-card .pricing-license-rows .pricing-license-row.disabled {
	color: #c3ccdd;
}
.landing-body .section#pricing .pricing-cards .pricing-card .pricing-license-button {
	padding-top: 24px;
}
.landing-body .section#pricing .pricing-cards .pricing-card .pricing-license-button button {
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	background-color: #88c0d0;
	padding: 15px 0;
	text-align: center;
	color: #ffffff;
	font-size: 14px;
	border: none;
	width: 160px;
	cursor: pointer;
}
.landing-body .section#pricing .pricing-cards .pricing-card .pricing-license-button button:hover {
	background-color: #97d9ec;
	-moz-transition: background-color 0.15s;
	-o-transition: background-color 0.15s;
	-webkit-transition: background-color 0.15s;
	transition: background-color 0.15s;
}
.landing-body .section#pricing .pricing-cards .pricing-card::before {
	display: none;
	width: 100%;
	height: 13px;
	background-color: #5e81ac;
	content: " ";
	-moz-border-radius-topleft: 4px;
	-webkit-border-top-left-radius: 4px;
	border-top-left-radius: 4px;
	-moz-border-radius-topright: 4px;
	-webkit-border-top-right-radius: 4px;
	border-top-right-radius: 4px;
	position: absolute;
	top: 0px;
	left: 0px;
}
.landing-body .section#pricing .pricing-cards .pricing-card.premium {
	padding: 53px 75px 25px;
	box-shadow: 0 1px 33px 3px rgba(0, 0, 0, 0.07);
}
.landing-body .section#pricing .pricing-cards .pricing-card.premium:before {
	display: block;
}
.landing-body .section#multimedia {
	padding: 35px 10% 60px;
	position: relative;
}
.landing-body .section#multimedia .multimedia-video-wrapper {
	display: flex;
	justify-content: center;
	padding-top: 35px;
	z-index: 998;
	position: relative;
}
.landing-body .section#multimedia .multimedia-video-wrapper iframe {
	width: 570px;
	height: 300px;
}
.landing-body .section .effect img {
	position: absolute;
	bottom: 0px;
	width: 100%;
	left: 0;
	max-height: 350px;
	z-index: 997;
}
.landing-body .footer {
	background-color: #20252e;
	padding: 10px 10%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 120px;
}
.landing-body .footer .logo-text {
	display: flex;
	align-items: center;
	height: 100%;
}
.landing-body .footer .logo-text img {
	height: 50px;
	width: 50px;
}
.landing-body .footer .logo-text .text {
	margin-left: 15px;
}
.landing-body .footer .logo-text .text h1 {
	font-size: 18px;
	color: #c3ccdd;
	font-weight: normal;
	margin: 0;
}
.landing-body .footer .logo-text .text span {
	color: #9199a9;
	font-size: 12px;
}
.landing-body .footer .icons {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	font-size: 18px;
	color: #9199a9;
	height: 100%;
}
.landing-body .footer .icons .icon:first-child img {
	margin-left: 0;
}
.landing-body .footer .icons .icon img {
	margin-left: 20px;
	cursor: pointer;
}
.landing-body .landing-mask {
	display: none;
	background-color: #2e3440;
	position: fixed;
	z-index: 1000;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
	opacity: 0.64;
	animation-name: fadeinmask;
	animation-duration: 0.15s;
	opacity: 0.8;
	filter: alpha(opacity=80);
	cursor: pointer;
}

@media (max-width: 1199px) {
	.landing-body .landing-nav-menu {
		z-index: 10001;
	}
	.landing-body .landing-nav-menu .nav-menu-wrapper {
		position: absolute;
		width: 100%;
		top: 50px;
		left: 0;
	}
	.landing-body .landing-nav-menu .nav-menu-wrapper ul {
		flex-flow: column;
		background-color: #f9fafb;
		max-height: 0;
		overflow: auto;
		-moz-transition: max-height 0.4s;
		-o-transition: max-height 0.4s;
		-webkit-transition: max-height 0.4s;
		transition: max-height 0.4s;
	}
	.landing-body .landing-nav-menu .nav-menu-wrapper ul li {
		width: 100%;
		text-align: center;
	}
	.landing-body .landing-nav-menu .nav-menu-wrapper ul li:hover {
		background-color: #d8dee9;
		-moz-transition: background-color 0.3s;
		-o-transition: background-color 0.3s;
		-webkit-transition: background-color 0.3s;
		transition: background-color 0.3s;
	}
	.landing-body .landing-nav-menu .nav-menu-wrapper ul li.active-link {
		background-color: #26282e;
	}
	.landing-body .landing-nav-menu .nav-menu-wrapper ul li.active-link a {
		color: #ffffff;
	}
	.landing-body .landing-nav-menu .buy-button {
		display: none;
	}
	.landing-body .landing-nav-menu .menu-button {
		display: block;
	}
	.landing-body .landing-nav-menu .menu-button .landing-menu-button {
		display: flex;
	}
	.landing-body .landing-nav-menu .menu-button .landing-menu-button:hover {
		background-color: #6c7a96;
		-moz-transition: background-color 0.15s;
		-o-transition: background-color 0.15s;
		-webkit-transition: background-color 0.15s;
		transition: background-color 0.15s;
	}
	.landing-body .landing-nav-menu .landing-menu-button.landing-menu-active {
		background-color: #2e3440;
		-moz-transition: background-color 0.15s;
		-o-transition: background-color 0.15s;
		-webkit-transition: background-color 0.15s;
		transition: background-color 0.15s;
		color: #ffffff;
	}
	.landing-body .landing-nav-menu .landing-menu-button.landing-menu-active .landing-menu-button-icon {
		background: url("../../images/landing/icon-mainmenu-white.svg");
		background-repeat: no-repeat;
		background-size: 18px 23px;
	}
	.landing-body .landing-nav-menu .nav-menu-wrapper ul.landing-menu-active {
		max-height: 300px;
		overflow: hidden;
	}
	.landing-body .landing-mask.landing-menu-active {
		display: block;
	}
	.landing-body .landing-header-wrapper .landing-header-content {
		text-align: center;
	}
	.landing-body .landing-header-wrapper .sun {
		display: none;
	}
	.landing-body .section#meet-mirage {
		padding: 24px 10% 260px;
	}
	.landing-body .section#meet-mirage .buy-mirage-promo-wrapper .buy-mirage-promo .promo-content {
		width: 100%;
	}
	.landing-body .section#meet-mirage .buy-mirage-promo-wrapper .buy-mirage-promo .promo-button {
		width: 100%;
	}
	.landing-body .section#features {
		padding: 260px 10% 60px;
	}
	.landing-body .section#features .features-img img {
		height: 300px;
	}
	.landing-body .section#features .features-rows .features-row {
		padding: 17px 12px;
	}
	.landing-body .section#features .features-rows .features-row .features-row-icon {
		width: 46px;
		height: 46px;
	}
	.landing-body .section#features .features-rows .features-row .features-row-icon .feature {
		background-repeat: no-repeat;
		background-size: 20px 20px;
		width: 20px;
		height: 20px;
	}
	.landing-body .section#features .features-rows .features-row .features-row-icon .feature.first {
		background-repeat: no-repeat;
		background-size: 20px 20px;
	}
	.landing-body .section#features .features-rows .features-row .features-row-icon .feature.second {
		background-repeat: no-repeat;
		background-size: 20px 20px;
	}
	.landing-body .section#features .features-rows .features-row .features-row-icon .feature.third {
		background-repeat: no-repeat;
		background-size: 20px 20px;
	}
	.landing-body .section#features .features-rows .features-row:hover .features-row-icon .feature {
		width: 46px;
		height: 46px;
	}
	.landing-body .section#features .features-rows .features-row:hover .features-row-icon .feature.first {
		background: url("../../images/landing/icon-feature-1-hover.svg");
		background-repeat: no-repeat;
		background-size: 46px 46px;
	}
	.landing-body .section#features .features-rows .features-row:hover .features-row-icon .feature.second {
		background: url("../../images/landing/icon-feature-2-hover.svg");
		background-repeat: no-repeat;
		background-size: 46px 46px;
	}
	.landing-body .section#features .features-rows .features-row:hover .features-row-icon .feature.third {
		background: url("../../images/landing/icon-feature-3-hover.svg");
		background-repeat: no-repeat;
		background-size: 46px 46px;
	}
	.landing-body .section#features .features-rows .features-row .features-row-content {
		margin-left: 12px;
	}
	.landing-body .footer {
		height: 100%;
		flex-flow: column;
	}
	.landing-body .footer .logo-text {
		flex-flow: column;
		text-align: center;
		margin-bottom: 10px;
	}
	.landing-body .footer .logo-text .text {
		margin-top: 10px;
		margin-left: 0;
	}
}
.layout-config {
	position: fixed;
	padding: 0;
	top: 75px;
	display: block;
	right: 0;
	width: 20em;
	z-index: 998;
	height: calc(100% - 75px);
	transform: translate3d(20em, 0px, 0px);
	transition: transform 0.15s;
	background-color: #ffffff;
	backface-visibility: hidden;
	box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 12px 17px 2px rgba(0, 0, 0, 0.14);
}
.layout-config.layout-config-active {
	transform: translate3d(0px, 0px, 0px);
}
.layout-config.layout-config-active .layout-config-button {
	opacity: 0;
	visibility: hidden;
}
.layout-config .layout-config-button {
	display: block;
	position: absolute;
	width: 52px;
	height: 52px;
	line-height: 52px;
	background-color: #fafafa;
	text-align: center;
	top: 230px;
	left: -51px;
	z-index: -1;
	overflow: hidden;
	cursor: pointer;
	opacity: 1;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	transition: background-color 0.15s, opacity 0.15s;
	transition-delay: 0.2s;
	box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 12px 17px 2px rgba(0, 0, 0, 0.14);
}
.layout-config .layout-config-button i {
	font-size: 32px;
	font-weight: bold;
	line-height: inherit;
	cursor: pointer;
	transition: transform 1s;
}
.layout-config .layout-config-close {
	position: absolute;
	width: 20px;
	height: 20px;
	line-height: 20px;
	text-align: center;
	right: 10px;
	top: 10px;
	z-index: 999;
	background-color: #e0284f;
	border-radius: 50%;
	transition: background-color 0.15s;
}
.layout-config .layout-config-close i {
	color: #ffffff;
	line-height: inherit;
	font-size: 12px;
}
.layout-config .layout-config-close:hover {
	background-color: #d44d69;
}
.layout-config .layout-config-content {
	position: relative;
	height: 100%;
	padding: 1em;
	overflow: auto;
}
.layout-config .layout-config-content .layout-themes {
	display: flex;
	flex-wrap: wrap;
}
.layout-config .layout-config-content .layout-themes > div {
	padding: 0.25rem;
}
.layout-config .layout-config-content .layout-themes a {
	width: 2rem;
	height: 2rem;
	border-radius: 4px;
	display: block;
	position: relative;
	align-items: center;
	justify-content: center;
	transition: transform 0.15s;
	box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}
.layout-config .layout-config-content .layout-themes a i {
	font-size: 1rem;
	position: absolute;
	color: #ffffff;
	top: 50%;
	left: 50%;
	margin-left: -0.5rem;
	margin-top: -0.5rem;
}
.layout-config .layout-config-content .layout-themes a:hover {
	transform: scale(1.1);
}
.layout-config .p-col {
	text-align: center;
}
.layout-config p {
	line-height: 1.5;
	margin-top: 0;
	color: #9199a9;
}

.blocked-scroll-config {
	overflow: hidden;
}

@media screen and (max-width: 1024px) {
	.layout-config {
		transform: translate3d(20em, 0px, 0px);
	}
	.layout-config.layout-config-active {
		transform: translate3d(0px, 0px, 0px);
	}
	.layout-config .layout-config-close {
		right: 29px;
	}
}
.widget-overview-box {
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	width: 100%;
	min-height: 90px;
	padding: 10px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	align-items: center;
}
.widget-overview-box .overview-box-icon img {
	height: 50px;
	width: 50px;
}
.widget-overview-box .overview-box-text h5 {
	color: #ffffff;
	font-size: 16px;
	font-weight: 500;
	margin: 0;
}
.widget-overview-box .overview-box-text span {
	color: #dcf2f9;
	font-size: 13px;
	font-weight: 500;
	margin: 0;
}
.widget-overview-box .overview-box-status {
	color: #ffffff;
	font-weight: 500;
	font-size: 16px;
	width: 43px;
	height: 22px;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	align-items: center;
}
.widget-overview-box.overview-box-1 {
	background: -moz-linear-gradient(256deg, #65afc4 0%, #88c0d0 100%);
	/* ff3.6+ */
	background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, #65afc4), color-stop(100%, #88c0d0));
	/* safari4+,chrome */
	background: -webkit-linear-gradient(256deg, #65afc4 0%, #88c0d0 100%);
	/* safari5.1+,chrome10+ */
	background: -o-linear-gradient(256deg, #65afc4 0%, #88c0d0 100%);
	/* opera 11.10+ */
	background: -ms-linear-gradient(256deg, #65afc4 0%, #88c0d0 100%);
	/* ie10+ */
	background: linear-gradient(256deg, #65afc4 0%, #88c0d0 100%);
	/* w3c */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#65afc4", endColorstr="#88c0d0",GradientType=1 );
	/* ie6-9 */
}
.widget-overview-box.overview-box-1 .overview-box-text span {
	color: #dcf2f9;
}
.widget-overview-box.overview-box-1 .overview-box-status {
	background-color: #d08770;
}
.widget-overview-box.overview-box-2 {
	background: -moz-linear-gradient(256deg, #f2c260 0%, #ebcb8b 100%);
	/* ff3.6+ */
	background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, #f2c260), color-stop(100%, #ebcb8b));
	/* safari4+,chrome */
	background: -webkit-linear-gradient(256deg, #f2c260 0%, #ebcb8b 100%);
	/* safari5.1+,chrome10+ */
	background: -o-linear-gradient(256deg, #f2c260 0%, #ebcb8b 100%);
	/* opera 11.10+ */
	background: -ms-linear-gradient(256deg, #f2c260 0%, #ebcb8b 100%);
	/* ie10+ */
	background: linear-gradient(256deg, #f2c260 0%, #ebcb8b 100%);
	/* w3c */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#f2c260", endColorstr="#ebcb8b",GradientType=1 );
	/* ie6-9 */
}
.widget-overview-box.overview-box-2 .overview-box-text span {
	color: #fcf5e8;
}
.widget-overview-box.overview-box-2 .overview-box-status {
	background-color: #b48ead;
}
.widget-overview-box.overview-box-3 {
	background: -moz-linear-gradient(256deg, #8fb56f 0%, #a3be8c 100%);
	/* ff3.6+ */
	background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, #8fb56f), color-stop(100%, #a3be8c));
	/* safari4+,chrome */
	background: -webkit-linear-gradient(256deg, #8fb56f 0%, #a3be8c 100%);
	/* safari5.1+,chrome10+ */
	background: -o-linear-gradient(256deg, #8fb56f 0%, #a3be8c 100%);
	/* opera 11.10+ */
	background: -ms-linear-gradient(256deg, #8fb56f 0%, #a3be8c 100%);
	/* ie10+ */
	background: linear-gradient(256deg, #8fb56f 0%, #a3be8c 100%);
	/* w3c */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#8fb56f", endColorstr="#a3be8c",GradientType=1 );
	/* ie6-9 */
}
.widget-overview-box.overview-box-3 .overview-box-text span {
	color: #f0f4ec;
}
.widget-overview-box.overview-box-3 .overview-box-status {
	background-color: #ebcb8b;
	color: #3b4252;
}
.widget-overview-box.overview-box-4 {
	background: -moz-linear-gradient(256deg, #a4719b 0%, #b48ead 100%);
	/* ff3.6+ */
	background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, #a4719b), color-stop(100%, #b48ead));
	/* safari4+,chrome */
	background: -webkit-linear-gradient(256deg, #a4719b 0%, #b48ead 100%);
	/* safari5.1+,chrome10+ */
	background: -o-linear-gradient(256deg, #a4719b 0%, #b48ead 100%);
	/* opera 11.10+ */
	background: -ms-linear-gradient(256deg, #a4719b 0%, #b48ead 100%);
	/* ie10+ */
	background: linear-gradient(256deg, #a4719b 0%, #b48ead 100%);
	/* w3c */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#a4719b", endColorstr="#b48ead",GradientType=1 );
	/* ie6-9 */
}
.widget-overview-box.overview-box-4 .overview-box-text span {
	color: #f3ecf2;
}
.widget-overview-box.overview-box-4 .overview-box-status {
	background-color: #a3be8c;
	color: #3b4252;
}

.widget-statistics {
	overflow: auto;
}
.widget-statistics #dashboard-chart canvas {
	background-color: #ffffff 4%;
	background-image: -webkit-gradient(linear, left top, left bottom, from(#ffffff 4%), to(#71B5C8 214%));
	background-image: -webkit-linear-gradient(to bottom, #ffffff 4%, #71B5C8 214%);
	background-image: -moz-linear-gradient(to bottom, #ffffff 4%, #71B5C8 214%);
	background-image: -ms-linear-gradient(to bottom, #ffffff 4%, #71B5C8 214%);
	background-image: -o-linear-gradient(to bottom, #ffffff 4%, #71B5C8 214%);
	background-image: linear-gradient(to bottom, #ffffff 4%, #71B5C8 214%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff 4%", endColorstr="#71B5C8 214%");
	background-color: #ffffff;
}
.widget-statistics .dropdown {
	text-align: right;
	padding: 0;
}
.widget-statistics .dropdown .p-dropdown {
	border: none;
	min-width: auto;
}
.widget-statistics .dropdown .p-dropdown .p-dropdown-label {
	text-align: right;
}

.widget-news {
	overflow: hidden;
	position: relative;
	margin: 1em 0;
	padding: 0;
	border-radius: 4px;
}
.widget-news .news-row {
	padding-bottom: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	flex-direction: row;
	transition: transform 500ms ease 0s;
}
.widget-news .news-row .news {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	padding-top: 80px;
	padding-bottom: 35px;
	z-index: -1;
	flex: 1 0 100%;
	-moz-transition: visibility 0.8s;
	-o-transition: visibility 0.8s;
	-webkit-transition: visibility 0.8s;
	transition: visibility 0.8s;
	-moz-transition: transform 500ms ease 0s;
	-o-transition: transform 500ms ease 0s;
	-webkit-transition: transform 500ms ease 0s;
	transition: transform 500ms ease 0s;
	overflow: hidden;
	margin: 0;
}
.widget-news .news-row .news.news-1 {
	background-image: url("../../images/dashboard/news-1.png");
}
.widget-news .news-row .news.news-1 .news-title {
	background: -moz-linear-gradient(246deg, #f2c260 0%, #ebcb8b 100%);
	/* ff3.6+ */
	background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, #f2c260), color-stop(100%, #ebcb8b));
	/* safari4+,chrome */
	background: -webkit-linear-gradient(246deg, #f2c260 0%, #ebcb8b 100%);
	/* safari5.1+,chrome10+ */
	background: -o-linear-gradient(246deg, #f2c260 0%, #ebcb8b 100%);
	/* opera 11.10+ */
	background: -ms-linear-gradient(246deg, #f2c260 0%, #ebcb8b 100%);
	/* ie10+ */
	background: linear-gradient(246deg, #f2c260 0%, #ebcb8b 100%);
	/* w3c */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#f2c260", endColorstr="#ebcb8b",GradientType=1 );
	/* ie6-9 */
}
.widget-news .news-row .news.news-1 .link a {
	color: #ebcb8b;
}
.widget-news .news-row .news.news-2 {
	background-image: url("../../images/dashboard/news-2.png");
}
.widget-news .news-row .news.news-2 .news-title {
	background: -moz-linear-gradient(246deg, #8fb56f 0%, #a3be8c 100%);
	/* ff3.6+ */
	background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, #8fb56f), color-stop(100%, #a3be8c));
	/* safari4+,chrome */
	background: -webkit-linear-gradient(246deg, #8fb56f 0%, #a3be8c 100%);
	/* safari5.1+,chrome10+ */
	background: -o-linear-gradient(246deg, #8fb56f 0%, #a3be8c 100%);
	/* opera 11.10+ */
	background: -ms-linear-gradient(246deg, #8fb56f 0%, #a3be8c 100%);
	/* ie10+ */
	background: linear-gradient(246deg, #8fb56f 0%, #a3be8c 100%);
	/* w3c */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#8fb56f", endColorstr="#a3be8c",GradientType=1 );
	/* ie6-9 */
}
.widget-news .news-row .news.news-2 .link a {
	color: #a3be8c;
}
.widget-news .news-row .news.news-3 {
	background-image: url("../../images/dashboard/news-3.png");
}
.widget-news .news-row .news.news-3 .news-title {
	background-color: #45b0d5;
	background-image: -webkit-gradient(linear, left top, left bottom, from(#45b0d5), to(#59c7ed 99%));
	background-image: -webkit-linear-gradient(to bottom, #45b0d5, #59c7ed 99%);
	background-image: -moz-linear-gradient(to bottom, #45b0d5, #59c7ed 99%);
	background-image: -ms-linear-gradient(to bottom, #45b0d5, #59c7ed 99%);
	background-image: -o-linear-gradient(to bottom, #45b0d5, #59c7ed 99%);
	background-image: linear-gradient(to bottom, #45b0d5, #59c7ed 99%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#45b0d5", endColorstr="#59c7ed 99%");
}
.widget-news .news-row .news.news-3 .link a {
	color: #88c0d0;
}
.widget-news .news-row .news .news-title {
	width: 55px;
	height: 25px;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	align-items: center;
}
.widget-news .news-row .news .news-title h5 {
	font-size: 14px;
	font-weight: 700;
	letter-spacing: 1px;
	color: #434c5e;
	margin: 0;
}
.widget-news .news-row .news h6 {
	font-size: 16px;
	font-weight: 500;
	color: #ffffff;
}
.widget-news .news-row .news p {
	color: #ffffff;
	opacity: 0.6;
	margin-bottom: 20px;
}
.widget-news .news-row .news .link {
	position: absolute;
	width: 95%;
	bottom: 20px;
}
.widget-news .news-row .news .link a {
	cursor: pointer;
}
.widget-news .news-row .news .link a i {
	margin-left: 5px;
}
.widget-news .link-points {
	position: absolute;
	width: 95%;
	bottom: 20px;
}
.widget-news .link-points .points {
	justify-content: flex-end;
	padding-top: 10px;
}
.widget-news .link-points .points .point {
	width: 10px;
	height: 10px;
	background-color: #eceff4;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	margin: 3px;
	cursor: pointer;
}
.widget-news .link-points .points .point.active-point.point-1 {
	background-color: #ebcb8b;
}
.widget-news .link-points .points .point.active-point.point-2 {
	background-color: #a3be8c;
}
.widget-news .link-points .points .point.active-point.point-3 {
	background-color: #88c0d0;
}

.widget-timeline .timeline-row {
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	border: solid 1px #e5e9f0;
	background-color: #f9fafb;
	padding: 15px;
	width: 100%;
	margin: 12px 0;
}
.widget-timeline .timeline-row > .p-grid > .p-col-1 {
	width: 40px;
}
.widget-timeline .timeline-row > .p-grid > .p-col-11 {
	width: calc(100% - 40px);
	padding-left: 1em;
}
.widget-timeline .timeline-row .timeline-icon {
	width: 36px;
	height: 36px;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #ffffff;
	font-size: 16px;
	margin-bottom: 10px;
}
.widget-timeline .timeline-row .timeline-icon.camera-icon {
	background: -moz-linear-gradient(225deg, #65afc4 0%, #88c0d0 100%);
	/* ff3.6+ */
	background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, #65afc4), color-stop(100%, #88c0d0));
	/* safari4+,chrome */
	background: -webkit-linear-gradient(225deg, #65afc4 0%, #88c0d0 100%);
	/* safari5.1+,chrome10+ */
	background: -o-linear-gradient(225deg, #65afc4 0%, #88c0d0 100%);
	/* opera 11.10+ */
	background: -ms-linear-gradient(225deg, #65afc4 0%, #88c0d0 100%);
	/* ie10+ */
	background: linear-gradient(225deg, #65afc4 0%, #88c0d0 100%);
	/* w3c */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#65afc4", endColorstr="#88c0d0",GradientType=1 );
	/* ie6-9 */
}
.widget-timeline .timeline-row .timeline-icon.comment-icon {
	background: -moz-linear-gradient(225deg, #f2c260 0%, #ebcb8b 100%);
	/* ff3.6+ */
	background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, #f2c260), color-stop(100%, #ebcb8b));
	/* safari4+,chrome */
	background: -webkit-linear-gradient(225deg, #f2c260 0%, #ebcb8b 100%);
	/* safari5.1+,chrome10+ */
	background: -o-linear-gradient(225deg, #f2c260 0%, #ebcb8b 100%);
	/* opera 11.10+ */
	background: -ms-linear-gradient(225deg, #f2c260 0%, #ebcb8b 100%);
	/* ie10+ */
	background: linear-gradient(225deg, #f2c260 0%, #ebcb8b 100%);
	/* w3c */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#f2c260", endColorstr="#ebcb8b",GradientType=1 );
	/* ie6-9 */
}
.widget-timeline .timeline-row .timeline-icon.chart-icon {
	background: -moz-linear-gradient(225deg, #a4719b 0%, #b48ead 100%);
	/* ff3.6+ */
	background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, #a4719b), color-stop(100%, #b48ead));
	/* safari4+,chrome */
	background: -webkit-linear-gradient(225deg, #a4719b 0%, #b48ead 100%);
	/* safari5.1+,chrome10+ */
	background: -o-linear-gradient(225deg, #a4719b 0%, #b48ead 100%);
	/* opera 11.10+ */
	background: -ms-linear-gradient(225deg, #a4719b 0%, #b48ead 100%);
	/* ie10+ */
	background: linear-gradient(225deg, #a4719b 0%, #b48ead 100%);
	/* w3c */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#a4719b", endColorstr="#b48ead",GradientType=1 );
	/* ie6-9 */
}
.widget-timeline .timeline-row h6 {
	margin-top: 0;
	margin-bottom: 6px;
}
.widget-timeline .timeline-row .timeline-time {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: flex-end;
	color: #9199a9;
}
.widget-timeline .timeline-row .timeline-images {
	margin-top: 15px;
	position: relative;
}
.widget-timeline .timeline-row .timeline-images img {
	width: 100%;
}
.widget-timeline .timeline-row .timeline-images .new {
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	background-color: #434c5e;
	color: #d8dee9;
	font-size: 12px;
	position: absolute;
	top: -8px;
	right: -8px;
	padding: 3px 6px;
	text-align: center;
}

.widget-tasks {
	padding: 0;
	padding-top: 18px;
}
.widget-tasks .card-header {
	padding: 0 18px;
}
.widget-tasks .task-info {
	text-align: right;
}
.widget-tasks .task-info span {
	font-size: 12px;
	color: #9199a9;
}
.widget-tasks .task-info span.highlighted {
	color: #4c566a;
	font-weight: 500;
}
.widget-tasks .checkboxes {
	list-style: none;
	padding: 0;
	margin: 0;
	color: #4c566a;
}
.widget-tasks .checkboxes .checkbox-row {
	padding: 6px 18px;
}
.widget-tasks .checkboxes .checkbox-row:nth-child(odd) {
	background-color: #f9fafb;
}
.widget-tasks .checkboxes .checkbox-row .checkbox {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
}
.widget-tasks .checkboxes .checkbox-row .checkbox p {
	margin-left: 12px;
}
.widget-tasks .checkboxes .checkbox-row .checkbox-row-info {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}
.widget-tasks .checkboxes .checkbox-row .checkbox-row-info .time {
	-moz-border-radius: 2px;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	background-color: #88c0d0;
	color: #ffffff;
	font-size: 12px;
	padding: 3px 10px;
	text-align: center;
	min-width: 55px;
	margin-right: 4px;
}
.widget-tasks .checkboxes .checkbox-row .checkbox-row-info .time.later {
	background-color: #9199a9;
}
.widget-tasks .checkboxes .checkbox-row .checkbox-row-info i {
	color: #9199a9;
	margin-left: 12px;
	cursor: pointer;
}
.widget-tasks .checkboxes .checkbox-row .checkbox-row-info i:hover {
	color: #88c0d0;
	-moz-transition: color 0.15s;
	-o-transition: color 0.15s;
	-webkit-transition: color 0.15s;
	transition: color 0.15s;
}

.widget-support-request {
	position: relative;
}
.widget-support-request .support-info {
	text-align: right;
}
.widget-support-request .support-info p {
	font-size: 12px;
	color: #9199a9;
	margin: 0;
}
.widget-support-request .support-info .highlited {
	color: #4c566a;
	font-weight: 500;
}
.widget-support-request label {
	display: block;
	margin-bottom: 8px;
}
.widget-support-request .p-inputtext {
	margin-bottom: 16px;
	width: 100%;
	color: #9199a9;
}
.widget-support-request .p-inputtextarea {
	width: 100%;
	margin-bottom: 16px;
	color: #9199a9;
}
.widget-support-request .resolution-button-bar {
	text-align: left;
}
.widget-support-request .resolution-button-bar .p-button {
	width: auto;
	margin-right: 15px;
	min-width: 125px;
	margin-bottom: 15px;
}
.widget-support-request .resolution-button-bar .p-button.secondary-button {
	padding: 0;
	background-color: transparent;
	color: #9199a9;
}

.widget-team {
	padding: 0;
	padding-top: 18px;
	position: relative;
}
.widget-team h5 {
	padding-left: 18px;
}
.widget-team ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}
.widget-team ul li {
	padding: 12px 18px;
	border-top: 1px solid rgba(145, 153, 169, 0.3);
}
.widget-team ul li:last-child {
	border-bottom: 1px solid rgba(145, 153, 169, 0.3);
}
.widget-team ul li:nth-child(odd) {
	background-color: #f9fafb;
}
.widget-team ul li .member {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
}
.widget-team ul li .member .team-box {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
}
.widget-team ul li .member .team-box .profile-image {
	margin-right: 10px;
}
.widget-team ul li .member .team-box .profile-image img {
	width: 40px;
	height: 40px;
}
.widget-team ul li .member .team-box h6 {
	font-size: 14px;
	font-weight: 500;
	margin: 0;
	color: #4c566a;
}
.widget-team ul li .member .team-box span {
	font-size: 12px;
	color: #9199a9;
}
.widget-team ul li .member .icons {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}
.widget-team ul li .member .icons .icon {
	height: 24px;
	width: 24px;
	background-color: #eceff4;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	margin: 3px;
	cursor: pointer;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	align-items: center;
}
.widget-team ul li .member .icons .icon i {
	color: #ffffff;
}
.widget-team ul li .member .icons .icon.icon-hastag {
	background-color: #d08770;
}
.widget-team ul li .member .icons .icon.icon-hastag:hover {
	background-color: #a76a57;
	-moz-transition: background-color 0.15s;
	-o-transition: background-color 0.15s;
	-webkit-transition: background-color 0.15s;
	transition: background-color 0.15s;
}
.widget-team ul li .member .icons .icon.icon-twitter {
	background-color: #5e81ac;
}
.widget-team ul li .member .icons .icon.icon-twitter:hover {
	background-color: #456184;
	-moz-transition: background-color 0.15s;
	-o-transition: background-color 0.15s;
	-webkit-transition: background-color 0.15s;
	transition: background-color 0.15s;
}
.widget-team ul li .member .icons .icon.icon-prime {
	background-color: #bf616a;
}
.widget-team ul li .member .icons .icon.icon-prime:hover {
	background-color: #9c4a52;
	-moz-transition: background-color 0.15s;
	-o-transition: background-color 0.15s;
	-webkit-transition: background-color 0.15s;
	transition: background-color 0.15s;
}

.widget-card-graph {
	padding: 0;
	text-align: center;
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	min-height: 225px;
	-moz-border-radius: 7px;
	-webkit-border-radius: 7px;
	border-radius: 7px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}
.widget-card-graph .graph-header {
	width: 100%;
	-moz-border-radius-topleft: 7px;
	-webkit-border-top-left-radius: 7px;
	border-top-left-radius: 7px;
	-moz-border-radius-topright: 7px;
	-webkit-border-top-right-radius: 7px;
	border-top-right-radius: 7px;
	height: 124px;
}
.widget-card-graph .graph-header h5 {
	padding-top: 18px;
	font-size: 18px;
	color: #4c566a;
}
.widget-card-graph .graph-header .graph {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 50px;
	left: 50%;
	margin-left: -75px;
}
.widget-card-graph .graph-header .graph .graph-center {
	width: 125px;
	height: 125px;
	background-color: #2e3440;
	background-image: -webkit-gradient(linear, left top, left bottom, from(#2e3440), to(#eceff4));
	background-image: -webkit-linear-gradient(to bottom, #2e3440, #eceff4);
	background-image: -moz-linear-gradient(to bottom, #2e3440, #eceff4);
	background-image: -ms-linear-gradient(to bottom, #2e3440, #eceff4);
	background-image: -o-linear-gradient(to bottom, #2e3440, #eceff4);
	background-image: linear-gradient(to bottom, #2e3440, #eceff4);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2e3440", endColorstr="#eceff4");
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	position: absolute;
}
.widget-card-graph .graph-header .graph .graph-border {
	width: 150px;
	height: 150px;
	background-color: #f2c260;
	background-image: -webkit-gradient(linear, right top, left top, from(#f2c260), to(#ebcb8b));
	background-image: -webkit-linear-gradient(to left, #f2c260, #ebcb8b);
	background-image: -moz-linear-gradient(to left, #f2c260, #ebcb8b);
	background-image: -ms-linear-gradient(to left, #f2c260, #ebcb8b);
	background-image: -o-linear-gradient(to left, #f2c260, #ebcb8b);
	background-image: linear-gradient(to left, #f2c260, #ebcb8b);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f2c260", endColorstr="#ebcb8b", gradientType="1");
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	position: relative;
}
.widget-card-graph .graph-header .graph .graph-border .graph-block {
	position: absolute;
	right: 0;
	bottom: 0;
	height: 150px;
	width: 64px;
	background-color: #ffffff;
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	height: 115px;
	-moz-border-radius-topright: 50%;
	-webkit-border-top-right-radius: 50%;
	border-top-right-radius: 50%;
	-moz-border-radius-bottomright: 50%;
	-webkit-border-bottom-right-radius: 50%;
	border-bottom-right-radius: 50%;
}
.widget-card-graph .graph-content {
	padding: 18px;
	color: #9199a9;
	border-top: solid 1px rgba(145, 153, 169, 0.3);
	background-color: #ffffff;
	position: relative;
	-moz-border-radius-bottomleft: 7px;
	-webkit-border-bottom-left-radius: 7px;
	border-bottom-left-radius: 7px;
	-moz-border-radius-bottomright: 7px;
	-webkit-border-bottom-right-radius: 7px;
	border-bottom-right-radius: 7px;
	width: 100%;
	z-index: 10;
	justify-content: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}
.widget-card-graph .graph-content h5 {
	margin-top: 10px;
	color: #4c566a;
	margin-bottom: 6px;
}
.widget-card-graph .graph-content hr {
	width: 42px;
	height: 1px;
	background-color: rgba(145, 153, 169, 0.2);
	border: none;
}
.widget-card-graph .graph-content .graph-icon {
	width: 30px;
	height: 30px;
	box-shadow: inset 0 2px 0 0 rgba(208, 135, 112, 0.11);
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	position: absolute;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	align-items: center;
	top: -15px;
	left: 50%;
	margin-left: -15px;
}
.widget-card-graph .graph-content .jun {
	position: absolute;
	right: 15%;
	top: 0;
}
.widget-card-graph.graph-1 .graph-header .graph .graph-center {
	background-color: #eceff4;
	background-image: -webkit-gradient(linear, left top, left bottom, from(#eceff4), to(#ffffff));
	background-image: -webkit-linear-gradient(to bottom, #eceff4, #ffffff);
	background-image: -moz-linear-gradient(to bottom, #eceff4, #ffffff);
	background-image: -ms-linear-gradient(to bottom, #eceff4, #ffffff);
	background-image: -o-linear-gradient(to bottom, #eceff4, #ffffff);
	background-image: linear-gradient(to bottom, #eceff4, #ffffff);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#eceff4", endColorstr="#ffffff");
}
.widget-card-graph.graph-1 .graph-content .graph-icon {
	background: -moz-linear-gradient(225deg, #f2c260 0%, #ebcb8b 100%);
	/* ff3.6+ */
	background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, #f2c260), color-stop(100%, #ebcb8b));
	/* safari4+,chrome */
	background: -webkit-linear-gradient(225deg, #f2c260 0%, #ebcb8b 100%);
	/* safari5.1+,chrome10+ */
	background: -o-linear-gradient(225deg, #f2c260 0%, #ebcb8b 100%);
	/* opera 11.10+ */
	background: -ms-linear-gradient(225deg, #f2c260 0%, #ebcb8b 100%);
	/* ie10+ */
	background: linear-gradient(225deg, #f2c260 0%, #ebcb8b 100%);
	/* w3c */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#f2c260", endColorstr="#ebcb8b",GradientType=1 );
	/* ie6-9 */
}
.widget-card-graph.graph-2 .graph-header {
	background-image: url("../../images/dashboard/bg-box-1.jpg");
	background-size: cover;
}
.widget-card-graph.graph-2 .graph-header .graph .graph-border {
	background-color: #4c566a;
	background-image: -webkit-gradient(linear, left top, right top, from(#4c566a), to(#2e3440));
	background-image: -webkit-linear-gradient(to right, #4c566a, #2e3440);
	background-image: -moz-linear-gradient(to right, #4c566a, #2e3440);
	background-image: -ms-linear-gradient(to right, #4c566a, #2e3440);
	background-image: -o-linear-gradient(to right, #4c566a, #2e3440);
	background-image: linear-gradient(to right, #4c566a, #2e3440);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4c566a", endColorstr="#2e3440", gradientType="1");
}
.widget-card-graph.graph-2 .graph-content {
	background-color: #2e3440;
}
.widget-card-graph.graph-2 .graph-content h5 {
	color: #eceff4;
}
.widget-card-graph.graph-2 .graph-content .graph-icon {
	background-color: #2e3440;
}
.widget-card-graph.graph-3 .graph-header {
	background-image: url("../../images/dashboard/bg-box.jpg");
	background-size: cover;
}
.widget-card-graph.graph-3 .graph-header h5 {
	color: #ffffff;
}
.widget-card-graph.graph-3 .graph-header .graph .graph-center {
	background-color: #eceff4;
	background-image: -webkit-gradient(linear, left top, left bottom, from(#eceff4), to(#ffffff));
	background-image: -webkit-linear-gradient(to bottom, #eceff4, #ffffff);
	background-image: -moz-linear-gradient(to bottom, #eceff4, #ffffff);
	background-image: -ms-linear-gradient(to bottom, #eceff4, #ffffff);
	background-image: -o-linear-gradient(to bottom, #eceff4, #ffffff);
	background-image: linear-gradient(to bottom, #eceff4, #ffffff);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#eceff4", endColorstr="#ffffff");
}
.widget-card-graph.graph-3 .graph-content .graph-icon {
	background: -moz-linear-gradient(225deg, #f2c260 0%, #ebcb8b 100%);
	/* ff3.6+ */
	background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, #f2c260), color-stop(100%, #ebcb8b));
	/* safari4+,chrome */
	background: -webkit-linear-gradient(225deg, #f2c260 0%, #ebcb8b 100%);
	/* safari5.1+,chrome10+ */
	background: -o-linear-gradient(225deg, #f2c260 0%, #ebcb8b 100%);
	/* opera 11.10+ */
	background: -ms-linear-gradient(225deg, #f2c260 0%, #ebcb8b 100%);
	/* ie10+ */
	background: linear-gradient(225deg, #f2c260 0%, #ebcb8b 100%);
	/* w3c */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#f2c260", endColorstr="#ebcb8b",GradientType=1 );
	/* ie6-9 */
}
.widget-card-graph.graph-4 .graph-header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
}
.widget-card-graph.graph-4 .graph-header .title-tag {
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	background-color: #434c5e;
	color: #d8dee9;
	font-size: 12px;
	position: absolute;
	top: -8px;
	left: -8px;
	padding: 3px 6px;
	text-align: center;
}
.widget-card-graph.graph-4 .graph-header img {
	display: block;
	width: 100%;
	max-height: 120px;
}
.widget-card-graph.graph-4 .graph-content .graph-icon {
	background-color: #45b0d5;
	background-image: -webkit-gradient(linear, left top, left bottom, from(#45b0d5), to(#59c7ed));
	background-image: -webkit-linear-gradient(to bottom, #45b0d5, #59c7ed);
	background-image: -moz-linear-gradient(to bottom, #45b0d5, #59c7ed);
	background-image: -ms-linear-gradient(to bottom, #45b0d5, #59c7ed);
	background-image: -o-linear-gradient(to bottom, #45b0d5, #59c7ed);
	background-image: linear-gradient(to bottom, #45b0d5, #59c7ed);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#45b0d5", endColorstr="#59c7ed");
}

.widget-store-items {
	position: relative;
}
.widget-store-items .store-info {
	text-align: right;
}
.widget-store-items .store-info .info-item {
	display: inline-block;
	margin-left: 15px;
	color: #9199a9;
	cursor: pointer;
}
.widget-store-items .store-info .info-item:hover {
	color: #4c566a;
	-moz-transition: color 0.15s;
	-o-transition: color 0.15s;
	-webkit-transition: color 0.15s;
	transition: color 0.15s;
}
.widget-store-items .store-cards-container .store-cards {
	max-height: 489px;
	overflow: auto;
}
.widget-store-items .store-cards-container .store-cards .store-card {
	-moz-border-radius: 2px;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	border: solid 1px #e5e9f0;
	background-color: #f9fafb;
	height: 100%;
}
.widget-store-items .store-cards-container .store-cards .store-card .store-card-icons {
	text-align: center;
	padding: 6px;
	border-bottom: solid 1px #d8dee9;
}
.widget-store-items .store-cards-container .store-cards .store-card .store-card-icons i {
	color: #9199a9;
	cursor: pointer;
	font-size: 20px;
}
.widget-store-items .store-cards-container .store-cards .store-card .store-card-icons i:hover {
	color: #4c566a;
	-moz-transition: color 0.15s;
	-o-transition: color 0.15s;
	-webkit-transition: color 0.15s;
	transition: color 0.15s;
}
.widget-store-items .store-cards-container .store-cards .store-card .store-card-image img {
	width: 100%;
}
.widget-store-items .store-cards-container .store-cards .store-card .store-card-content {
	text-align: center;
	padding-bottom: 12px;
}
.widget-store-items .store-cards-container .store-cards .store-card .store-card-content h5 {
	margin: 6px;
	font-weight: 500;
	color: #4c566a;
}
.widget-store-items .store-cards-container .store-cards .store-card .store-card-content span {
	font-size: 12px;
	color: #9199a9;
}

.widget-download-files {
	padding: 0;
	padding-top: 18px;
	position: relative;
}
.widget-download-files .title {
	padding-left: 18px;
}
.widget-download-files .files-info {
	text-align: right;
	padding-right: 18px;
}
.widget-download-files .files-info span {
	font-size: 12px;
	color: #9199a9;
	cursor: pointer;
	display: block;
	margin-bottom: 1rem;
}
.widget-download-files .files-info span:hover {
	color: #4c566a;
	-moz-transition: color 0.15s;
	-o-transition: color 0.15s;
	-webkit-transition: color 0.15s;
	transition: color 0.15s;
}
.widget-download-files ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
	max-height: 500px;
	overflow: auto;
}
.widget-download-files ul li {
	padding: 15px;
	border-top: 1px solid rgba(145, 153, 169, 0.3);
}
.widget-download-files ul li:last-child {
	border-bottom: 1px solid rgba(145, 153, 169, 0.3);
}
.widget-download-files ul li .list-item {
	align-items: center;
	cursor: pointer;
}
.widget-download-files ul li .list-item:hover .file-name h6 {
	color: #9199a9;
	-moz-transition: color 0.15s;
	-o-transition: color 0.15s;
	-webkit-transition: color 0.15s;
	transition: color 0.15s;
}
.widget-download-files ul li .list-item .file-name {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
}
.widget-download-files ul li .list-item .file-name h6 {
	font-size: 14px;
	font-weight: 500;
	margin: 0;
	color: #4c566a;
}
.widget-download-files ul li .list-item .icon {
	text-align: center;
}
.widget-download-files ul li .list-item .new {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: flex-end;
}
.widget-download-files ul li .list-item .new .new-tag {
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	height: 20px;
	background-color: #434c5e;
	color: #d8dee9;
	font-size: 12px;
	width: 40px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	align-items: center;
}

/* Add your customizations of layout here */
