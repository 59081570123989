$gutter: 0.5rem; //for primeflex grid system

@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/prismjs/themes/prism-coy.css";
@import "../node_modules/@fullcalendar/core/main.min.css";
@import "../node_modules/@fullcalendar/daygrid/main.min.css";
@import "../node_modules/@fullcalendar/timegrid/main.min.css";

h3.gfreport,
h4.gfreport {
	text-align: center;
}

table.gfreportInvisible {
	border-style: none;
	align-self: center;
	margin-left: auto;
	margin-right: auto;
	border-spacing: 0;
}

table.gfreportVisible {
	border-style: solid;
	border-color: black;
	border-width: 0.5pt;
	align-self: center;
	margin-left: auto;
	margin-right: auto;
	border-spacing: 0;
}

td.gfreportLeftaligned {
	text-align: left;
}

td.gfreportRightaligned {
	text-align: right;
}

td.gfreportCenteraligned {
	text-align: center;
}

table.gfreportVisible td.gfreportLeftaligned,
table.gfreportVisible td.gfreportRightaligned,
table.gfreportVisible td.gfreportCenteraligned {
	border-style: solid;
	border-color: black;
	border-width: 0.5pt;
	padding-left: 6pt;
	padding-right: 6pt;
	padding-top: 2pt;
	padding-bottom: 2pt;
}

.p-column-filter-overlay {
	background: var(--surface-a);
	padding: 0.5rem;
	border-radius: 4px;
	border: 2px solid var(--surface-c);
}

.p-column-filter-overlay>div {
	margin: 0.5rem 0.5rem 0.5rem 0.5rem;
}

.p-column-filter-buttonbar>button {
	margin: 0 0.5rem 0 0.5rem;
}

.p-column-filter-constraint {
	margin: 1rem 0 1rem 0;
}

.p-datatable-gridlines {
	border: 2px solid var(--surface-c);
}

.p-datepicker-month {
	color: var(--text-color);
}

.p-datepicker-year {
	color: var(--text-color);
}

.p-datatable-footer {
	border-width: 1px 1px 1px 1px !important;
}